import React, {forwardRef} from "react";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import TgfDataGrid from "../../../../core/kendo/dataGrid/TgfDataGrid";
import {LoadFlagsCell} from "../../../../core/kendo/dataGrid/cells/LoadFlags";

import "./LaneLookupListing.css";
import AgentTooltipComponent from "../../../../crm/components/agent-tooltip/AgentTooltipComponent";

const LaneLookupListingTable = forwardRef(function LaneLookupListingTable(props, ref) {

    const {
        isAce,
        fromCity,
        fromState,
        toCity,
        toState,
        equipmentType,
        activeAssociateId,
        filterAssociate,
        myShipmentsOnly,
        customerName,
        pickupDateFrom,
        pickupDateTo,
        isBlind,
        isHazMat,
        isMultiStop,
    } = props;

    const openLinksInNewTab = useSelector((state) => state.persona.associate.openLinksInNewTab);

    const currencyFormatter = (amount) => {
      return (
        <td>
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(amount)}
        </td>
      );
    };

    const canView = (associateId) => {
      return isAce || (associateId === activeAssociateId);
    }

    const calcTotalMargin = (carrierCost = 0, customerCost = 0, associateId) => {
        const calcValue = customerCost - carrierCost;
        const dataValue = calcValue !== 0 ? calcValue : null;
        return canView(associateId) ? currencyFormatter(dataValue) : <td></td>;
    }

  const renderCustomerCost = (dataValue, associateId) => canView(associateId) ? currencyFormatter(dataValue) : <td></td>;

    const renderCustomerName = (path, title, associateId) => {
        const rowLink = <td className={"k-table-td show-all-text"}><Link className={"tgf-grid-link-cell"} to={path} target={openLinksInNewTab ? "_blank" : "" } >{title}</Link></td>;
        return canView(associateId) || !associateId ? rowLink : <td>{<title></title>}</td>;
    }

  const cellLink = (path, title, associateId) => {
    const rowLink = <td className={"k-table-td show-all-text"}><Link className={"tgf-grid-link-cell"} to={path} target={openLinksInNewTab ? "_blank" : "" } >{title}</Link></td>;
    return canView(associateId) || !associateId ? rowLink : <td>{title}</td>;
  }

  const AceColumns = !isAce ? [] :
    [{
      title: 'REP',
      field: 'repName',
      width: 50,
      type: 'custom',
      cell: (props) =>
        <td>
            <span>
                <AgentTooltipComponent content={props.dataItem.repName} agentId={props.dataItem.associateId}/>
            </span>
        </td>,
    }];


  const gridColumns = [
    ...AceColumns,
    {
      title: "Ship #",
      field: "bolNumber",
      type: "custom",
      cell: (props) =>
        cellLink(
          `/shipment/${props.dataItem.bolNumber}/details`,
          props.dataItem.bolNumber,
          props.dataItem.associateId
        ),
      width: 70,
    },
    {
      title: "Carrier Cost",
      field: "carrierCost",
      type: "currency",
      width: 110,
    },
    {
      title: "Customer Cost",
      field: "customerCost",
      type: "custom",
      cell: (props) =>
        renderCustomerCost(
          props.dataItem.customerCost,
          props.dataItem.associateId
        ),
      width: 100,
    },
    {
      title: "Total Margin",
      field: "totalMargin",
      sortable: false,
      type: "custom",
      cell: (props) =>
        calcTotalMargin(
          props.dataItem.carrierCost,
          props.dataItem.customerCost,
          props.dataItem.associateId
        ),
      width: 100,
    },
    {
      title: "Carrier Name",
      field: "carrierName",
      type: "custom",
      cell: (props) =>
        cellLink(
          `/carrier/${props.dataItem.carrierMcNumber}/details`,
          props.dataItem.carrierName,
          null
        ),
    },
    {
      title: "Type",
      field: "freightCategoryType",
      type: "text",
        width: 50
    },
    {
      title: "Third Party",
      field: "thirdPartyCompanyName",
      type: "text",
      className: "show-all-text",
      width: 150
    },
    {
      title: "Pickup Date",
      field: "bolDate",
      type: "date",
      width: 80,
    },
    {
      title: "City From",
      field: "fromCity",
      type: "text",
      width: 120,
      className: "show-all-text",
    },
    {
      title: "State From",
      field: "fromState",
      type: "text",
      width: 50,
    },
    {
      title: "City To",
      field: "toCity",
      dataType: "text",
      width: 120,
      className: "show-all-text",
    },
    {
      title: "State To",
      field: "toState",
      dataType: "text",
      width: 50,
    },
    {
      title: "Ship Flags",
      field: "",
      type: "custom",
        cell: (props) => <LoadFlagsCell {...props} />,
      sortable: false,
      width: 100,
    },
    {
      title: "Customer Name",
      field: "customerName",
      type: "custom",
      cell: (props) =>
        renderCustomerName(
          `/address-book/${props.dataItem.customerId}/details`,
          props.dataItem.customerName,
          props.dataItem.associateId
        ),
    },
    {
      title: "Equipment Type",
      field: "equipmentType",
      dataType: "text",
      className: "show-all-text",
    },
  ];

    const applyAdditionalFilters = (odata) => {
        odata = odata ?? {
            top: 20,
            skip: 0
        };

        const filter = {};
        if(filterAssociate) filter.associateId = filterAssociate.id;
        if(myShipmentsOnly) filter.associateId = activeAssociateId;
        if(fromCity) filter.fromCity = {substringof: fromCity};
        if(fromState) filter.fromState = fromState;
        if(toCity) filter.toCity = {substringof: toCity};
        if(toState) filter.toState = toState;
        if(equipmentType) filter.equipmentType = equipmentType;
        if(customerName) filter.customerName = customerName;
        if(isBlind) filter.isBlind = isBlind;
        if(isHazMat) filter.isHazMat = isHazMat;
        if(isMultiStop) filter.isMultiStop = isMultiStop;
        if(pickupDateFrom || pickupDateTo) filter.bolDate = {};

        if(pickupDateFrom)  {
            const pickupDateFromUtcStartOfDay = new Date(Date.UTC(pickupDateFrom.getFullYear(), pickupDateFrom.getMonth(), pickupDateFrom.getDate(), 0, 0, 0, 0));
            filter.bolDate.ge = pickupDateFromUtcStartOfDay.toISOString();
        }

        if(pickupDateTo)  {
            const pickupDateToUtcEndOfDay = new Date(Date.UTC(pickupDateTo.getFullYear(), pickupDateTo.getMonth(), pickupDateTo.getDate(), 23, 59, 59, 999));
            filter.bolDate.le = pickupDateToUtcEndOfDay.toISOString();
        }

        odata.filter.and = filter;
        return odata;
    }

    const loadGridData = async (odata) => {
        return await window.shell.gateway.getLaneLookup(odata);
    }

  return (
      <TgfDataGrid
          ref={ref}
          className={"lane-lookup-grid"}
          onLoadDataPage={loadGridData}
          onApplyAdditionalDataStateAsOdata={applyAdditionalFilters}
          gridColumns={gridColumns}
          sortable={true}
          sort={[{field: "bolNumber", dir: "desc"}]}
          pageable={{
              info: true,
              pageSize: 20,
              buttonCount: 10,
              pageSizes: [5, 10, 20, 50, 100, 500],
          }}
          skipInitialLoad={true}
      />
  )
});

export default LaneLookupListingTable
