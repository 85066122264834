import React from 'react';
import { Grid } from '@material-ui/core';

import AppAlertStatic from '../../../core/components/AppAlertStatic';
import AppCheckbox from '../../../core/components/inputs/AppCheckbox';
import AppDialog from '../../../core/components/AppDialog/AppMuiDialog';
import AppInput from '../../../core/components/inputs/AppInput/AppMuiInput';
import AppPhoneInput from "../../../core/components/inputs/AppPhoneInput/AppMuiPhoneInput";
import AppValidationFailure from '../../../core/components/AppValidationFailure';
import CheckNumberRegExp from "../../../core/text/CheckNumberRegExp";
import {Button} from "@progress/kendo-react-buttons";

const renderModifyContactForm = ({ values, errors, isValid, isDirty, setFieldValue, submitForm }) => {

  const handlePhoneChange = (key) => (e) => {
    const value = e.target.value.match(CheckNumberRegExp) ?
      e.target.value : null;
    setFieldValue(key, value);
  };

  const actions = [
    {
      title: 'Cancel',
      action: values.onClose
    },
    {
      title: 'Save',
      action: submitForm,
      disabled: !isValid || !isDirty
    }
  ];

  const handleGrantPortalAccess = () => {
       values.onGrantPortalAccess(values.companyId, values.id);
       values.onClose();
  }

    const handleRevokePortalAccess = () => {
        values.onRevokePortalAccess(values.companyId, values.id);
        values.onClose();
    }

  const handleFieldChange = (key) => (e) =>
    setFieldValue(key, e.target.value);

  const handleFieldCheck = (key) => (e) =>
    setFieldValue(key, e.target.checked);

  const hasOtherPrimary = values.primaryContactId ?
    values.primaryContactId !== values.id :
    false;

  return (
    <AppDialog
      title={values.id && values.id > 0 ? 'Modify Contact' : 'New Contact'}
      open={true}
      onClose={values.onClose}
      actionButtons={actions}
      width="sm"
    >
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <AppInput
              label="First Name"
              value={values.firstName || ''}
              inputProps={{
                maxLength: 30
              }}
              onChange={handleFieldChange('firstName')}
            />
            <AppValidationFailure message={errors.firstName} />
          </Grid>
          <Grid item>
            <AppInput
              label="Last Name"
              value={values.lastName || ''}
              inputProps={{
                maxLength: 30
              }}
              onChange={handleFieldChange('lastName')}
            />
            <AppValidationFailure message={errors.lastName} />
          </Grid>
          <Grid item>
            <AppPhoneInput
              label="Direct Number"
              value={values.phone || ''}
              onChange={handlePhoneChange('phone')}
              name="phone"
              id="phone"
            />
            <AppValidationFailure message={errors.phone} />
          </Grid>
          <Grid item>
            <AppPhoneInput
              label="Mobile Number"
              value={values.cellPhone || ''}
              onChange={handlePhoneChange('cellPhone')}
              name="cellPhone"
              id="cellPhone"
            />
            <AppValidationFailure message={errors.cellPhone} />
          </Grid>
          <Grid item>
            <AppInput
              label="Email"
              value={values.emailAddress || ''}
              inputProps={{
                maxLength: 150
              }}
              onChange={handleFieldChange('emailAddress')}
            />
            <AppValidationFailure message={errors.emailAddress} />
          </Grid>
          <Grid item>
            <AppCheckbox
              type="checkbox"
              id="primary"
              label="Primary"
              checked={values.isPrimary}
              onChange={handleFieldCheck('isPrimary')}
            />
          </Grid>
            {/*These are for the customer portal and will not be looking like this when that happens.*/}
            {/*<Grid item justifyContent={'space-between'}>*/}
            {/*    <Button onClick={handleGrantPortalAccess}>Grant Customer Portal Access</Button>*/}
            {/*    <Button onClick={handleRevokePortalAccess}>Revoke Customer Portal Access</Button>*/}
            {/*</Grid>*/}
          <Grid item>
            {
              hasOtherPrimary && values.isPrimary &&
              <AppAlertStatic color="warning" icon={false}>
                Setting this contact as primary will remove the primary flag from other contacts.
              </AppAlertStatic>
            }
          </Grid>
        </Grid>
    </AppDialog>
  );
};

export default renderModifyContactForm;