import AppMuiCard from "../../../../../../core/components/cards/AppCard";
import {Grid, Typography} from "@material-ui/core";
import React from "react";
import {Label} from "@progress/kendo-react-labels";
import TgfTextBox from "../../../../../../core/kendo/form/inputs/TgfTextBox";
import InfoIconTooltip from "../../../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";


export const ReferenceNumbersCard = (props) => {

    const {
        formValues,
        setFormValues,
        disableAll = false
    } = props;

    const handleChange = (e) => {
        setFormValues({
            ...formValues,
            referenceNumbers: {
                ...formValues.referenceNumbers,
                [e.target.name]: e.target.value
            }
        })
    };

    const cardTitle = (
        <div className={"card-title"}>
            <span>Reference Numbers</span>
            <InfoIconTooltip title={
                <>
                    <p>The information entered below is to complete the corresponding section for the booked shipment in the TGF CRM.  However, the information entered for the Pickup Number will be sent to Globaltranz accordingly.</p>
                </>
            }/>
        </div>
    )

    return (
        <AppMuiCard title={cardTitle}>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                {/*<Grid container item style={{backgroundColor: '#E5E6EC'}}>*/}
                <Grid item xs={12} md={6} style={{backgroundColor: '#E5E6EC'}}>
                    <Typography style={{textAlign: "center"}}>Description</Typography>
                </Grid>
                <Grid item xs={12} md={6} style={{backgroundColor: '#E5E6EC'}}>
                    <Typography style={{textAlign: "center"}}>Number</Typography>
                </Grid>
                {/*</Grid>*/}
                <Grid item xs={12} md={6}>
                    <Label>Pickup {<InfoIconTooltip title={"The Pickup Number information will be sent to Globaltranz accordingly."}/>}</Label>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TgfTextBox
                        id={"pickupNumber"}
                        name={"pickupNumber"}
                        value={formValues.referenceNumbers?.pickupNumber}
                        onChange={handleChange}
                        disabled={disableAll}
                        maxLength={25}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Label>Delivery</Label>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TgfTextBox
                        id={"deliveryNumber"}
                        name={"deliveryNumber"}
                        value={formValues.referenceNumbers?.deliveryNumber}
                        onChange={handleChange}
                        disabled={disableAll}
                    />
                </Grid>
                <Grid item xs={12} justifyContent={"flex-start"}>
                    <Typography style={{color: '#CBAC65', fontStyle: 'italic'}}>
                        Fields below appear on the customer's invoice.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TgfTextBox
                        id={"refNum1Description"}
                        name={"refNum1Description"}
                        value={formValues.referenceNumbers?.refNum1Description}
                        onChange={handleChange}
                        disabled={disableAll}
                    />
                </Grid><Grid item xs={12} md={6}>
                <TgfTextBox
                    id={"refNum1"}
                    name={"refNum1"}
                    value={formValues.referenceNumbers?.refNum1}
                    onChange={handleChange}
                    disabled={disableAll}
                />
            </Grid><Grid item xs={12} md={6}>
                <TgfTextBox
                    id={"refNum2Description"}
                    name={"refNum2Description"}
                    value={formValues.referenceNumbers?.refNum2Description}
                    onChange={handleChange}
                    disabled={disableAll}
                />
            </Grid><Grid item xs={12} md={6}>
                <TgfTextBox
                    id={"refNum2"}
                    name={"refNum2"}
                    value={formValues.referenceNumbers?.refNum2}
                    onChange={handleChange}
                    disabled={disableAll}
                />
            </Grid><Grid item xs={12} md={6}>
                <TgfTextBox
                    id={"refNum3Description"}
                    name={"refNum3Description"}
                    value={formValues.referenceNumbers?.refNum3Description}
                    onChange={handleChange}
                    disabled={disableAll}
                />
            </Grid><Grid item xs={12} md={6}>
                <TgfTextBox
                    id={"refNum3"}
                    name={"refNum3"}
                    value={formValues.referenceNumbers?.refNum3}
                    onChange={handleChange}
                    disabled={disableAll}
                />
            </Grid><Grid item xs={12} md={6}>
                <TgfTextBox
                    id={"refNum4Description"}
                    name={"refNum4Description"}
                    value={formValues.referenceNumbers?.refNum4Description}
                    onChange={handleChange}
                    disabled={disableAll}
                />
            </Grid><Grid item xs={12} md={6}>
                <TgfTextBox
                    id={"refNum4"}
                    name={"refNum4"}
                    value={formValues.referenceNumbers?.refNum4}
                    onChange={handleChange}
                    disabled={disableAll}
                />
            </Grid>
            </Grid>
        </AppMuiCard>
    );
};