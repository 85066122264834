
//sendCustomerPortalInvitation
import ActionTypes from "../../../ActionTypes";
import ActionBuilder from "../../../ActionBuilder2";

const revokeCustomerPortalAccess = async (shell, companyId, contactId) => {

    // eslint-disable-next-line no-unused-vars
    const result = await shell.gateway.revokeCustomerPortalAccess(companyId, contactId);
    return {
        type: ActionTypes.AddressBook.Modification.RevokeCustomerPortalAccess,
        result: true
    };

};

export default ActionBuilder
    .for(revokeCustomerPortalAccess)
    .build();