import {Grid} from "@material-ui/core";
import React from "react";
import TgfDialog from "../../../../../core/kendo/dialog/TgfDialog";
import TgfButtonBasic from "../../../../../core/kendo/buttons/TgfButtonBasic";

export const TotalsWarningModal = (props) => {

    return (
        <TgfDialog title={props?.title}
                   onClose={props.onClose}
                   width={"60vw"}>
            <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={12} style={{textAlign: "center"}}>
                        <p><strong>IMPORTANT</strong></p>
                    <p><strong>Based off the Total Weight and/or the Total Volume of the entered Shipment Contents, this shipment may exceed carriers' cubic capacity and/or linear foot maximums.</strong></p>
                    <p><strong>When carriers determine the shipment exceeds cubic capacity or linear foot maximums, the rate increases dramatically and the transit time may take a lot longer.</strong></p>
                    <p><strong>Please double check all details of the Shipment Contents and always remember our Golden Rules for Quoting/Booking with Globaltranz.</strong></p>
                </Grid>
                <Grid item>
                    <TgfButtonBasic
                        onClick={props.onClose}
                        themeColor={"error"}
                    >
                        GO BACK
                    </TgfButtonBasic>
                </Grid>
                <Grid item>
                    <TgfButtonBasic
                        onClick={() => {props.stepForward(); props.onClose();}}
                    >
                        CONTINUE
                    </TgfButtonBasic>
                </Grid>
            </Grid>
        </TgfDialog>
    );
};