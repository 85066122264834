import {Grid} from "@material-ui/core";
import {usePageTitle} from "../../../../../crm/components/customHooks/misc/usePageTitle";
import {CarrierSummaryCard} from "../BookingForm/includes/CarrierSummaryCard";
import React, {useEffect} from "react";
import {ShipperDetailsCard} from "../BookingForm/includes/ShipperDetailsCard";
import {ConsigneeDetailsCard} from "../BookingForm/includes/ConsigneeDetailsCard";
import {ReferenceNumbersCard} from "../BookingForm/includes/ReferenceNumbersCard";
import {ShipmentContents} from "../ShipmentContents/ShipmentContents";
import {FinancialsCard} from "../BookingForm/includes/FinancialsCard";
import TgfButtonBasic from "../../../../../core/kendo/buttons/TgfButtonBasic";
import {GeneralBolNotesCard} from "../BookingForm/includes/GeneralBolNotesCard";
import {useDispatch} from "react-redux";
import AppAlertStatic from "../../../../../core/components/AppAlertStatic";

export const ReviewAndFinalizePage = (props) => {
    const {
        formValues,
        applyFormValue,
        formData,
        type,
        setFormValues,
        setIsDirty,
        fieldErrors,
        shipmentContentsAreValid,
        setShipmentContentsAreValid,
        defaultShipmentContent,
        stepForward,
        setBookedShipmentData,
        activeStepIdx,
        loadCustomerCreditStatus
    } = props;

    usePageTitle("TGF: Booking");
    const personalDispatch = useDispatch();

    useEffect(() => {
        setTimeout(() => window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth' // Enable smooth scrolling
        }), 100);
    }, [])

    // will eventually use this func when sending the api call to book / finalize
    // to get HH:MM from ready time / close time
    const extractTime = (date) => {
        if(!date) return null;
        return date.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit', hour12: false});
    };

    const finalizeAndBook = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('finalizeAndBook'));
            const shipmentItems = [];
            for (const [i, obj] of Object.entries(formValues.shipmentContents)) {
                shipmentItems.push({
                    packageTypeId: obj.packageType,
                    unitCount: obj.unitCount,
                    unitSaidToContainCount: obj.piecesCount,
                    description: obj.product.productDescription,
                    nmfcNumber: obj?.nmfcCode ? obj.nmfcCode : "",
                    freightClass: obj.class,
                    isHazmat: obj.isHazMat,
                    hazmatType: obj.hazmatType,
                    hazmatCode: obj.hazmatCode,
                    hazmatClass: obj.hazmatClass,
                    packingGroupNumber: obj?.packingGroupNumber ? obj.packingGroupNumber.toString() : 0,
                    weightLbs: obj.weightLbs,
                    lengthIn: obj.dimensionsLength,
                    widthIn: obj.dimensionsWidth,
                    heightIn: obj.dimensionsHeight,
                    isStackable: obj.isStackable,
                    product: obj.product,
                    logProduct: obj.logProduct,
                });
            }

            const shipmentDetails = {
                pickupDateOnly: formValues.pickupDate,
                origin: {
                    companyId: formValues.shipper?.companyId,
                    businessName: formValues.shipper.companyName,
                    primaryContactName: `${formValues.shipper?.primaryContactFirstName} ${formValues.shipper?.primaryContactLastName ? " " + formValues.shipper.primaryContactLastName : ""}`,
                    primaryContactPhone: formValues.shipper.companyBusinessPhone,
                    siteTypeId: formValues.originLocationType ? formValues.originLocationType : formValues.pickupSite,
                    address1: formValues.shipper.companyAddress1,
                    address2: formValues.shipper.companyAddress2,
                    city: formValues.shipper.companyCity,
                    state:formValues.shipper.companyStateProvince,
                    postalCode: formValues.originZipPostal,
                    countryRegion: formValues.originCountry,
                    isLiftGatePickup: formValues.originAccessorials.includes(1),
                    isInsidePickup: formValues.originAccessorials.includes(2),
                    byAppointmentOnly: formValues.originAccessorials.includes(3),
                    serviceWindowOpen: extractTime(formValues.shipper.readyTime),
                    serviceWindowClosed: extractTime(formValues.shipper.closeTime)
                },
                destination: {
                    companyId: formValues.consignee?.companyId,
                    businessName: formValues.consignee?.companyName,
                    primaryContactName: `${formValues.consignee?.primaryContactFirstName} ${formValues.consignee?.primaryContactLastName ? " " + formValues.consignee.primaryContactLastName : ""}`,
                    primaryContactPhone: formValues.consignee?.companyBusinessPhone,
                    siteTypeId: formValues.destinationLocationType ? formValues.destinationLocationType : formValues.deliverySite,
                    address1: formValues.consignee.companyAddress1,
                    address2: formValues.consignee.companyAddress2,
                    city: formValues.consignee.companyCity,
                    state:formValues.consignee.companyStateProvince,
                    postalCode: formValues.destinationZipPostal,
                    countryRegion: formValues.destinationCountry,
                    isLiftGateDelivery: formValues.destinationAccessorials.includes(1),
                    notifyPriorToArrival: formValues.destinationAccessorials.includes(2),
                    isInsideDelivery: formValues.destinationAccessorials.includes(3),
                    byAppointmentOnly: formValues.destinationAccessorials.includes(4),
                    sortAndSegregate: formValues.destinationAccessorials.includes(5),
                    serviceWindowOpen: extractTime(formValues.consignee.readyTime),
                    serviceWindowClosed: extractTime(formValues.consignee.closeTime)
                },
                shipmentItems: shipmentItems,
                declaredValue: formValues.declaredValue,
                itemCondition: formValues.itemCondition ?? "NEW",
                protectFromFreeze: formValues.originAccessorials.includes(4),
                totalWeight: formValues.totalWeight,
                totalDensity: formValues.totalDensity,
                totalVolume: formValues.totalVolume,
                generalBolNotes: formValues.generalBolNotes,
                customer: formValues.customer,
                customerId: formValues.customer.companyId,
                deliveryHours: formValues.deliveryHours,
                shipper: formValues.shipper,
                consignee: formValues.consignee,
                referenceNumbers: formValues.referenceNumbers,
                selectedQuote: formValues.selectedQuote,
                hazmatEmergencyPhone: formValues.hazmatEmergencyPhone,
                customerCost: formValues.customerCost,
                clientDateTime: new Date()
            };
            const result = await window.shell.gateway.createAndBookLtlShipment(formValues.customer?.companyId, shipmentDetails);
            setBookedShipmentData(result);
            await loadCustomerCreditStatus(formValues.customer.companyId);
            stepForward();
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({content: "You're Shipment Has Been Booked"}));
        } catch (e) {
            console.log(e);
            let errorMessage = "Failed to book shipment.";
            if(e?.response?.data?.error && e?.response?.data?.error.includes("The GlobalTranz API service is currently unreachable")) {
                errorMessage = "The GlobalTranz service is currently unavailable. Please try again.";
            }
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: errorMessage,
                color: 'error',
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('finalizeAndBook'));
        }
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <CarrierSummaryCard
                    formValues={formValues}
                    applyFormValue={applyFormValue}
                    formData={formData}
                />
            </Grid>
            <Grid item>
                <AppAlertStatic
                    style={{fontSize: 10}}
                    title={
                        "Newly entered addresses below will automatically be saved as a " +
                        "Shipper/Consignee under the involved customer. The information entered " +
                        "in the Contact Name field will be saved as the primary contact and the " +
                        "entered phone number will be saved as the Business Phone."
                    }
                    color={"warning"}
                />
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={12} md={4}>
                    <ShipperDetailsCard
                        formValues={formValues}
                        setFormValues={setFormValues}
                        setIsDirty={setIsDirty}
                        fieldErrors={fieldErrors}
                        disableAll={true}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ConsigneeDetailsCard
                        formValues={formValues}
                        setFormValues={setFormValues}
                        applyFormValue={applyFormValue}
                        consignee={formValues.consignee}
                        setIsDirty={setIsDirty}
                        fieldErrors={fieldErrors}
                        disableAll={true}
                    />
                </Grid>
                <Grid item xs={12} md={4}>
                    <ReferenceNumbersCard
                        formValues={formValues}
                        applyFormValue={applyFormValue}
                        disableAll={true}
                    />
                </Grid>
            </Grid>
            <Grid item>
                <AppAlertStatic
                    style={{fontSize: 10}}
                    title={
                        `For any displayed shipment contents below to be added to the involved customer's Product Catalog, the "Log" column's toggle must be toggled on accordingly.`
                    }
                    color={"warning"}
                />
            </Grid>
            <Grid item xs={12}>
                <ShipmentContents
                    type={type}
                    formValues={formValues}
                    formData={formData}
                    setFormValues={setFormValues}
                    setIsDirty={setIsDirty}
                    shipmentContentsAreValid={shipmentContentsAreValid}
                    setShipmentContentsAreValid={setShipmentContentsAreValid}
                    defaultShipmentContent={defaultShipmentContent}
                    activeStepIdx={activeStepIdx}
                    disableAll={true}
                />
            </Grid>
            <Grid item xs={12} md={12} lg={4} style={{height: "fit-content"}}>
                <GeneralBolNotesCard
                    formValues={formValues}
                    applyFormValue={applyFormValue}
                    formData={formData}
                    disableAll={true}
                />
            </Grid>
            <Grid item xs={12} md={12} lg={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                        <FinancialsCard
                            formValues={formValues}
                            applyFormValue={applyFormValue}
                            fieldErrors={fieldErrors}
                            disableAll={true}
                        />
                    </Grid>
                    {/*<Grid item xs={12} md={12} lg={12}>*/}
                    {/*    {formValues.isHazMat &&*/}
                    {/*        <HazmatEmergencyContactCard*/}
                    {/*            formValues={formValues}*/}
                    {/*            applyFormValue={applyFormValue}*/}
                    {/*            fieldErrors={fieldErrors}*/}
                    {/*            disabled={true}*/}
                    {/*        />*/}
                    {/*    }*/}
                    {/*</Grid>*/}

                </Grid>


            </Grid>
            <Grid item xs={4}>
                <Grid container spacing={2}>
                    <Grid item justifyContent={"center"}>
                        <div className={'warning-text'}>
                            Clicking the button below will result in the shipment being booked with Globaltranz
                            and a corresponding shipment being built in the CRM for the involved customer.
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <TgfButtonBasic
                            onClick={finalizeAndBook}
                            className={"booking-form__continue-btn"}
                        >
                            FINALIZE SHIPMENT
                        </TgfButtonBasic>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};
