import AppMuiCard from "../../../../../core/components/cards/AppCard";
import React from "react";
import {Grid} from "@material-ui/core";


export const OriginTerminal = (props) => {

    const {
        ltlShipmentDetails
    } = props.data;

    return (
        <AppMuiCard title={"Origin Terminal"}>
            {ltlShipmentDetails?.originTerminal ?
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        Address 1: <strong>{ltlShipmentDetails?.originTerminal?.address1}</strong>
                    </Grid>
                    <Grid item xs={12}>
                        Address 2: <strong>{ltlShipmentDetails?.originTerminal?.address2}</strong>
                    </Grid>
                    <Grid item xs={5}>
                        City: <strong>{ltlShipmentDetails?.originTerminal?.city}</strong>
                    </Grid>
                    <Grid item xs={2}>
                        State: <strong>{ltlShipmentDetails?.originTerminal?.state}</strong>
                    </Grid>
                    <Grid item xs={5}>
                        Zip/Postal: <strong>{ltlShipmentDetails?.originTerminal?.postalCode}</strong>
                    </Grid>
                    <Grid item xs={12}>
                        Contact Phone: <strong>{ltlShipmentDetails?.originTerminal?.primaryContactPhone}</strong>
                    </Grid>
                </Grid>
                :
                <span>N/A</span>
            }

        </AppMuiCard>
    )
}
