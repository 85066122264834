
const isFirstCharNumberOrLetter = (str) => {
    if(!str) return null;
    const firstCharacter = str.trim().charAt(0);
    const numberRegex = /[0-9]/;
    const letterRegex = /[a-zA-Z]/;

    if (numberRegex.test(firstCharacter)) {
        return "NUMBER";
    } else if (letterRegex.test(firstCharacter)) {
        return "LETTER";
    } else {
        return null
    }
};

export default {
    isFirstCharNumberOrLetter
}