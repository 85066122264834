import AppMuiCard from "../../../../../core/components/cards/AppCard";
import React from "react";


export const AvailableDocs = (props) => {
    const {
        ltlTracking,
    } = props.data;
    return (
        <AppMuiCard title={"Available Documents"}>
            {/*  TODO, map out link data when we have some available?  */}
            <span>This feature isn't yet available.</span>
        </AppMuiCard>
    )
}
