import {Grid} from "@material-ui/core";
import React from "react";
import TgfDialog from "../../../../../core/kendo/dialog/TgfDialog";
import TgfButtonBasic from "../../../../../core/kendo/buttons/TgfButtonBasic";
import {useDispatch} from "react-redux";

export const SendToDetailsPageModal = (props) => {

    const personalDispatch = useDispatch();

    const formattedValues = {
        title: props.type === "ltlCarrierPickupNumber" ? "Send Carrier Pickup # to Details Page"
            : "Send Pro # to Details Page",
        placeholderText1: props.type === "ltlCarrierPickupNumber" ? " LTL Carrier Pickup # "
        : " Tracking # ",
        placeholderText2: props.type === "ltlCarrierPickupNumber" ? props.ltlCarrierPickupNumber
            : props.proNumber
    };

    const saveToShipmentDetails = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveShipment'));
            const shipmentData = {
                bolNumber: props.shipment.bolNumber,
            };

            if(props.type === "ltlCarrierPickupNumber") {
                shipmentData.ltlCarrierPickupNumber = props.ltlCarrierPickupNumber;
            } else {
                shipmentData.proNumber = props.proNumber;
            }
            await window.shell.gateway.sendProOrPuNumToCrmShipment(shipmentData);
            props.onClose();
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({content: "Successfully Saved to Shipment Details."}));
        } catch (e) {
            console.error(e);
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: "Failed to Save to Shipment Details",
                color: 'error',
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('saveShipment'));
        }
    }

    return (
        <TgfDialog title={formattedValues.title}
                   onClose={props.onClose}
                   width={"450px"}>
            <Grid container spacing={2} justifyContent={"center"} style={{fontWeight: 'bold'}}>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <span>Confirm you are wanting to place the below information in the
                        {formattedValues.placeholderText1} field on the Details Page of this shipment.</span>
                    <p style={{color: "#2b55ff"}}>{formattedValues.placeholderText2}</p>
                    <span>If the {formattedValues.placeholderText1} field already has information saved, this action will replace such information with the above.</span>
                </Grid>
                <Grid item>
                    <TgfButtonBasic
                        onClick={props.onClose}
                        themeColor={"error"}
                    >
                        CANCEL
                    </TgfButtonBasic>
                </Grid>
                <Grid item>
                    <TgfButtonBasic
                        onClick={saveToShipmentDetails}
                    >
                        CONFIRM
                    </TgfButtonBasic>
                </Grid>
            </Grid>
        </TgfDialog>
    );
};