import React from "react";
import {Button, Grid} from "@material-ui/core";
import PropTypes from "prop-types";
import TgfDialog from "./TgfDialog";

const ConfirmationModal = (props) => {

    return (
        <TgfDialog
            title={props?.title}
            onClose={props.onClose}
            width={500}
        >
            <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={12} style={{textAlign: "center"}}>
                    <span>{props?.description}</span>
                </Grid>
                <Grid item>
                    <Button
                        color={"primary"}
                        variant={"contained"}
                        onClick={props.action}
                    >
                        {props?.confirmBtnText ?? "YES"}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        color={"primary"}
                        variant={"outlined"}
                        onClick={props.onClose}
                    >
                        {props?.cancelBtnText ?? "NO"}
                    </Button>
                </Grid>
            </Grid>
        </TgfDialog>
    )
}

ConfirmationModal.propTypes = {
    // Title Header.
    title: PropTypes.string.isRequired,
    // Short description.
    description: PropTypes.string.isRequired,
    // A delete handler function.
    action: PropTypes.func.isRequired,
    // A onClose handler function that closes this modal.
    onClose: PropTypes.func.isRequired,
}

export default ConfirmationModal;