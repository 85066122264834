import AppMuiCard from "../../../../../core/components/cards/AppCard";
import React from "react";
import {Grid} from "@material-ui/core";
import DateUtils from "../../../../../core/utils/dateUtils/dateUtils";


export const TrackingHistory = (props) => {

    const {
        ltlTracking,
        additionalData
    } = props.data;
    
    const formattedValues = {
        orderStatus: additionalData?.gzOrderStatusTypes ? additionalData.gzOrderStatusTypes.find(x => x.id === ltlTracking?.ShipmentStatus?.OrderStatus)?.display : null,
        estimatedDeliveryDate: DateUtils.getDisplayDateFromDateString(ltlTracking?.ShipmentStatus?.EstimatedDueDate),
        // estimatedDeliveryTime: ltlTracking?.ShipmentStatus?.EstimatedDeliveryTime, // will need some formatting.
        latestTracked: DateUtils.getDisplayDateTimeFromDateTime(DateUtils.getLatestDateTime(ltlTracking?.Track.map(t => t.TrackDateTime))),
        trackingItems: ltlTracking?.Track ? ltlTracking.Track : [],
    }

    return (
        <AppMuiCard title={"Tracking History"}>
            <Grid container spacing={0}>
                <Grid item xs={12}>
                    Status: <strong>{formattedValues.orderStatus}</strong>
                </Grid>
                <Grid item xs={12}>
                    Estimated Delivery Date: <strong>{formattedValues.estimatedDeliveryDate}</strong>
                </Grid>
                {/*<Grid item xs={12}>*/}
                {/*    Estimated Delivery Time: <strong>{formattedValues.estimatedDeliveryTime}</strong>*/}
                {/*</Grid>*/}
                <Grid item xs={12}>
                    Current Location: <strong>{ltlTracking?.ShipmentStatus?.CurrentLocation}</strong>
                </Grid>
                <Grid item xs={12}>
                    Last Tracked: <strong>{formattedValues?.latestTracked}</strong>
                </Grid>
                <Grid item xs={12}>
                    <hr/>
                </Grid>
                <Grid item xs={12}>
                    <Grid container spacing={0} className={"tracking-container"}>
                        {formattedValues.trackingItems.length > 0 ? formattedValues.trackingItems.map(tracking => {
                            return (
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12}>
                                            Shipment Status: <span>{tracking?.StatusDescription}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            Time: <span>{DateUtils.getDisplayTimeFromMilitaryTimeString(tracking?.TrackTime)}</span>
                                        </Grid>
                                        <Grid item xs={6}>
                                            Date: <span>{DateUtils.getDisplayDateFromDateString(tracking?.TrackDate)}</span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            Location: <span>{tracking?.City}, {tracking?.State}</span>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <hr style={{opacity: .2}}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })
                        :
                        <span>No tracking updates available</span>}
                    </Grid>
                </Grid>
            </Grid>
        </AppMuiCard>
    )
}
