import {TextBox} from "@progress/kendo-react-inputs";
import {Label} from "@progress/kendo-react-labels";
import * as React from "react";
import {Tooltip} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const TgfTextBox = React.forwardRef((props, ref) => {

    const classes = makeStyles(theme => ({
        tooltip: {
            backgroundColor: theme.palette.tgfGreen.main,
            textAlign: 'center',
            color: theme.palette.white,
            width: 220,
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: `1px solid ${theme.palette.tgfGrey.light}`,
        },
    }))();

    return (
        <>
            {props?.label && <Label editorId={props.id}>{props.label}</Label>}
            <Tooltip title={(props?.value && props.showTooltip) ? props.value : ""} classes={{ tooltip: classes.tooltip }}>
                <span>
                    <TextBox
                        {...props}
                        className={"tgf-textbox"}
                        ref={ref}
                        value={props.value ?? ''}
                        autoComplete="off"
                    />
                </span>
            </Tooltip>
        </>
    );
});

export default TgfTextBox;
