import AppMuiCard from "../../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import React from "react";

export const CarrierSummaryCard = (props) => {

    const {
        formValues,
        formData
    } = props;

    const formattedValues = {
        originZipPostal: formValues?.originZipPostal,
        originLocationType: formValues?.originLocationType ?
            formData?.originLocationTypes?.find(x => x.id === formValues?.originLocationType)?.display
            : null,
        nonCommercialPickupSite: formValues?.pickupSite ?
            formData.pickupSiteOptions.find(x => x.id === formValues.pickupSite)?.display
            : null,
        originAccessorials: formValues?.originAccessorials.map((id, idx) => {
            return <span
                key={idx}>{formData.originAccessorialOptions.find(option => option.id === id)?.display}, </span>;
        }),
        destinationZipPostal: formValues?.destinationZipPostal,
        destinationLocationType: formValues?.destinationLocationType ?
            formData?.destinationLocationTypes?.find(x => x.id === formValues.destinationLocationType)?.display
            : null,
        nonCommercialDeliverySite: formValues?.deliverySite ?
            formData?.deliverySiteOptions?.find(x => x.id === formValues.deliverySite)?.display
            : null,
        destinationAccessorials: formValues.destinationAccessorials.map((id, idx) => {
            return <span
                key={idx}>{formData.destinationAccessorialOptions.find(option => option.id === id)?.display}, </span>;
        }),
        totalWeight: formValues?.totalWeight,
        isHasMat: Object.values(formValues.shipmentContents).some(x => x.isHazMat === true) ? "Yes" : "No",
        guaranteedService: formValues?.selectedQuote?.guaranteedRate ? "Yes" : "No",
        selectedCarrier: formValues?.selectedQuote?.carrierName,
        guaranteedVerbiage: formValues?.selectedQuote?.guaranteedRate?.guarantee,
    };

    if(formValues.protectFromFreeze === true) {
        formattedValues.originAccessorials.unshift(<span
            key={99}>Protect From Freeze, </span>)
    }

    return (
        <AppMuiCard title={'Location, Accessorial, and Carrier Summary'}>
            <Grid container>
                <Grid item xs={12} md={3}>
                    <p className={"carrier-summary-new-line"}>
                        P/U Location Type:{' '}
                        <span className={"carrier-summary-card highlighted"}>{formattedValues.originLocationType}</span>
                    </p>
                    <p className={"carrier-summary-new-line"}>
                        Del. Location Type:{' '}
                        <span className={"carrier-summary-card highlighted"}>
                            {formattedValues.destinationLocationType}
                        </span>
                    </p>
                    <p className={"carrier-summary-new-line"}>
                        Guaranteed Service:{' '}
                        <span className={"carrier-summary-card highlighted"}>{formattedValues.guaranteedService}</span>
                    </p>
                </Grid>
                <Grid item xs={12} md={3}>
                    <p className={"carrier-summary-new-line"}>
                        Non Commercial P/U Site:{' '}
                        <span className={"carrier-summary-card highlighted"}>
                            {formattedValues.nonCommercialPickupSite}
                        </span>
                    </p>
                    <p className={"carrier-summary-new-line"}>
                        Non Commercial Del. Site:{' '}
                        <span className={"carrier-summary-card highlighted"}>
                            {formattedValues.nonCommercialDeliverySite}
                        </span>
                    </p>
                    <p className={"carrier-summary-new-line"}>
                        Selected Carrier:{' '}
                        <span className={"carrier-summary-card highlighted"}>{formattedValues.selectedCarrier}</span>
                    </p>
                </Grid>
                <Grid item xs={12} md={6}>
                    <p className={"carrier-summary-new-line"}>
                        P/U Accessorials:{' '}
                        <span className={"carrier-summary-card highlighted"}>{formattedValues.originAccessorials}</span>
                    </p>
                    <p className={"carrier-summary-new-line"}>
                        Del. Accessorials:{' '}
                        <span className={"carrier-summary-card highlighted"}>
                            {formattedValues.destinationAccessorials}
                        </span>
                    </p>
                    { formattedValues.guaranteedVerbiage &&
                        <p className={"carrier-summary-new-line"}>
                            Guaranteed Verbiage:{' '}
                            <span className={"carrier-summary-card highlighted"}>
                                {formattedValues.guaranteedVerbiage}
                            </span>
                        </p>
                    }
                </Grid>
            </Grid>
        </AppMuiCard>
    );
};