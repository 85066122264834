import * as yup from "yup";
import Core from "@atomos/core";

export const bookingFormValidationSchema = yup.object().shape({
    pickupDate: yup.date().required(),
    customerCost: yup.number().min(1, "Must be > 0").required(),
    shipper: yup.object().shape({
        companyName: yup.string().max(50).required(),
        companyAddress1: yup.string().max(50).required(),
        companyAddress2: yup.string().max(50).nullable(),
        companyCity: yup.string().max(30).required(),
        companyStateProvince: yup.string().required(),
        companyPostalCode: yup.string().max(7).required(),
        primaryContactFirstName: yup.string().max(30, 'Contact must be less than 30 characters').required(),
        companyBusinessPhone: yup.string().required()
            .matches(Core.Text.PhoneRegExp, 'Phone Number is invalid.'),
        readyTime: yup.date().required(),
        closeTime: yup.date().required(),
    }),
    consignee: yup.object().shape({
        companyName: yup.string().max(50).required(),
        companyAddress1: yup.string().max(50).required(),
        companyAddress2: yup.string().max(50).nullable(),
        companyCity: yup.string().max(30).required(),
        companyStateProvince: yup.string().required('is required'),
        companyPostalCode: yup.string()
            .max(7)
            .required('is required'),
        primaryContactFirstName: yup.string().max(30, 'Contact must be less than 30 characters').required(),
        companyBusinessPhone: yup.string()
            .nullable()
            .matches(Core.Text.PhoneRegExp, 'Phone Number is invalid.')
            .required(),
        readyTime: yup.date().required(),
        closeTime: yup.date().required(),
    }),
    hazmatEmergencyPhone: yup.string().when('isHazMat', {
        is: true,
        then: (schema) => schema.required(),
        otherwise: (schema) => schema.nullable(),
    })
});