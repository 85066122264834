import React from "react";
import {Button} from "@progress/kendo-react-buttons";

const TgfButtonBasic = (props) => {

    return (
            <Button
                {...props}
                style={props.style ?? {fontWeight: 500}}
                themeColor={props.themeColor ?? "primary"}
                className={`tgf-button ${props.className}`}
            >{props.children}</Button>
    )
}

export default TgfButtonBasic