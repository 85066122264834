import Core from "@atomos/core";
import Qs from "qs";
import RoleNames from "./persona/RoleNames";
import StatusTypeNames from "./shipment/StatusTypeNames";
import Moment from "moment-timezone";
import getUtcDate from "../core/utils/getUtcDate";
import buildOdataQuery from "odata-query-sequelize";

const PhoneNumberGroupsRegExp = /^(\d{3})(\d{3})(\d{4})$/;

// Singleton variables.
let categoryTypes;

class GatewayFacade {

  constructor(communicator) {
    this.communicator = communicator;
  }


  //#region : Static Methods :

  static makeDate(value) {
    return value ?
      Moment(value).tz('America/Chicago').toDate() : null;
  }

  /**
   * makeDateOnly is used to take a simple date-only string
   * in the format of 'yyyy-mm-dd' and create a Date instance
   * for it.  Moment is used to ensure the Date instance has
   * a timestamp of midnight, whereas new Date(value) results
   * in a timezone specific timestamp which can alter the date.
   * @param {string} value - The value to make into a Date.
   * @return {Date|*}
   */
  static makeDateOnly(value) {
    const hasStringValue = Core.Utils.isString(value) && value.length > 0;

    const processedValue = hasStringValue && value.includes('T') ?
      value.split('T')[0] : value;

    return hasStringValue ?
      Moment(processedValue).toDate() :
      value;
  }

  /**
   * makePhone is used to convert the digits
   * of a phone number into a properly formatted
   * string.
   * @param value
   */
  static makePhone(value) {
    let phoneValue = value;

    if (Core.Utils.isString(phoneValue)) {
      const numbersText = Core.Utils.extractNumbers(phoneValue);

      if (numbersText.length === 10) {
        const numberGroups = numbersText.match(PhoneNumberGroupsRegExp);
        if (numberGroups) {
          phoneValue = `(${numberGroups[1]}) ${numberGroups[2]}-${numberGroups[3]}`;
        }
      } else {
        phoneValue = null;
      }
    }

    return phoneValue;
  }

  static extract(source, struct) {

    const extractedPairs = Core.Utils
      .toPairs(struct)
      .map(([key]) => [key, source[key]]);

    return Core.Utils
      .fromPairs(extractedPairs);
  }

  //#endregion

  //#region : Site Methods :

  async getSiteDescriptor() {
    return await this.communicator.get('../site-descriptor.json');
  }

  //#endregion

  //#region: Announcements :

  async getAnnouncement() {
    const announcement = await this.communicator.get('/settings/announcement');
    return announcement;
  }

  async updateAnnouncementNote(announcementNote) {

    Core.Validation.Mandate.argIsNonEmptyString(announcementNote, 'announcementNote');

    const updatedNoteData = await this.communicator
      .post(`/settings/announcement`, { announcementNote });

    return updatedNoteData;
  }

  async getSpecialOccasionNote() {
    return await this.communicator.get("/settings/special-occasion-note");
  }

  async saveSpecialOccasionNote(noteData) {
    return await this.communicator.post("/settings/special-occasion-note", noteData);
  }

  //#endregion

  //#region : Associates :

  async authenticate(username, password) {
    return await this.communicator
      .post('/security/login', { username, password });
  }

  async logout() {
    await this.communicator
      .get('/security/logout');
  }

  async terminateSessions(associateId) {
    await this.communicator
      .post('/security/terminate-sessions', { associateId });
  }

  async whoami() {
    const associate = await this.communicator
      .get('/security/whoami');
    return this.coerceAssociate(associate);
  }

  async getAllBrokerAssociates(queensOnly = false) {

    const roles = await this.getAllRoles();

    const allowedRoles = queensOnly ?
      [RoleNames.Queen] : [RoleNames.Ace, RoleNames.Queen];

    const roleIds = roles
      .filter(role => allowedRoles.includes(role.name))
      .map(roles => roles.id);

    const options = {
      roleId: roleIds
    };

    const branchAssociates = await this.searchAllAssociates(options);

    return branchAssociates
  }

  async getAssociateNotes(associateId) {
    const associateNotes = await this.communicator.get(`/associate/${associateId}/note`);

    return associateNotes
      .map(note => this.coerceAssociateNote(note));
  }

  coerceAssociateNote(note) {
    const overrides = {
      createDate: GatewayFacade.makeDateOnly(note.createDate),
      associate: note.associate ? this.coerceAssociate(note.associate) : null,
      createdByAssociate: note.createdByAssociate ? this.coerceAssociate(note.createdByAssociate) : null
    };
    return Core.Utils
      .merge({}, this.createEmptyAssociateNote(), note, overrides);
  }

  createEmptyAssociateNote() {
    return {
      id: 0,
      associateId: null,
      typeId: null,
      createDate: null,
      createdByAssociateId: null,
      content: null
    };
  }

  async getAllAssociates(options = {}) {
    const {
      filter,
      roles = [],
      offset = 0,
      limit = 0,
      sort = [['lastName', 'asc']]
    } = options;

    const queryParams = {
      filter: JSON.stringify(filter),
      roles,
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);
    const { count, associates } = await this.communicator.get(`/associates?${qs}`);

    return {
      associates: associates.map(a => this.coerceAssociate(a)),
      count
    };
  }

  listAssociates = async (odata = {}) => {
    const qs = buildOdataQuery(odata);
    const dataPage = await this.communicator.get(`/associates/page${qs}`);
    dataPage.items = dataPage.items.map(a => this.coerceAssociate(a));
    return dataPage;
  }

  async getPageOfManageUserAssociateJacks (odata) {
    const qs = buildOdataQuery(odata);
    return await this.communicator.get(`/admin/manage-users/list-associate-jacks${qs}`);
  }

  async getPageOfAssociateNotes (odata) {
    const qs = buildOdataQuery(odata);
    return await this.communicator.get(`/admin/manage-users/list-associate-notes${qs}`);
  }

  async searchAllAssociates(options = {}) {

    const {
      searchTerm,
      roleId,
      offset = 0,
      limit = 0,
      sort = [['lastName', 'asc']],
      filter = null
    } = options;

    const queryParams = {
      searchTerm,
      roleId,
      offset,
      limit,
      sort,
      filter: JSON.stringify(filter)
    };

    if(options.includeInactive) {
      queryParams.includeInactive = true;
    }

    const qs = Qs.stringify(queryParams);

    const { associates } = await this.communicator.get(`/associate?${qs}`);
    return associates
      .map(a => this.coerceAssociate(a));
  }

  async getAssociateFormData(associateId) {
    const data = await this.communicator.get(`/associate/manage-account/${associateId}`);
    if(data) {
        // backward compatibility
        data.businessPhone = GatewayFacade.makePhone(data.businessPhone);
        data.cellPhone = GatewayFacade.makePhone(data.cellPhone);
    }
    return data;
  }
  async getAssociate(associateId, allDetails = false) {
    const qs = Qs.stringify({ allDetails });
    const associate = await this.communicator
      .get(`/associate/${associateId}?${qs}`);
    return this.coerceAssociate(associate);
  }

  async calculateYearlyMarginGoals(associateId) {
    const results = await this.communicator
        .get(`/associate/${associateId}/calculate-yearly-margin-goals/`);
    return results;
  }

  async calculateYearlyMarginGoalsAll(associateId, options = {}) {
    const {
      filter,
      offset = 0,
      limit = 20,
      sort = []
    } = options;

    const queryParams = {
      filter: JSON.stringify(filter),
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);
    const { count, breakdowns } = await this.communicator
      .get(`/${associateId}/calculate-yearly-margin-goals-all?${qs}`);
    return {count, breakdowns} ;
  }

  async getAssociateNoteTypes() {
    return await this.communicator.get('/associate-note-type');
  }

  async saveAssociateNote(note) {
    const updatedNote = await this.communicator
      .post(`/associate/${note.associateId}/note`, note);

    return this.coerceAssociateNote(updatedNote);
  }

  async saveAssociate(associate) {
    const updatedAssociate = await this.communicator
      .post(`/associate`, associate);
    return this.coerceAssociate(updatedAssociate);
  }

  async verifyDatCredentials(username, password) {
    const response = await this.communicator.post('/loadboard/credentials/verify/dat', {username:username, password:password});
    return response;
  }

  async verifyTruckstopCredentials(username, password) {
    const response = await this.communicator.post('/loadboard/credentials/verify/truckstop', {username:username, password:password});
    return response;
  }

  async getAssociateJacks(associateId) {
    const associates = await this.communicator.get(`/associate/${associateId}/jack`);
    return associates
      .map(a => this.coerceAssociate(a));
  }

  coerceLaneLookup = (laneLookup) => {
    const overrides = {
      bolDate: GatewayFacade.makeDateOnly(laneLookup.bolDate)
    };
    return Core.Utils
      .merge({}, this.createLaneLookup(), laneLookup, overrides);
  }

  async getCustomerReport(startDate, endDate, customerId, associateId = null, sort = [], offset = 0, limit = 25) {
    const filters = {
      customerId,
      bolDate: {
        $between: [startDate, endDate]
      }
    };

    let associateFilters = {};
    if (associateId) {
      associateFilters = {
        associateId
      }
    }

    const queryParams = {
      filter: JSON.stringify({
        ...filters,
        ...associateFilters
      }),
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);
    const { count, shipments } = await this.communicator.get(`/reports/customer-report?${qs}`);

    return {
      shipments: shipments.map(s => this.coerceCustomerReportShipment(s)),
      count
    };
  }

  coerceCustomerReportShipment(shipment) {
    const overrides = {
      bolDate: GatewayFacade.makeDateOnly(shipment.bolDate)
    };
    return Core.Utils
      .merge({}, this.createCustomerReportShipment(), shipment, overrides);
  }

  createCustomerReportShipment() {
    return {
      bolNumber: null,
      associateId: null,
      carrierCost: null,
      customerCost: null,
      freightCategory: null,
      carrierName: null,
      carrierMcNumber: null,
      thirdPartyCompanyName: null,
      bolDate: null,
      fromState: null,
      toState: null,
      equipmentType: null
    };
  }

  async getStandardReports() {
    return await this.communicator.get(`/standard-reports/list`);
  }

  async getLaneLookup(odata = {}) {
    const qs = buildOdataQuery(odata);
    const dataPage = await this.communicator.get(`/reports/lane-lookup${qs}`);
    dataPage.items = dataPage.items.map(a => this.coerceLaneLookup(a));
    return dataPage;

  }

  async getMissingCreditApp(offset = 0, limit = 25, sort = []) {
    const queryParams = {
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);

    const { count, companies } = await this.communicator.get(`/reports/missing-credit-app?${qs}`);

    return {
      companies: companies.map(c => this.coerceMissingCreditAppCompany(c)),
      count
    };
  }

  coerceMissingCreditAppCompany(companyData) {

    const overrides = {
      companyCreditAppReceivedDate: GatewayFacade.makeDateOnly(companyData.companyCreditAppReceivedDate),
      firstShipmentDate: GatewayFacade.makeDateOnly(companyData.firstShipmentDate),
      lastShipmentDate: GatewayFacade.makeDateOnly(companyData.lastShipmentDate)
    };
    return Core.Utils
      .merge({}, this.createMissingCreditAppCompany(), companyData, overrides);

  }

  createMissingCreditAppCompany() {
    return {
      companyId: null,
      companyName: null,
      companyCreditAppReceivedDate: null,
      companyCreditAppOnFile: null,
      companyIsInactive: null,
      companyIsDisabled: null,
      companyCreditLimit: null,
      firstShipmentDate: null,
      lastShipmentDate: null,
      associateId: null,
      associateFirstName: null,
      associateLastName: null
    };
  }


  coerceBolDate = (dateValue) => {
    const overrides = {
      bolDate: GatewayFacade.makeDateOnly(dateValue.bolDate)
    };
    return Core.Utils
      .merge({}, this.createBolDate(), dateValue, overrides);
  }

  async getSearchLoads(searchTerm, offset = 0, limit = 25, sort = []) {

    const filters = ['proNumber', 'pickupNumber', 'deliveryNumber', 'refNum1', 'refNum2', 'refNum3', 'refNum4', 'ratingRefNumber', 'note', 'rateConNote']
      .map(fieldName => ({
        [fieldName]: {
          $like: `%${searchTerm}%`
        }
      }));

    const queryParams = {
      filter: JSON.stringify({ $or: filters }),
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);
    const { count, shipments } = await this.communicator.get(`/reports/search-loads?${qs}`);

    return {
      shipments: shipments.map(s => this.coerceShipment(s)),
      count
    };
  }

  async getCompaniesMissingPrimaryContact(offset = 0, limit = 25, sort = []) {
    const queryParams = {
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);
    const { count, companies } = await this.communicator.get(`/reports/missing-primary-contacts?${qs}`);

    return {
      companies,
      count
    };
  }

  async getInvoiceAging(options) {

    const {
      limitToPaidDiscrepancy,
      ignoreDisputed,
      ignoreClaim,
      associateId = null,
      customerPaidStatusId,
      disputeStatusId,
      claimStatusId,
      collectionAssociateId,
      offset = 0,
      limit = 25,
      sort = []
    } = options;

    let paidDiscrepancyCriteria = {};
    let ignoredStatusIds = [];
    let statusIdFilter = {};
    let collectionsFilter = {};

    if (limitToPaidDiscrepancy) {
      // Limit results to "paid discrepancy" in which its an either-or of
      // one of these fields being marked as paid.
      // flag=true or status=customer paid but not both
      paidDiscrepancyCriteria = {
        $or: [
          { customerWasPaid: true, statusId: { $not: customerPaidStatusId } },
          { customerWasPaid: false, statusId: customerPaidStatusId },
        ]
      };
    }
    else {
      paidDiscrepancyCriteria = {
        $not: [
          {customerWasPaid: true, statusId: customerPaidStatusId}
        ]
      };
    }

    if (ignoreDisputed) {
      ignoredStatusIds.push(disputeStatusId);
    }

    if (ignoreClaim) {
      ignoredStatusIds.push(claimStatusId);
    }

    if(ignoredStatusIds.length > 0) {
      statusIdFilter = {
        statusId: { $not: ignoredStatusIds }
      };
    }

    const filters = {
      customerName: {
        $ne: 'CANCELLED/DELETED LOAD'
      },
      invoiceDate: {
        $ne: null
      },
      creditRiskIndicator: false,
      ...paidDiscrepancyCriteria,
      ...statusIdFilter,
      ...collectionsFilter
    };

    if (Core.Utils.isNumber(associateId) && associateId > 0) {
      filters.associateId = associateId;
    }

    // Null allows for unassigned filtering.
    // eslint-disable-next-line no-mixed-operators
    if (Core.Utils.isNumber(collectionAssociateId) && collectionAssociateId > 0 || collectionAssociateId === null) {
      filters.collectionAssociateId = collectionAssociateId;
    }

    const queryParams = {
      filter: JSON.stringify(filters),
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);
    const { count, documents: shipments } = await this.communicator.get(`/reports/invoice-aging?${qs}`);

    return {
      shipments: shipments
        .map(s => this.coerceInvoiceAgingShipment(s)),
      count
    };
  }

  async getMarginContribution (odata) {
    const qs = buildOdataQuery(odata);
    const result = await this.communicator.get(`/reports/margin-contribution/${qs}`);

    return result;
  }

  async getMarginContributionChart (agentId) {
    const result = await this.communicator.get(`/charts/margin-contribution/${agentId}/`);
    return result;
  }

  async getLastShipmentReport (odata) {
    const qs = buildOdataQuery(odata);

    const result = await this.communicator.get(`/reports/last-shipment-report/${qs}`);

    return result;
  }

  async getLastShipmentReportCounts (filter) {
    const qs = Qs.stringify(filter);

    const result = await this.communicator.get(`/reports/last-shipment-report-counts?${qs}`);

    return result;
  }

  async getVoidShipmentReport (odata) {
    const qs = buildOdataQuery(odata);

    const result = await this.communicator.get(`/shipment/list-void-shipments/${qs}`);

    return result;
  }

  async getUserSessions (odata) {
    const qs = buildOdataQuery(odata);

    const result = await this.communicator.get(`/reports/user-sessions/${qs}`);

    return result;
  }

  coerceInvoiceAgingShipment(shipmentData) {
    const overrides = {
      bolDate: GatewayFacade.makeDateOnly(shipmentData.bolDate),
      invoiceDate: GatewayFacade.makeDateOnly(shipmentData.invoiceDate),
      invoiceActualDeliveryDate: GatewayFacade.makeDateOnly(shipmentData.invoiceActualDeliveryDate),
      customerInvoiceDueDate: GatewayFacade.makeDateOnly(shipmentData.customerInvoiceDueDate)
    };
    return Core.Utils
      .merge({}, this.createEmptyInvoiceAgingShipment(), shipmentData, overrides);
  }

  createEmptyInvoiceAgingShipment() {
    return {
      bolNumber: null,
      daysPastDue: null,
      customerInvoiceDueDate: null,
      carrierCost: null,
      customerCost: null,
      carrierName: null,
      carrierMcNumber: null,
      customerPaymentTerms: null,
      bolDate: null,
      thirdPartyCompanyName: null,
      associateId: null,
      repName: null,
      statusId: null,
      shipmentStatusName: null,
      customerId: null,
      customerName: null,
      customerRep: null,
      equipmentType: null,
      invoiceDate: null,
      freightCategory: null,
      customerWasPaid: false,
      invoiceActualDeliveryDate: null,
      companyAssociateId: null
    };
  }

  async getPaidAndUnpaidAssociateStatement(associateId, startDate) {
    const queryParams = {
      date: startDate
    };
    const qs = Qs.stringify(queryParams);
    const amounts = await this.communicator
      .get(`/associate/${associateId}/total-statement?${qs}`);
    return amounts;
  }

  async getCommissionRate(associateId, commissionStartDate) {
    const queryParams = {
      date: commissionStartDate
    };
    const qs = Qs.stringify(queryParams);
    const commissionRate = await this.communicator.get(`/associate/${associateId}/commission-rate?${qs}`);
    return this.coerceCommissionRate(commissionRate);
  }

  async getAssociatePayStatementDocument(associateId, payPeriodStartDate) {

    const dateText = payPeriodStartDate.toMoment().utc().format('YYYY-MM-DD');
    const url = `/associate/${associateId}/pay-statement/${dateText}`;
    const document = await this.communicator.get(url);
    return this.coerceAssociateDocument(document);
  }

  async getMonthlyAssociatePayments(paymentDate) {
    const queryParams = {
      date: paymentDate
    };

    const qs = Qs.stringify(queryParams);
    const { count, monthlyAssociatePayments } = await this.communicator
      .get(`/associate/monthly-payments?${qs}`);
    return {
      monthlyAssociatePayments: monthlyAssociatePayments.map(pay => this.coerceMonthlyAssociatePayment(pay)),
      count
    };
  }

  async getMonthlyCommissionRate(associateId, startDate, endDate) {

    const queryParams = {
      startDate: startDate.toMoment().utc().format('YYYY-MM-DD'),
      endDate: endDate.toMoment().utc().format('YYYY-MM-DD')
    };

    const qs = Qs.stringify(queryParams);
    const commissionRate = await this.communicator.get(`/associate/${associateId}/monthly-commission-rate?${qs}`);

    return commissionRate.map(cr => this.coerceCommissionRate(cr));
  }

  async getMonthlyCommissionBreakdown(associateId, startDate, endDate) {
    const queryParams = {
      startDate: startDate,
      endDate: endDate
    };
    const qs = Qs.stringify(queryParams);
    const commissionBreakdown = await this.communicator.get(`/associate/${associateId}/monthly-commission-breakdown?${qs}`);

    return commissionBreakdown;
  }

  async getAssociatePayPeriodCommissionAdjustments(associateId, options = {}) {

    const {
      commissionDate,
      offset = 0,
      limit = 25,
      sort = [['relatedBolNumber', 'desc']]
    } = options;

    const queryParams = {
      date: commissionDate,
      offset,
      limit,
      sort
    };
    const qs = Qs.stringify(queryParams);
    const { count, adjustments } = await this.communicator
      .get(`/associate/${associateId}/pay-period-adjustment?${qs}`);
    return {
      adjustments: adjustments.map(adj => this.coerceCommissionAdjustment(adj)),
      count
    };
  }

  async getAssociateMonthlyAdjustments(associateId, options = {}) {

    const {
      startDate = new Date(),
      endDate = new Date(),
      offset = 0,
      limit = 25,
      sort = [['relatedBolNumber', 'desc']]
    } = options;

    const queryParams = {
      startDate,
      endDate,
      offset,
      limit,
      sort
    };
    const qs = Qs.stringify(queryParams);
    const { count, adjustments } = await this.communicator
      .get(`/associate/${associateId}/monthly-adjustment?${qs}`);
    return {
      adjustments: adjustments.map(adj => this.coerceCommissionAdjustment(adj)),
      count
    };
  }

  async deletePayPeriodAdjustment(associateId, adjustmentId) {
    const result = await this.communicator
      .delete(`/associate/${associateId}/commission-adjustment/${adjustmentId}`);
    return result;
  }

  async getAssociatePayPeriodShipments(associateId, options = {}) {

    const {
      startDate,
      offset = 0,
      limit = 25,
      sort = [['bolNumber', 'desc']]
    } = options;

    const queryParams = {
      date: startDate,
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);
    const { count, shipments } = await this.communicator
      .get(`/associate/${associateId}/pay-period-shipment?${qs}`);
    return {
      shipments: shipments.map(s => this.coerceShipment(s)),
      count
    };
  }

  async getAssociateGrossMarginBreakdown(startDate, endDate, associateId, customerId) {

    const queryParams = {
      startDate: startDate,
      endDate: endDate,
      associateId: associateId,
      customerId: customerId
    };

    const qs = Qs.stringify(queryParams);
    const groupedTotals = await this.communicator
      .get(`/associate/monthly-gross-margin?${qs}`);
    return groupedTotals;
  }

  async getAssociateMonthlyGmShipments(options = {}) {

    const {
      associateId,
      startDate = new Date(),
      endDate = new Date(),
      offset = 0,
      limit = 25,
      sort = [['bolNumber', 'asc']]
    } = options;

    const queryParams = {
      associateId,
      startDate,
      endDate,
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);
    const { count, shipments } = await this.communicator
      .get(`/shipment/monthly-shipment?${qs}`);

    return {
      shipments: shipments.map(s => {
        s.invoice = this.coerceInvoice(s.invoice);
        return this.coerceShipment(s);
      }),
      count
    };
  }

  async savePayPeriodAdjustment(associateId, commissionAdjustmentModel) {

    return await this.communicator
      .post(`/associate/${associateId}/commission-adjustment`, commissionAdjustmentModel);
  }

  async finalizeStatement(associateId, commissionDate) {

    const params = {
      date: commissionDate
    };

    await this.communicator.post(`/associate/${associateId}/finalize-statement`, params);
  }

  async saveCommissionRate(associateId, commissionRateModel) {
    const emptyCommissionRateModel = this.createCommissionRate();

    const commissionRateData = GatewayFacade.extract(commissionRateModel, emptyCommissionRateModel);

    const updatedCommissionRate = await this.communicator
      .post(`/associate/${associateId}/commission-rate`, commissionRateData);

    return this.coerceCommissionRate(updatedCommissionRate);
  }

  createCommissionAdjustment() {
    return {
      id: 0,
      startDate: null,
      endDate: null,
      associateId: null,
      relatedBolNumber: null,
      reason: null,
      deductionAmount: null,
      additionAmount: null,
      note: null,
      supportDocAttachment: null,
      repPaid: 0,
      repPadConfirmed: 0
    };
  }

  createMonthlyAssociatePayment() {
    return {
      id: 0,
      paymentDate: null,
      associateId: null,
      payment1: null,
      payment2: null,
      confirmDate: null,
      confirmAssociateId: null,
      atchPaymentDocs: null,
    };
  }

  createCommissionRate() {
    return {
      id: 0,
      associateId: null,
      commissionDate: null,
      commissionType: null,
      feePercent: 0,
      marginThreshold: null,
      feeGuaranteedAmount: null,
      marginPercent: 0,
      marginGuaranteedAmount: null,
      // Default to active.
      isActive: 1,
      // We will only ever have a single pay configuration number
      // now that we dont' allow multiple per statement.
      payConfigurationNumber: 1
    }
  }

  createAssociateDocument() {
    return {
      id: 0,
      typeId: null,
      payPeriodDocument: null,
      associateId: null,
      description: null,
      filename: null,
      path: null,
      mimeType: null,
      uploadingAssociateId: null,
      uploadDate: null
    };
  }

  createEmptyAssociate() {
    return {
      id: 0,
      firstName: null,
      lastName: null,
      company: null,
      emailAddress: null,
      businessPhone: null,
      cellPhone: null,
      address: null,
      city: null,
      stateProvince: null,
      postalCode: null,
      countryRegion: null,
      notes: null,
      attachments: null,
      jobTitle: null,
      isActive: true,
      loginAttempts: 0,
      systemId: null,
      parentAssociateId: null,
      hasCarrierPrivilege: false,
      rateConBusinessPhone: null,
      rateConBusinessPhoneExt: null,
      rateConFaxPhone: null,
      rateConEmailAddress: null,
      roles: [],
      displayPayouts: false,
      collectionRole: false,
      openLinksInNewTab: true,
      hasDatCredentials: false,
      hasTruckstopCredentials: false,
      canViewMarginContribution: true,
      canEditBlockedDates: false,
      canEditBlockedDatesArBilling: false,
      canViewCollectionsAssignment: false,
      canViewBackOfficeHelpDocs: false,
      canRetryInvoiceDistribution: false,
      canViewAccountingTab: false,
      canEditCustomerBilling: false,
      assignedCollectorId: null,
    };
  }

  createLaneLookup() {
    return {
      bolDate: null,
    }
  }

  createBolDate() {
    return {
      bolDate: null,
    }
  }

  coerceAssociate(associateData) {

    const overrides = {
      fullName: `${associateData.firstName} ${associateData.lastName}`,
      businessPhone: GatewayFacade.makePhone(associateData.businessPhone),
      cellPhone: GatewayFacade.makePhone(associateData.cellPhone),
      isActive: Core.Utils.isBoolean(associateData.isActive) ?
        associateData.isActive :
        (Core.Utils.isNumber(associateData.isActive) && associateData.isActive === 1)
    };
    return Core.Utils
      .merge({}, this.createEmptyAssociate(), associateData, overrides);
  }

  coerceCommissionRate(commissionRateData) {
    if (commissionRateData) {
      const overrides = {
        commissionDate: GatewayFacade.makeDateOnly(commissionRateData.commissionDate),
        feePercent: commissionRateData.feePercent / 100,
        marginPercent: commissionRateData.marginPercent / 100
      };
      return Core.Utils
        .merge({}, this.createCommissionRate(), commissionRateData, overrides);
    }
    return commissionRateData;
  }

  coerceAssociateDocument(documentData) {
    if (!documentData)
      return null;

    const overrides = {
      uploadDate: GatewayFacade.makeDateOnly(documentData.uploadDate)
    };
    return Core.Utils
      .merge({}, this.createAssociateDocument(), documentData, overrides);
  }

  coerceCommissionAdjustment(commissionAdjustmentDate) {
    if (commissionAdjustmentDate) {
      const overrides = {
        startDate: GatewayFacade.makeDateOnly(commissionAdjustmentDate.startDate),
        endDate: GatewayFacade.makeDateOnly(commissionAdjustmentDate.endDate)
      };
      return Core.Utils
        .merge({}, this.createCommissionAdjustment(), commissionAdjustmentDate, overrides);
    }
    return commissionAdjustmentDate;
  }

  coerceMonthlyAssociatePayment(payment) {
    const overrides = {
      confirmDate: GatewayFacade.makeDateOnly(payment.confirmDate),
      paymentDate: GatewayFacade.makeDateOnly(payment.paymentDate),
      payment1: payment.payment1 ? Number(payment.payment1) : null
    };
    return Core.Utils
      .merge({}, this.createMonthlyAssociatePayment(), payment, overrides);
  }

  async getCollectorsAssignmentListing(odata = {}) {
    const qs = buildOdataQuery(odata);
    const result = await this.communicator.get(`/settings/collectors-assignment-listing${qs}`);
    return result;
  }

  //#endregion

  //#region : Carrier :

  async searchCarriers(options) {
    const {
      filter = {},
      offset = 0,
      limit = 25,
      sort = [['name', 'asc']]
    } = options;

    const queryParams = {
      filter: JSON.stringify(filter),
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);

    const { carriers, count } = await this.communicator
      .get(`/carrier?${qs}`);
    return {
      carriers: carriers.map(c => this.coerceCarrier(c)),
      count
    };
  }

  async getAllCarriers(options) {
    const {
      filter = {},
      offset = 0,
      limit = 25,
      sort = [['carrierName', 'asc']]
    } = options;

    const queryParams = {
      filter: JSON.stringify(filter),
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);

    const { carriers, count } = await this.communicator
      .get(`/carrier/listing?${qs}`);
    return {
      carriers: carriers.map(c => this.coerceCarrierListing(c)),
      count
    };
  }

  async getPendingCarriers(options) {
    const {
      filter = {},
      offset = 0,
      limit = 25,
      sort = [['mcNumber', 'desc']]
    } = options;

    const queryParams = {
      filter: JSON.stringify(filter),
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);

    const pendingCarriers = await this.communicator
      .get(`/carrier/pending?${qs}`);
    return pendingCarriers;
  }

  async getCarrierCompliance(mcNumber) {
    const complianceData = await this.communicator.get(`/carrier/${mcNumber}/compliance`);
    return this.coerceCarrierCompliance(complianceData);
  }

  async getCarrierHistory(options) {
    const {
      filter = {},
      offset = 0,
      limit = 25,
      sort = [['shipmentBolNumber', 'asc']]
    } = options;

    const queryParams = {
      filter: JSON.stringify(filter),
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);

    const { shipments, count } = await this.communicator
      .get(`/carrier/shipment?${qs}`);

    return {
      shipments: shipments.map(s => this.coerceCarrierHistory(s)),
      count
    };
  }

  async getAllCarrierNotes(mcNumber) {
    const notes = await this.communicator.get(`/carrier/${mcNumber}/note`);
    return notes.map(n => this.coerceCarrierNote(n));
  }

  async deletePendingCarrier(mcNumber) {
    const result = await this.communicator
      .delete(`/carrier/${mcNumber}/pending`);
    return result;
  }

  async savePendingCarrier(pendingCarrierModel) {
    const emptyPendingCarrierModel = this.createPendingCarrier();

    const pendingCarrierData = GatewayFacade.extract(pendingCarrierModel, emptyPendingCarrierModel);

    return await this.communicator.post(`/carrier/${pendingCarrierData.mcNumber}/pending`, pendingCarrierData);
  }

  async saveCarrier(carrierModel) {

    const emptyCarrierModel = this.createCarrier();

    const carrierData = GatewayFacade.extract(carrierModel, emptyCarrierModel);

    // Ensure tgfOverride is converted back to char(1) due to idiotic database developers.
    carrierData.tgfOverride = carrierData.tgfOverride ? '1' : '0';
    carrierData.blackListed = carrierData.blackListed ? '1' : '0';

    const carrier = await this.communicator.post(`/carrier`, carrierData);
    return this.coerceCarrier(carrier);
  }

  async getCarrier(mcNumber) {
    const carrierData = await this.communicator.get(`/carrier/${mcNumber}`);
    return this.coerceCarrier(carrierData);
  }

  async getCarrierContact(bolNumber) {
    const carrierContact = await this.communicator.get(`/shipment/${bolNumber}/bol-carrier-contact`);

    return carrierContact ?
      this.coerceCarrierContact(carrierContact) :
      null;
  };

  async saveCarrierContact(carrierContactModel) {
    const emptyCarrierContactModel = this.createCarrierContact();

    const carrierContactData = GatewayFacade.extract(carrierContactModel, emptyCarrierContactModel);

    return await this.communicator
      .post(`/shipment/${carrierContactData.bolNumber}/bol-carrier-contact`, carrierContactData);
  };

  async saveCarrierNote(noteModel) {

    const emptyNoteModel = this.createCarrierNote();

    const noteData = GatewayFacade.extract(noteModel, emptyNoteModel);

    return await this.communicator
      .post(`/carrier/${noteData.mcNumber}/note`, noteData);
  }

  createCarrier() {
    return {
      address1: null,
      address2: null,
      alternatePhone: null,
      associateId: null,
      attachment: null,
      auditDate: null,
      auditNote: null,
      blackListed: false,
      businessPhone: null,
      businessStartDate: null,
      cargoLiabilityAmount: null,
      carrierRating: null,
      city: null,
      countryRegion: null,
      createDate: null,
      dotNumber: null,
      emailAddress: null,
      factorRate: null,
      faxPhone: null,
      freightCategoryId: null,
      generalLiabilityAmount: null,
      generalLiabilityExpirationDate: null,
      genericAttachment: null,
      hireDate: null,
      id: null,
      insuranceAttachment: null,
      insuranceExpirationDate: null,
      isBonded: false,
      isConditional: false,
      isGoodToGo: false,
      isGrandfathered: false,
      isHazMat: false,
      isInactive: false,
      isMonitoring: false,
      macroPointNumber: null,
      mcNumber: null,
      mobilePhone: null,
      name: null,
      note: null,
      packetReceivedDate: null,
      packetReceivedTgDate: null,
      packetSentDate: null,
      postalCode: null,
      repFirstName: null,
      repLastName: null,
      scac: null,
      stateProvince: null,
      tgfOverride: null,
      tgfSoftInactivation: null,
      updateDate: null,
      vendorTaxId: null,
      webPageUrl: null,
      ysnInactiveAuditAssociateId: null,
      ysnInactiveAuditDate: null,
      ysnInactiveNew: false,
      ysnInactiveNewAssociateId: null,
      ysnInactiveNewDate: null
    }
  };

  createCarrierListing() {
    return {
      carrierId: null,
      carrierName: null,
      carrierCity: null,
      carrierState: null,
      carrierPostalCode: null,
      carrierMcNumber: null,
      carrierDotNumber: null,
      freightCategoryId: null,
      freightCategoryName: null,
      carrierIsGoodToGo: null,
      rmisCarrierIsCertified: null,
      carrierIsMonitored: null,
      carrierIsBlackListed: null,
      carrierHireDate: null,
      carrierPacketReceivedDate: null,
      rmisInsuranceCoverageCargoExpirationDate: null,
      rmisInsuranceCoverageLimitCargoAmount: null,
      rmisInsuranceCoverageAutoExpirationDate: null,
      rmisInsuranceCoverageLimitAutoAmount: null,
    }
  };

  createPendingCarrier() {
    return {
      id: 0,
      associateId: null,
      label: null,
      isInDatabase: false,
      isRmisLinked: false,
      isRmisCertified: false,
      isInsuranceConfirmed: false,
      isTaxIdConfirmed: false,
      isGoodToGo: false,
      createDate: null,
      updateDate: null,
      mcNumber: null,
    }
  };

  createCarrierHistory() {
    return {
      associateId: null,
      associateSystemId: null,
      shipmentBolNumber: null,
      carrierCost: null,
      shipmentBolDate: null,
      shipperFromState: null,
      consigneeToState: null,
      shipperFromCity: null,
      consigneeToCity: null,
      shipmentEquipmentType: null,
      carrierMcNumber: null,
      bolInternalNotes: null,
      carrierRep: null,
      carrierRepPhone: null,
      carrierRepEmail: null
    }
  };

  createCompliance() {
    return {
      carrierMcNumber: null,
      carrierName: null,
      carrierAddress1: null,
      carrierAddress2: null,
      carrierCity: null,
      carrierState: null,
      carrierZipCode: null,
      carrierCountry: null,
      carrierDotNumber: null,
      rmisCarrierTaxId: null,
      lastShipmentDate: null,
      tgfShipmentCount: null,
      carrierGoodToGo: null,
      carrierBlackListed: null,
      rmisCarrierCompliant: null,
      carrierRmisMonitored: null,
      rmisCarrierUpdate: null,
      rmisDotTestingCurrentOperatingStatus: null,
      rmisDotTestingCurrentSaftyRating: null,
      rmisDotCommonAuthority: null,
      rmisDotContractAuthority: null,
      rmisDotBrokerAuthority: null,
      rmisInsuranceCoverageLimitAutoCoverageLimit: null,
      RmisInsuranceCoverageAutoExpiration: null,
      rmisInsuranceCoverageLimitCargoCoverageLimit: null,
      RmisInsuranceCoverageCargoExpiration: null
    }
  };

  coerceCarrier(carrierData) {
    const overrides = {
      auditDate: GatewayFacade.makeDate(carrierData.auditDate),
      businessStartDate: GatewayFacade.makeDate(carrierData.businessStartDate),
      createDate: GatewayFacade.makeDate(carrierData.createDate),
      generalLiabilityExpirationDate: GatewayFacade.makeDateOnly(carrierData.generalLiabilityExpirationDate),
      hireDate: GatewayFacade.makeDate(carrierData.hireDate),
      insuranceExpirationDate: GatewayFacade.makeDateOnly(carrierData.insuranceExpirationDate),
      packetReceivedDate: GatewayFacade.makeDate(carrierData.packetReceivedDate),
      packetReceivedTgDate: GatewayFacade.makeDate(carrierData.packetReceivedTgDate),
      packetSentDate: GatewayFacade.makeDate(carrierData.packetSentDate),
      updateDate: GatewayFacade.makeDate(carrierData.updateDate),
      alternatePhone: GatewayFacade.makePhone(carrierData.alternatePhone),
      businessPhone: GatewayFacade.makePhone(carrierData.businessPhone),
      faxPhone: GatewayFacade.makePhone(carrierData.faxPhone),
      mobilePhone: GatewayFacade.makePhone(carrierData.mobilePhone),
      // Convert to boolean thanks to idiotic previous database developers.
      blackListed: carrierData.blackListed === '1',
      tgfOverride: carrierData.tgfOverride === '1',
      name: carrierData.name && carrierData.name.trim(),
      repFirstName: carrierData.repFirstName && carrierData.repFirstName.trim(),
      repLastName: carrierData.repLastName && carrierData.repLastName.trim(),
      emailAddress: carrierData.emailAddress && carrierData.emailAddress.trim(),
      address1: carrierData.address1 && carrierData.address1.trim(),
      city: carrierData.city && carrierData.city.trim(),
      postalCode: carrierData.postalCode && carrierData.postalCode.trim(),
      rmisCarrier: carrierData.rmisCarrier && this.coerceRmisCarrier(carrierData.rmisCarrier),
      rmisCarrierContact: carrierData.rmisCarrierContact && this.coerceRmisCarrierContact(carrierData.rmisCarrierContact),
      rmisDot: carrierData.rmisDot && this.coerceRmisDot(carrierData.rmisDot),
      rmisDotTesting: carrierData.rmisDotTesting && this.coerceRmisDotTesting(carrierData.rmisDotTesting),
      rmisInsuranceCoverage: carrierData.rmisInsuranceCoverage && this.coerceRmisInsuranceCoverage(carrierData.rmisInsuranceCoverage)
    };
    return Core.Utils
      .merge({}, this.createCarrier(), carrierData, overrides);
  }

  coerceCarrierListing(carrierData) {
    const overrides = {
      carrierHireDate: GatewayFacade.makeDate(carrierData.carrierHireDate),
      carrierPacketReceivedDate: GatewayFacade.makeDate(carrierData.carrierPacketReceivedDate),
      rmisInsuranceCoverageCargoExpirationDate: GatewayFacade.makeDateOnly(carrierData.rmisInsuranceCoverageCargoExpirationDate),
      rmisInsuranceCoverageAutoExpirationDate: GatewayFacade.makeDateOnly(carrierData.rmisInsuranceCoverageAutoExpirationDate),
      carrierIsBlackListed: carrierData.carrierIsBlackListed === '1'
    };
    return Core.Utils
      .merge({}, this.createCarrierListing(), carrierData, overrides);
  }

  coerceCarrierCompliance(complianceData) {
    const overrides = {
      lastShipmentDate: GatewayFacade.makeDate(complianceData.lastShipmentDate),
      rmisCarrierUpdate: GatewayFacade.makeDate(complianceData.rmisCarrierUpdate),
      RmisInsuranceCoverageAutoExpiration: GatewayFacade.makeDateOnly(complianceData.RmisInsuranceCoverageAutoExpiration),
      RmisInsuranceCoverageCargoExpiration: GatewayFacade.makeDateOnly(complianceData.RmisInsuranceCoverageCargoExpiration)
    };
    return Core.Utils
      .merge({}, this.createCompliance(), complianceData, overrides);
  }

  coerceCarrierNote(carrierNoteData) {
    const overrides = {
      createDate: GatewayFacade.makeDate(carrierNoteData.createDate)
    };
    return Core.Utils
      .merge({}, this.createCarrierNote(), carrierNoteData, overrides);
  }

  coerceCarrierContact(carrierContactData) {
    const overrides = {
      repPhone: GatewayFacade.makePhone(carrierContactData.repPhone)
    };
    return Core.Utils
      .merge({}, this.createCarrierContact(), carrierContactData, overrides);
  }

  createCarrierNote() {
    return {
      id: 0,
      associateId: null,
      mcNumber: null,
      content: null,
      createDate: null
    }
  }

  coerceCarrierHistory(carrierHistoryData) {
    const overrides = {
      shipmentBolDate: GatewayFacade.makeDateOnly(carrierHistoryData.shipmentBolDate)
    };
    return Core.Utils
      .merge({}, this.createCarrierHistory(), carrierHistoryData, overrides);
  };

  createCarrierContact() {
    return {
      id: 0,
      bolNumber: null,
      repName: null,
      repPhone: null,
      repEmail: null,
      carrierCallContactPreferred: null,
      carrierTextContactPreferred: null,
      carrierEmailContactPreferred: null,
      experienceNote: null
    };
  }

  //#endregion

  //#region : Company :

  async getCompany(companyId) {
    Core.Validation.Mandate.argIsNumber(companyId, 'companyId');
    const companyData = await this.communicator.get(`/company/${companyId}`);
    return companyData ?
      this.coerceCompany(companyData) :
      null;
  }

  async searchCompanies(options) {
    const {
      filter = {},
      offset = 0,
      limit = 25,
      sort = [['companyName', 'asc']]
    } = options;

    const queryParams = {
      filter: JSON.stringify(filter),
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);

    const result = await this.communicator
      .get(`/company?${qs}`);
    return result;
  }

  /**
   * searchAffiliates is used to search companies that can
   * be an affiliate for a shipper/consignee.
   * @param {string} searchTerm - Search text for name matching.
   * @return {Promise<object[]>}
   */
  async searchAffiliates(searchTerm) {

    if (!Core.Utils.isString(searchTerm) || searchTerm.trim().length === 0) {
      return [];
    }

    const categoryTypes = await this.getCompanyCategoryTypes();

    // Find the one category type (customer) that represents
    // companies that can be affiliates.
    const affiliateRequiredCatType = categoryTypes
      .find(ct => ct.canBeAffiliate);

    // Build the generic search filter for companies.
    const filter = {
      companyName: {
        $like: `${searchTerm}%`
      },
      categoryTypeId: affiliateRequiredCatType.id
    };

    const sort = [['companyName', 'asc']];

    const { companies } = await this
      .searchCompanies({
        filter,
        sort
      });

    return companies;

  }

  async saveCompany(companyModel) {

    // Ensure only valid fields make it into the
    // data to send to the gateway.
    const emptyCompanyModel = this.createCompany();

    const companyData = GatewayFacade.extract(companyModel, emptyCompanyModel);

    const updatedCompanyData = await this.communicator.post(`/company`, companyData);

    return this.coerceCompany(updatedCompanyData);
  }

  async disableCompany(companyId, reasonText) {

    const updatedCompanyData = await this.communicator.post(`/company/${companyId}/manual-disablement`, {
      reasonText
    });

    return this.coerceCompany(updatedCompanyData);
  }

  async getCompanyDisablementHistory(companyId, offset = 0, limit = 25, sort = [['createDate', 'desc']]) {

    const queryParams = {
      filter: JSON.stringify({ customerId: companyId }),
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);

    const { disablementHistories, count } = await this.communicator.get(`/company/${companyId}/disablement-history?${qs}`);

    return {
      disablementHistories: disablementHistories.map(d => this.coerceCompanyDisablementHistory(d)),
      count
    };
  }

  createCompany() {
    return {
      id: 0,
      code: null,
      name: null,
      associateId: null,
      categoryTypeId: null,
      address1: null,
      address2: null,
      city: null,
      stateProvince: null,
      postalCode: null,
      countryRegion: null,
      lastName: null,
      firstName: null,
      emailAddress: null,
      businessPhone: null,
      alternatePhone: null,
      cellPhone: null,
      faxPhone: null,
      webPageUrl: null,
      note: null,
      hotNote: null,
      attachment: null,
      salesCalls: null,
      billingContactLastName: null,
      billingContactFirstName: null,
      billingContactEmailAddress: null,
      billingContactBusinessPhone: null,
      billingContactCellPhone: null,
      billingContactFaxPhone: null,
      billingContactAddress1: null,
      billingContactCity: null,
      billingContactStateProvince: null,
      billingContactPostalCode: null,
      wasSentLeadLetter: false,
      contactDate: null,
      wasConverted: false,
      paymentTerms: null,
      sicCode: null,
      hasInvoicePaper: false,
      shippingHours: null,
      receivingHours: null,
      companyLabel: null,
      isInactive: false,
      affiliateId: null,
      convertedDate: null,
      hasDeliverOrder: false,
      isDisabled: false,
      disabledDate: null,
      disablingAssociateId: null,
      creditLimit: null,
      creditAppReceivedDate: null,
      hasCreditAppOnFile: false,
      creditAppAttachment: null,
      isHardCopyPodRequired: false,
      hasVerbalPod: false,
      allowMarketingEngagement: true
    };
  }

  createCompanyDisablementHistory() {
    return {
      id: null,
      customerId: null,
      reasonId: null,
      reasonText: null,
      associateId: null,
      createDate: null,
      classificationCode: null
    };
  }

  coerceCompany(companyData) {
    const overrides = {
      contactDate: GatewayFacade.makeDate(companyData.contactDate),
      convertedDate: GatewayFacade.makeDate(companyData.convertedDate),
      disabledDate: GatewayFacade.makeDate(companyData.disabledDate),
      creditAppReceivedDate: GatewayFacade.makeDateOnly(companyData.creditAppReceivedDate),
      // Credit limit requires conversion as the Gateway seems
      // to be sending it as a string.
      creditLimit: Core.Utils.isString(companyData.creditLimit) ?
        parseFloat(companyData.creditLimit) : companyData.creditLimit,
      associate: companyData.associate ?
        this.coerceAssociate(companyData.associate) :
        null,
      disablingAssociate: companyData.disablingAssociate ?
        this.coerceAssociate(companyData.disablingAssociate) :
        null,
      businessPhone: GatewayFacade.makePhone(companyData.businessPhone),
      alternatePhone: GatewayFacade.makePhone(companyData.alternatePhone),
      cellPhone: GatewayFacade.makePhone(companyData.cellPhone),
      faxPhone: GatewayFacade.makePhone(companyData.faxPhone),
      billingContactBusinessPhone: GatewayFacade.makePhone(companyData.billingContactBusinessPhone),
      billingContactCellPhone: GatewayFacade.makePhone(companyData.billingContactCellPhone),
      billingContactFaxPhone: GatewayFacade.makePhone(companyData.billingContactFaxPhone)
    };
    return Core.Utils
      .merge({}, this.createCompany(), companyData, overrides);
  }

  coerceCompanyDisablementHistory(disablementHistoryData) {
    const overrides = {
      createDate: GatewayFacade.makeDate(disablementHistoryData.createDate),
      associate: this.coerceAssociate(disablementHistoryData.associate)
    };
    return Core.Utils
      .merge({}, this.createCompanyDisablementHistory(), disablementHistoryData, overrides);
  }

  getPageOfCollectorContacts = async (odata = {}, companyId) => {
    const qs = buildOdataQuery(odata);
    const results = await this.communicator.get(`/company/${companyId}/collector-contacts${qs}`);
    return results;
  };

  saveCollectorContact = async (contactData, companyId) => {
    const result = await this.communicator.post(`/company/${companyId}/collector-contact`, contactData)
  }

  deleteCollectorContact = async (companyId, contactId) => {
    await this.communicator.delete(`/company/${companyId}/collector-contact/${contactId}`)
  }

  getPageOfCollectorNotes = async (odata = {}, companyId) => {
    const qs = buildOdataQuery(odata);
    const results = await this.communicator.get(`/company/${companyId}/collector-notes${qs}`);
    return results;
  };

  saveCollectorNote = async (noteData, companyId) => {
    const result = await this.communicator.post(`/company/${companyId}/collector-note`, noteData);
    return result;
  }

  getCollectorHotNote = async (companyId) => {
    const result = await this.communicator.get(`/company/${companyId}/collector-hot-note`);
    return result;
  }

  saveCollectorHotNote = async (companyId, noteData) => {
    const result = await this.communicator.post(`/company/${companyId}/collector-hot-note`, noteData);
    return result;
  }

  getAssignedCollectorByCustomerId = async (customerId) => {
    const result = await this.communicator.get(`/company/${customerId}/assigned-collector`);
    return result;
  }

  getCompanyAutofillFields = async (customerId) => {
    const result = await this.communicator.get(`/company/${customerId}/autofill-fields`);
    return result;
  }

  saveCompanyAutofillFields = async (customerId, autofillData) => {
    const result = await this.communicator.post(`/company/${customerId}/autofill-fields`, autofillData);
    return result;
  }

  saveDuplicateShipperConsignee = async (companyIds) => {
    const result = await this.communicator.post(`/company/${companyIds.shipperConsigneeId}/duplicate-shipper-consignee`, companyIds);
    return result;
  }

  //#endregion

  //#region : Company Category Type :

  async getCompanyCategoryTypes() {

    // IMPORTANT: Do not attempt to reload category types if
    // already loaded.  This will cause components like
    // AppCompanyTypeComboBox to infinitely load due to reference
    // changes.  Be sure to assign a singleton variable
    // in order to keep the same memory reference throughout
    // the life of the app.
    if (!categoryTypes) {
      categoryTypes = await this.communicator.get(`/company-category`);

      categoryTypes.forEach(t => {
        t.isDefault = t.name === DefaultCategoryTypeName;
        t.requiresAdmin = AdminCategoryTypes
          .some(name => t.name === name);
        t.requiresAffiliate = AffiliateCategoryTypes
          .some(name => t.name === name);
        t.requiresCredit = t.canBeAffiliate = CreditCategoryTypes
          .some(name => t.name === name);
        t.allowsShipping = ShippableCategoryTypes
          .some(name => t.name === name);
      });

    }

    return categoryTypes;
  }

  //#endregion

  //#region : Company Contacts :

  async getCompanyContacts(companyId) {
    Core.Validation.Mandate.argIsNumber(companyId, 'companyId');
    const contactsData = await this.communicator.get(`/company/${companyId}/contact`);
    return contactsData
      .map(c => this.coerceCompanyContact(c));
  }

  async saveCompanyContact(contact) {

    const contactData = GatewayFacade.extract(contact, this.createCompanyContact());

    Core.Validation.Mandate.argIsNumber(contactData.companyId, 'contact.companyId');

    const updatedContactData = await this.communicator
      .post(`/company/${contactData.companyId}/contact`, contactData);

    return this.coerceCompanyContact(updatedContactData);
  }

  async deleteCompanyContact(companyId, contactId) {
    const result = await this.communicator
      .delete(`/company/${companyId}/contact/${contactId}`);
    return result;
  }

  async grantCustomerPortalAccess(companyId, contactId) {
    const result = await this.communicator.post(`/company/${companyId}/grant-customer-portal-access/${contactId}`, {agencyId:null});
    return result;
  }

    async revokeCustomerPortalAccess(companyId, contactId) {
      const result = await this.communicator.post(`/company/${companyId}/revoke-customer-portal-access/${contactId}`, {agencyId:null});
      return result;
    }

  coerceCompanyContact(contactData) {

    const overrides = {
      // Be sure phone numbers that are empty strings default to null.
      phone: GatewayFacade.makePhone(contactData.phone),
      cellPhone: GatewayFacade.makePhone(contactData.cellPhone),
      faxPhone: GatewayFacade.makePhone(contactData.faxPhone),
      isActive: Core.Utils.isBoolean(contactData.isActive) ?
        contactData.isActive : false,
      isArContact: Core.Utils.isBoolean(contactData.isArContact) ?
        contactData.isArContact : false,
      isPrimary: Core.Utils.isBoolean(contactData.isPrimary) ?
        contactData.isPrimary : false,
    };
    return Core.Utils.merge({}, GatewayFacade.extract(contactData, this.createCompanyContact()), overrides);
  }

  createCompanyContact() {
    return {
      id: 0,
      companyId: null,
      firstName: null,
      lastName: null,
      emailAddress: null,
      phone: null,
      cellPhone: null,
      faxPhone: null,
      isPrimary: false,
      isActive: true,
      isArContact: false,
      companyContactRoles: []
    };
  }

  //#endregion

  //#region : Company Credit :

  async getCompanyCreditStatus(companyId) {
    Core.Validation.Mandate.argIsNumber(companyId, 'companyId');
    const creditStatusData = await this.communicator.get(`/company/${companyId}/credit-status`);
    return this.coerceCompanyCreditStatus(creditStatusData);
  }

  async getLtlRateQuoteFormData(customerId) {
    const formData = await this.communicator.get(`/shipment/ltl-rates/form-data/${customerId ?? 0}`);
    return formData;
  }

  async getLtlRates(customerId, shipmentDetails) {
      const carrierRates = await this.communicator.post(`/shipment/ltl-rates/${customerId ?? 0}`, shipmentDetails);
      return carrierRates;
  }

    async getLtlTracking(bolNumber) {
        const ltlTracking = await this.communicator.get(`/shipment/ltl-tracking/${bolNumber}`);
        return ltlTracking;
    }

    async getPageOfLtlShipmentItems(odata, bolNumber) {
        let  qs = buildOdataQuery(odata);
        return await this.communicator.get(`/shipment/ltl-shipment-items/${bolNumber}${qs}`);
    }

    async createAndBookLtlShipment(customerId, shipmentDetails) {
        const result = await this.communicator.post(`/shipment/ltl-create-and-book/${customerId ?? 0}`, shipmentDetails);
        return result;
    }

    async sendProOrPuNumToCrmShipment(shipmentData) {
      await this.communicator.post(`/shipment/send-pro-or-pu-to-shipment/${shipmentData.bolNumber}`, shipmentData);
    }

  coerceCompanyCreditStatus(creditStatusData) {
    const overrides = {};

    // Sometimes the companyCreditLimit field can be a string.
    if (Core.Utils.isString(creditStatusData?.companyCreditLimit) && creditStatusData?.companyCreditLimit.trim().length > 0) {
      overrides.companyCreditLimit = parseFloat(creditStatusData.companyCreditLimit);
    }

    return Core.Utils
      .merge({}, this.createEmptyCompanyCreditStatus(), creditStatusData, overrides);
  }

  createEmptyCompanyCreditStatus() {
    return {
      associateId: null,
      companyId: null,
      companyCreditRemaining: 0,
      companyCreditLimit: 0,
      companyCreditUsed: 0
    };
  }

  async getCompanyAutoCreditCheck(companyId) {
    return await this.communicator.get(`/company/${companyId}/auto-credit-increase-check`);
  }

  async performAutoCreditIncrease(companyId) {
    return await this.communicator.post(`/company/${companyId}/auto-credit-increase`, {});
  }

  async getCompanyCreditLimitChanges(companyId) {
    const creditLimitChanges = await this.communicator.get(`/company/${companyId}/credit-limit-changes`);
    return creditLimitChanges
      .map((creditLimitChange) => this.coerceCompanyCreditLimitChangeRecord(creditLimitChange));
  }

  coerceCompanyCreditLimitChangeRecord(creditLimitChange) {
    const overrides = {
      outcomeDate: GatewayFacade.makeDate(creditLimitChange.outcomeDate)
    };

    return Core.Utils
      .merge({}, creditLimitChange, overrides);
  }

  //#endregion

  //#region : Company Invoice Settings :

  async getCompanyInvoiceDeliveryMethodTypes() {
    return await this.communicator
      .get(`/company-invoice-delivery-method-type`);
  }

  async getCompanyInvoiceSettings(companyId) {
    const companyInvoiceSettings = await this.communicator.get(`/company/${companyId}/invoice-settings`);
    return this.coerceCompanyInvoiceSettings(companyInvoiceSettings);
  }

  async saveCompanyInvoiceSettings(companyInvoiceSettings) {
    const updatedInvoiceSettings = await this.communicator.post(`/company/${companyInvoiceSettings.companyId}/invoice-settings`, companyInvoiceSettings);
    return this.coerceCompanyInvoiceSettings(updatedInvoiceSettings);
  }

  coerceCompanyInvoiceSettings(settingsData) {
    return Core.Utils.merge({}, this.createEmptyCompanyInvoiceSettings(), settingsData);
  }

  createEmptyCompanyInvoiceSettings() {
    return {
      id: null,
      companyId: null,
      billingAddress1: null,
      billingAddress2: null,
      billingCity: null,
      billingStateProvince: null,
      billingPostalCode: null,
      deliveryMethodTypeId: null,
      batchInvoices: false,
      deliveryMethodNote: null,
      generalNote: null,
      allowInvoiceBackdating: true
    };
  }

  async getInvoiceCommunicationConfig(companyId) {
    return await this.communicator.get(`/company/${companyId}/invoice-communication-config/form`);
  }

  async saveInvoiceCommunicationConfig(companyId, data) {
    return await this.communicator.post(`/company/${companyId}/invoice-communication-config`, data)
  }

  async saveCompanyBillingContacts(companyId, data) {
    return await this.communicator.post(`/company/${companyId}/billing-contacts`, data);
  }

  //#endregion

  //#region : Company Financial Health Summary :

  async getCompanyFinancialHealthSummary(companyId) {
    const healthSummary = await this.communicator
      .get(`/company/${companyId}/financial-health-summary`);

    if (!healthSummary)
      return null;

    return this.coerceCompanyFinancialHealthSummary(healthSummary);
  }

  coerceCompanyFinancialHealthSummary(healthSummary) {
    const overrides = {
      firstShipmentDate: GatewayFacade.makeDateOnly(healthSummary.firstShipmentDate),
      lastShipmentDate: GatewayFacade.makeDateOnly(healthSummary.lastShipmentDate),
      companyCreditLimit: healthSummary.companyCreditLimit ?
        parseFloat(healthSummary.companyCreditLimit) : 0,
      // Ensure booleans show up properly with the left joins in the backend.
      creditRiskIndicator: typeof(healthSummary.creditRiskIndicator) === 'boolean' ?
        healthSummary.creditRiskIndicator : false,
      companyAllowInvoiceBackdating: typeof(healthSummary.companyAllowInvoiceBackdating) === 'boolean' ?
        healthSummary.companyAllowInvoiceBackdating : true
    };

    return Core.Utils.merge({}, this.createEmptyCompanyFinancialHealthSummary(), healthSummary, overrides);
  }

  createEmptyCompanyFinancialHealthSummary() {
    return {
      companyId: null,
      companyName: null,
      companyAllowMarketingEngagement: true,
      firstShipmentDate: null,
      lastShipmentDate: null,
      averageTotalMonthlyMargin: 0,
      averageMarginPercentageLifetime: 0,
      averageDaysToPayRolling90: 0,
      autoCreditIncreaseThreshold: null,
      autoCreditIncreaseIncrement: null,
      dunBradstreetScore: null,
      paydexScore: null,
      paydexAverageDaysToPay: null,
      creditRiskIndicator: false,
      companyCreditLimit: 0,
      companyCreditUsed: 0,
      companyCreditRemaining: 0,
      companyAllowInvoiceBackdating: true
    };
  }

  //#endregion

  //#region : Company Financial :

  async getCompanyFinancial(companyId) {
    const companyFinancial = await this.communicator.get(`/company/${companyId}/financial`);
    return companyFinancial ?
      this.coerceCompanyFinancial(companyFinancial) : null;
  }

  async saveCompanyFinancial(companyFinancial) {
    return this.coerceCompanyFinancial(
      await this.communicator.post(`/company/${companyFinancial.companyId}/financial`, companyFinancial)
    );
  }

  coerceCompanyFinancial(companyFinancial) {
    const overrides = {
      collectionAssociate: companyFinancial?.collectionAssociate ?
        this.coerceAssociate(companyFinancial.collectionAssociate) : null
    };
    return Core.Utils.merge({}, this.createEmptyCompanyFinancial(), companyFinancial, overrides);
  }

  createEmptyCompanyFinancial() {
    return {
      companyId: null,
      autoCreditIncreaseThreshold: null,
      autoCreditIncreaseIncrement: null,
      dunBradstreetScore: null,
      paydexScore: null,
      paydexAverageDaysToPay: null,
      creditRiskIndicator: false,
      collectionAssociateId: null
    };
  }

  //#endregion

  //#region : Company Notes :

  async getAllCompanyNotes(companyId) {
    Core.Validation.Mandate.argIsNumber(companyId, 'companyId');
    const notes = await this.communicator.get(`/company/${companyId}/note`);
    return notes
      .map(n => this.coerceCompanyNote(n));
  }

  async getCompanyNotes(companyId) {
    const notes = await this.getAllCompanyNotes(companyId);
    return notes
      .filter(n => n.type === CompanyNoteTypes.Company);
  }

  async getCompanyAgingNotes(companyId) {
    const notes = await this.getAllCompanyNotes(companyId);
    return notes
      .filter(n => n.type === CompanyNoteTypes.Aging);
  }

  async saveCompanyNote(noteModel) {

    const noteData = this.extractCompanyNote(noteModel);

    Core.Validation.Mandate.argIsNumber(noteData.associateId, 'noteData.associateId');
    Core.Validation.Mandate.argIsNumber(noteData.companyId, 'noteData.companyId');
    Core.Validation.Mandate.argIsNonEmptyString(noteData.note, 'noteData.note');
    Core.Validation.Mandate.argIsDate(noteData.createDate, 'noteData.createDate');
    Core.Validation.Mandate.argIsString(noteData.type, 'noteData.type');
    Core.Validation.Mandate.assert({
      value: noteData.type,
      predicate: value => Core.Utils
        .toPairs(CompanyNoteTypes)
        .some(([key, noteType]) => noteType === value),
      template: "noteModel.type should be one of the allowed types [{{= it.allowedTypes}}], received [{{= it.value }}].",
      templateArgs: {
        allowedTypes: Core.Utils.toPairs(CompanyNoteTypes)
          .map(([key, noteType]) => noteType)
          .stitch()
      }
    });

    const updatedNoteData = await this.communicator
      .post(`/company/${noteData.companyId}/note`, noteData);

    return this.coerceCompanyNote(updatedNoteData);
  }

  coerceCompanyNote(noteData) {
    const overrides = {
      createDate: GatewayFacade.makeDate(noteData.createDate)
    };
    return Core.Utils
      .merge({}, this.createCompanyNote(), noteData, overrides);
  }

  extractCompanyNote(noteModel) {
    return {
      id: noteModel.id,
      associateId: noteModel.associateId,
      note: noteModel.note,
      createDate: noteModel.createDate,
      companyId: noteModel.companyId,
      type: noteModel.type,
    };
  }

  createCompanyAgingNote() {
    return this.createCompanyNote(CompanyNoteTypes.Aging);
  }

  createCompanyNote(type = CompanyNoteTypes.Company) {
    return {
      id: null,
      associateId: null,
      note: null,
      createDate: null,
      companyId: null,
      type,
    };
  }

  //#endregion

  //#region : Company Invoice Aging Summary :

  async getCompanyInvoiceAgingSummary(filter, offset, limit, sort) {

    const queryParams = {
      filter: JSON.stringify(filter),
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);

    const { invoiceAgingSummaryRows, count } = await this.communicator.get(`/company/invoice-aging-summary?${qs}`);
    return {
      invoiceAgingSummaryRows: invoiceAgingSummaryRows
        .map(i => this.coerceCompanyInvoiceAgingSummary(i)),
      count
    };

  }

  coerceCompanyInvoiceAgingSummary(invoiceAgingSummaryData) {
    return Core.Utils.merge({}, this.createEmptyCompanyInvoiceAgingSummary(), invoiceAgingSummaryData);
  }

  createEmptyCompanyInvoiceAgingSummary() {
    return {
      companyId: null,
      companyName: null,
      associateId: null,
      associateSystemId: null,
      associateFirstName: null,
      associateLastName: null,
      collectionAssociateId: null,
      collectionAssociateSystemId: null,
      collectionAssociateFirstName: null,
      collectionAssociateLastName: null,
      nonDelinquentCount: null,
      range1To15Count: null,
      range16To30Count: null,
      range31To45Count: null,
      range46To60Count: null,
      range61To90Count: null,
      range91PlusCount: null,
    };
  }

  //#endregion

  //#region : Dashboard :

    async getDashboardShipmentStatusSetCounts(options) {
      const {
        startDate,
        endDate,
        customerId = null
      } = options;


      const queryParams = {
        startDate,
        endDate,
        customerId
      };

      const qs = Qs.stringify(queryParams);

      const countStatusSet = await this.communicator
        .get(`/shipment/dashboard-count?${qs}`);

      return countStatusSet;
    }

    async getDashboardShipmentList(options) {

      const {
        startDate,
        endDate,
        customerId = null,
        statusSet = 'all',
        offset,
        limit,
        sort = [['bolNumber', 'desc']]
      } = options;

      const queryParams = {
        startDate,
        endDate,
        customerId,
        statusSet,
        offset,
        limit,
        sort
      };

      const qs = Qs.stringify(queryParams);

      const { shipments, count } = await this.communicator
        .get(`/shipment/dashboard-listing?${qs}`);
      return {
        shipments: shipments.map(s => this.coerceDashboardListing(s)),
        count
      };
    }

    async markInvoicesGenerated(bolNumbers) {

      const results = await this.communicator
        .post('/shipment/dashboard-invoice-generate', {bolNumbers});

      return results;
    };

    async markInvoicesSent(bolNumbers) {

      const results = await this.communicator
        .post('/shipment/dashboard-invoice-sent', {bolNumbers});

      return results;
    };

  createEmptyDashboardListing() {
    return {
      shipmentBolNumber: null,
      shipmentBolDate: null,
      statusId: null,
      statusName: null,
      adjustedCarrierCost: null,
      adjustedCustomerCost: null,
      invoiceAuditFinalizedDate: null,
      invoiceActualDeliveryDate: null,
      invoiceGenerated: null,
      invoiceSent: null,
      invoiceSentDate: null,
      customerPaid: null,
      freightCategoryType: null,
      carrierMcNumber: null,
      carrierName: null,
      customerId: null,
      customerName: null,
      thirdPartyId: null,
      thirdPartyName: null,
      associateSystemId: null
    };
  }

  coerceDashboardListing(dashboardListingData) {
    const overrides = {
      shipmentBolDate: GatewayFacade.makeDateOnly(dashboardListingData.shipmentBolDate),
      invoiceAuditFinalizedDate: GatewayFacade.makeDateOnly(dashboardListingData.invoiceAuditFinalizedDate),
      invoiceActualDeliveryDate: GatewayFacade.makeDateOnly(dashboardListingData.invoiceActualDeliveryDate),
      invoiceSentDate: GatewayFacade.makeDateOnly(dashboardListingData.invoiceSentDate),
      adjustedCarrierCost: dashboardListingData.adjustedCarrierCost ? dashboardListingData.adjustedCarrierCost : 0,
      adjustedCustomerCost: dashboardListingData.adjustedCustomerCost ? dashboardListingData.adjustedCustomerCost : 0,
    };
    return Core.Utils
      .merge({}, this.createEmptyDashboardListing(), dashboardListingData, overrides);
  }
  //#endregion

  //#region : Payment Terms :

  async getPaymentTerms() {
    return PaymentTerms;
  }

  //#endregion

  //#region : Open Load :

  async getUncoveredShipmentListing(options) {

    const {
      filter = {},
      offset = 0,
      limit = 25,
      sort
    } = options;

    const queryParams = {
      filter: JSON.stringify(filter),
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);

    const result = await this.communicator
      .get(`/shipment/uncovered?${qs}`);

    return {
      count: result.count,
      shipments: result.shipments.map(s => this.coerceUncoveredShipmentListing(s))
    };
  };

  async getAllShipments(options) {
    const {
      filter = {},
      offset = 0,
      limit = 25,
      sort
    } = options;

    const queryParams = {
      filter: JSON.stringify(filter),
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);

    const result = await this.communicator
      .get(`/shipment?${qs}`);

    return {
      count: result.count,
      shipments: result.shipments
    };
  };

  async getAllVoidShipments(options) {
    const {
      filter = {},
      offset = 0,
      limit = 25,
      sort
    } = options;

    const queryParams = {
      filter: JSON.stringify(filter),
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);

    const result = await this.communicator
        .get(`/void-shipment?${qs}`);

    return {
      count: result.count,
      shipments: result.shipments
    };
  };

  coerceUncoveredShipmentListing(shipment) {
    const overrides = {
      shipmentBolDate: GatewayFacade.makeDateOnly(shipment.shipmentBolDate),
      shipmentIsCovered: shipment.shipmentIsCovered || false
    };
    return Core.Utils.merge({}, this.createEmptyShipmentListing(), shipment, overrides);
  }

  createEmptyShipmentListing() {
    return {
      shipmentBolNumber: null,
      shipmentBolDate: null,
      customerCompanyId: null,
      customerCompanyName: null,
      customerCost: null,
      postedFor: null,
      shipperCompanyId: null,
      shipperCompanyName: null,
      shipperCompanyCity: null,
      shipperCompanyStateProvince: null,
      consigneeCompanyId: null,
      consigneeCompanyName: null,
      consigneeCompanyCity: null,
      consigneeCompanyStateProvince: null,
      shipmentEquipmentType: null,
      shipmentOpenLoadNotes: null,
      shipmentStatusId: null,
      shipmentIsCovered: false,
      shipmentCarrierMcNumber: null,
      associateId: null,
      associateSystemId: null,
      associateFirstName: null,
      associateLastName: null
    };
  }

  //#endregion

  //#region : RMIS :

  async getRmisCarrierContact(options) {
    const {
      filter = {},
      offset = 0,
      limit = 25,
      sort = []
    } = options;

    const queryParams = {
      filter: JSON.stringify(filter),
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);

    const carrierContacts = await this.communicator
      .get(`/carrier/rmis-contact?${qs}`);

    return carrierContacts.map(cc => this.coerceRmisCarrierContact(cc))
  }

  createRmisCarrierContact() {
    return {
      id: 0,
      mcNumber: null,
      type: null,
      name: null,
      title: null,
      phoneNumber: null,
      faxNumber: null,
      mobileNumber: null,
      email: null,
    }
  }

  coerceRmisCarrierContact(carrierContactData) {
    const overrides = {
      phoneNumber: GatewayFacade.makePhone(carrierContactData.phoneNumber),
      faxNumber: GatewayFacade.makePhone(carrierContactData.faxNumber),
      mobileNumber: GatewayFacade.makePhone(carrierContactData.mobileNumber)
    };
    return Core.Utils
      .merge({}, this.createRmisCarrierContact(), carrierContactData, overrides);
  }


  createRmisDot() {
    return {
      id: null,
      mcNumber: null,
      dotNumber: null,
      commonAuthority: null,
      contractAuthority: null,
      brokerAuthority: null,
      pendingCommonAuthority: null,
      pendingContractAuthority: null,
      pendingBrokerAuthority: null,
      commonAuthorityRevocation: null,
      contractAuthorityRevocation: null,
      brokerAuthorityRevocation: null,
      freightAllowed: null,
      passengerAllowed: null,
      householdGoodsAllowed: null,
      bipdRequired: null,
      cargoRequired: null,
      bondSuretyRequired: null,
      bipdOnFile: null,
      cargoOnFile: null,
      bondSuretyOnFile: null,
      addressStatus: null,
      dbaName: null,
      legalName: null,
      businessAddress: null,
      businessCity: null,
      businessStateProvince: null,
      businessCountry: null,
      businessPostalCode: null,
      businessPhoneNumber: null,
      businessFaxNumber: null,
      mailingAddress: null,
      mailingCity: null,
      mailingStateProvince: null,
      mailingCountry: null,
      mailingPostalCode: null,
      mailingPhoneNumber: null,
      mailingFaxNumber: null,
      updateDate: null
    }
  }
  coerceRmisDot(rmisDotContactData) {
    const overrides = {
      businessPhoneNumber: GatewayFacade.makePhone(rmisDotContactData.businessPhoneNumber),
      businessFaxNumber: GatewayFacade.makePhone(rmisDotContactData.businessFaxNumber),
      mailingPhoneNumber: GatewayFacade.makePhone(rmisDotContactData.mailingPhoneNumber),
      mailingFaxNumber: GatewayFacade.makePhone(rmisDotContactData.mailingFaxNumber),
      updateDate: GatewayFacade.makeDate(rmisDotContactData.updateDate)
    };
    return Core.Utils
      .merge({}, this.createRmisDot(), rmisDotContactData, overrides);
  };

  createRmisDotTesting() {
    return {
      id: null,
      mcNumber: null,
      safetyRatingDate: null,
      safetyRating: null,
      safetyReviewDate: null,
      safetyReviewType: null,
      operatingStatus: null,
      outOfServiceDate: null,
      saferStatus: null,
      saferStatusDate: null,
      originalAuthorityGrantDate: null,
      latestAuthorityGrantDate: null,
      latestAuthorityReinstatedDate: null,
      totalTrucks: null,
      totalPowerUnits: null
    }
  }
  coerceRmisDotTesting(rmisDotTestingData) {
    const overrides = {
      safetyRatingDate: GatewayFacade.makeDateOnly(rmisDotTestingData.safetyRatingDate),
      safetyReviewDate: GatewayFacade.makeDateOnly(rmisDotTestingData.safetyReviewDate),
      outOfServiceDate: GatewayFacade.makeDateOnly(rmisDotTestingData.outOfServiceDate),
      saferStatusDate: GatewayFacade.makeDateOnly(rmisDotTestingData.saferStatusDate),
      originalAuthorityGrantDate: GatewayFacade.makeDateOnly(rmisDotTestingData.originalAuthorityGrantDate),
      latestAuthorityGrantDate: GatewayFacade.makeDateOnly(rmisDotTestingData.latestAuthorityGrantDate),
      latestAuthorityReinstatedDate: GatewayFacade.makeDateOnly(rmisDotTestingData.latestAuthorityReinstatedDate)
    };
    return Core.Utils
      .merge({}, this.createRmisDotTesting(), rmisDotTestingData, overrides);
  };

  createRmisInsuranceCoverage() {
    return {
      id: null,
      mcNumber: null,
      description: null,
      status: null,
      effectiveDate: null,
      expirationDate: null,
      cancelDate: null,
      policyNumber: null,
      producer: null,
      producerPhoneNumber: null,
      producerFaxNumber: null,
      producerEmail: null,
      producerAddress: null,
      producerCity: null,
      producerStateProvince: null,
      producerPostalCode: null,
      underwriterName: null,
      underwriterRating: null,
      confidence: null,
      naicCompanyNumber: null,
      amBestCompanyNumber: null
    }
  }
  coerceRmisInsuranceCoverage(rmisInsuranceCoverageData) {
    const overrides = {
      effectiveDate: GatewayFacade.makeDateOnly(rmisInsuranceCoverageData.effectiveDate),
      expirationDate: GatewayFacade.makeDateOnly(rmisInsuranceCoverageData.expirationDate),
      cancelDate: GatewayFacade.makeDateOnly(rmisInsuranceCoverageData.cancelDate),
      producerPhoneNumber: GatewayFacade.makePhone(rmisInsuranceCoverageData.producerPhoneNumber),
      producerFaxNumber: GatewayFacade.makePhone(rmisInsuranceCoverageData.producerFaxNumber),
      rmisInsuranceCoverageLimit: this.coerceRmisInsuranceCoverageLimit(rmisInsuranceCoverageData.rmisInsuranceCoverageLimit)
    };
    return Core.Utils
      .merge({}, this.createRmisInsuranceCoverage(), rmisInsuranceCoverageData, overrides);
  };

  createRmisInsuranceCoverageLimit() {
    return {
      id: null,
      rmisInsuranceCoverageId: null,
      description: null,
      amount: null,
      currencyCode: null,
      synonym: null
    }
  }
  coerceRmisInsuranceCoverageLimit(rmisInsuranceCoverageLimitData) {
    const overrides = {};
    return Core.Utils
      .merge({}, this.createRmisInsuranceCoverageLimit(), rmisInsuranceCoverageLimitData, overrides);
  };

  //#endregion

  //#region : Roles :

  async getRmisCarrier(mcNumber) {
    const rmisCarrierData = await this.communicator.get(`/carrier/${mcNumber}/rmis-carrier`);

    return rmisCarrierData ?
      this.coerceRmisCarrier(rmisCarrierData) :
      null;
  }

  createRmisCarrier() {
    return {
      id: 0,
      name: null,
      taxId: null,
      mcNumber: null,
      dotNumber: null,
      address1: null,
      address2: null,
      city: null,
      stateProvince: null,
      postalCode: null,
      contactName: null,
      contactTitle: null,
      contactPhoneNumber: null,
      contactFaxNumber: null,
      contactEmail: null,
      payToAddress1: null,
      payToAddress2: null,
      payToCity: null,
      payToStateProvince: null,
      payToPostalCode: null,
      payToEmail: null,
      isCertified: false,
      certificationDate: null,
      certificationFailureReasons: null,
    }
  }

  coerceRmisCarrier(carrierData) {
    const overrides = {
      certificationDate: GatewayFacade.makeDate(carrierData.certificationDate),
      contactPhoneNumber: GatewayFacade.makePhone(carrierData.contactPhoneNumber),
      contactFaxNumber: GatewayFacade.makePhone(carrierData.contactFaxNumber),
    };
    return Core.Utils
      .merge({}, this.createRmisCarrier(), carrierData, overrides);
  }


  //#endregion

  //#region : Roles :

  async getAllRoles() {
    const roles = await this.communicator.get('/associate-role');

    return roles;
  }

  //#endregion

  //#region : Shipments :

  async searchPayPeriodShipments(commissionDate, associateId) {

    const queryParams = {
      startDate: commissionDate.startDate,
      endDate: commissionDate.endDate
    };

    const qs = Qs.stringify(queryParams);

    const { shipments, count } = await this.communicator
      .get(`/associate/${associateId}/pay-period-shipment?${qs}`);
    return {
      shipments: shipments.map(s => this.coerceShipment(s)),
      count
    };
  }

  async searchShipmentBolNumbers(options) {
    const {
      searchTerm,
      limit = 50,
    } = options;

    const queryParams = {
      bolNumberSearchTerm: searchTerm,
      limit,
    };

    const qs = Qs.stringify(queryParams);
    const shipmentBolNumbers = await this.communicator
      .get(`/shipment/bol-number-search?${qs}`);

    return shipmentBolNumbers;
  }

  async getShipment(bolNumber) {
    Core.Validation.Mandate.argIsNumber(bolNumber, 'bolNumber');
    const shipmentData = await this.communicator.get(`/shipment/${bolNumber}`);
    return this.coerceShipment(shipmentData);
  };

  async saveShipment(shipmentModel) {

    Core.Validation.Mandate.argIsDate(shipmentModel.bolDate, 'shipmentData.bolDate');

    const emptyShipmentModel = this.createShipment();

    const shipmentData = GatewayFacade.extract(shipmentModel, emptyShipmentModel);

    // Be sure the shipment has a bolDate in UTC for the server, this should
    // remove the timestamp to ensure that back-end queries can find a shipment
    // within a date range.
    shipmentData.bolDate = getUtcDate(shipmentData.bolDate);
    shipmentData.estimatedDeliveryDate = shipmentData.estimatedDeliveryDate ?
      getUtcDate(shipmentData.estimatedDeliveryDate) : shipmentData.estimatedDeliveryDate;

    const updatedShipmentData = await this.communicator.post(`/shipment`, shipmentData);

    return this.coerceShipment(updatedShipmentData);
  };

  async duplicateShipment(bolNumber, duplicateCount, mappingConfig) {

    mappingConfig.duplicateCount = duplicateCount;
    const bolNumbers = await this.communicator.post(`/shipment/${bolNumber}/duplicate`, mappingConfig);
    return bolNumbers;
  };

  async getCompanyFirstShipment(companyId) {
    Core.Validation.Mandate.argIsNumber(companyId, 'companyId');
    const firstShipmentData = await this.communicator.get(`/shipment/customer/${companyId}/first-shipment`);
    return firstShipmentData &&
      this.coerceCustomerShipmentListing(firstShipmentData);
  }

  async getCustomerFinancialMetrics(companyId) {
      Core.Validation.Mandate.argIsNumber(companyId, 'companyId');
      const metrics = await this.communicator.get(`/shipment/customer/${companyId}/financial-metrics`);
      return metrics;
  }
  async getCustomerShipmentListings(customerId, fromDate, toDate, bolNumber, filterToAging, offset, limit, sort, viewBlindOnly, viewHazMatOnly, viewMultiStopsOnly, equipmentType) {

    const queryParams = {
      offset,
      limit,
      sort,
      customerId,
      fromDate,
      toDate,
      bolNumber,
      filterToAging,
      viewBlindOnly,
      viewHazMatOnly,
      viewMultiStopsOnly,
      equipmentType
    };

    const qs = Qs.stringify(queryParams);

    const { shipments, count } = await this.communicator.get(`/shipment/customer/?${qs}`);

    return {
      shipments: shipments
        .map(s => this.coerceCustomerShipmentListing(s)),
      count
    };

  }

  async haltShipment(bolNumber, reason) {
    await this.communicator.post(`/shipment/${bolNumber}/halt`, {reason: reason});
  };

  async revertShipment(bolNumber) {
    await this.communicator.post(`/shipment/${bolNumber}/revert`);
  };

  async saveShipmentAutomation(bolNumber, isDisabled) {
    await this.communicator.post(`/shipment/${bolNumber}/disable-automation`, {isDisabled: isDisabled})
  }

  async getAutoInvoiceReportListing(odata = {}) {
    const qs = buildOdataQuery(odata);
    const result = await this.communicator.get(`/reports/auto-invoice-report-listing${qs}`);
    return result;
  }

  async getBadDebtReportData(odata = {}) {
    const qs = buildOdataQuery(odata);
    const result = await this.communicator.get(`/reports/bad-debt${qs}`);
    return result;
  }

  async getTgfMonthlyMetrics() {
    const metrics = await this.communicator.get('/shipment/monthly-metrics/tgf');
    return metrics ?
      this.coerceMonthlyMetrics(metrics) :
      this.createEmptyMonthlyMetrics();
  }

  async getAssociateMonthlyMetrics() {
    const metrics = await this.communicator.get('/shipment/monthly-metrics/associate');

    return metrics ?
      this.coerceMonthlyMetrics(metrics) :
      this.createEmptyMonthlyMetrics();
  }

  async getFreightCategoryTypes() {
    const freightCategoryTypes = await this.communicator.get('/shipment-freight-category');

    return freightCategoryTypes;
  }

  async deleteHelpDocument(documentId) {
    const document = await this.communicator.delete(`/support/document/${documentId}`)

    return document;
  }

  async publishHelpDocument(helpDocument) {
    const formData = new FormData();

    formData.append("document", helpDocument.document);
    if (helpDocument.description) {
      formData.append("description", helpDocument.description);
    }
    formData.append("documentTypeId", helpDocument.documentTypeId);
    formData.append("permAllUsers", helpDocument.permAllUsers);
    formData.append("permBackOfficeOnly", helpDocument.permBackOfficeOnly);

    const document = await this.communicator.postFormData(`/support/document`, formData)

    return this.coerceHelpDocument(document);
  }

  async getHelpDocuments() {
    const helpDocuments = await this.communicator.get(`/support/document`);

    return helpDocuments
      .map(d => this.coerceHelpDocument(d));
  }

  coerceHelpDocument(document) {
    const overrides = {
      uploadDate: GatewayFacade.makeDate(document.uploadDate)
    };
    return Core.Utils
      .merge({}, this.createHelpDocument(), document, overrides);
  }

  createHelpDocument() {
    return {
      id: 0,
      typeId: null,
      description: null,
      filename: null,
      path: null,
      mimeType: null,
      uploadingAssociateId: null,
      uploadDate: null
    };
  }

  async publishCompanyDocument(companyId, companyDocument) {
    const formData = new FormData();

    formData.append("document", companyDocument.document);
    if (companyDocument.description) {
      formData.append("description", companyDocument.description);
    }
    formData.append("documentTypeId", companyDocument.documentTypeId);

    const document = await this.communicator.postFormData(`/company/${companyId}/document`, formData)

    return document;
  }

  async deleteCompanyDocument(companyId, documentId) {
    const document = await this.communicator.delete(`/company/${companyId}/document/${documentId}`)

    return document;
  }

  async getCompanyDocuments(companyId) {
    const companyDocuments = await this.communicator.get(`/company/${companyId}/document`);

    return companyDocuments;
  }

  async getCompanyDocumentTypes() {
    const companyDocumentTypes = await this.communicator.get('/company-document-type');

    return companyDocumentTypes;
  }

  async publishShipmentDocument(bolNumber, shipmentDocument) {
    const formData = new FormData();

    formData.append("document", shipmentDocument.document);
    if (shipmentDocument.description) {
      formData.append("description", shipmentDocument.description);
    }
    formData.append("documentTypeId", shipmentDocument.documentTypeId);

    const document = await this.communicator.postFormData(`/shipment/${bolNumber}/document`, formData)

    return document;
  }

  async deleteShipmentDocument(bolNumber, documentId) {
    const document = await this.communicator.delete(`/shipment/${bolNumber}/document/${documentId}`)

    return document;
  }

  async getShipmentDocuments(bolNumber) {
    const shipmentDocuments = await this.communicator.get(`/shipment/${bolNumber}/document`);

    return shipmentDocuments;
  }

  async getShipmentDocumentTypes() {
    const shipmentDocumentTypes = await this.communicator.get('/shipment-document-type');

    return shipmentDocumentTypes;
  }

  async publishCarrierDocument(mcNumber, carrierDocument) {
    const formData = new FormData();

    formData.append("document", carrierDocument.document);
    if (carrierDocument.description) {
      formData.append("description", carrierDocument.description);
    }
    formData.append("documentTypeId", carrierDocument.documentTypeId);

    const document = await this.communicator.postFormData(`/carrier/${mcNumber}/document`, formData)

    return document;
  }

  async deleteCarrierDocument(mcNumber, documentId) {
    const document = await this.communicator.delete(`/carrier/${mcNumber}/document/${documentId}`)

    return document;
  }

  async getCarrierDocuments(mcNumber) {
    const carrierDocuments = await this.communicator.get(`/carrier/${mcNumber}/document`);

    return carrierDocuments;
  }

  async getCarrierDocumentTypes() {
    const carrierDocumentTypes = await this.communicator.get('/carrier-document-type');

    return carrierDocumentTypes;
  }

  coerceMonthlyMetrics(metrics) {
    return Object.assign({}, this.createEmptyMonthlyMetrics(), metrics);
  }

  createEmptyMonthlyMetrics() {
    return {
      shipmentYear: 0,
      shipmentMonth: 0,
      shipmentCount: 0,
      customerCost: 0.0,
      carrierCost: 0.0,
      averageRevenue: 0.0,
      totalMargin: 0.0,
      averageMargin: 0.0,
      averageMarginPercent: 0.0
    };
  }

  async getDeliveryCount() {
    const deliveryCount = await this.communicator.get('/shipment/count-tracked');
    return deliveryCount;
  }

  async getStatusTypes() {
    const statusTypes = await this.communicator.get(`/shipment-status`);
    const allowedTypeNames = Core.Utils.values(StatusTypeNames);
    return Core.Utils.orderBy(statusTypes, ['bolHistoryOrder'])
      .filter(st => allowedTypeNames.includes(st.name));
  };

  async getEquipmentTypes() {
    const loadEquipmentTypes = await this.communicator.get(`/shipment-equipment-type`);
    return loadEquipmentTypes;
  };

  async getHazardousStates() {
    const loadHazardousState = await this.communicator.get(`/shipment-hazardous-state`);
    return loadHazardousState;
  };

  async getTrackedShipmentCounts(options) {
    const countTrackedQs = Qs.stringify(options);
    const countTracked = await this.communicator
      .get(`/shipment/count-tracked?${countTrackedQs}`);

    return countTracked;
  }

  async getTrackedShipments(query) {
    const qs = Qs.stringify(query);
    return await this.communicator.get(`/shipment/list-tracked?${qs}`);
  }

  createTrackedShipment() {
    return {
      associateId: null,
      associateShortName: null,
      bolDate: null,
      bolNumber: null,
      carrierMcNumber: null,
      carrierName: null,
      companyId: null,
      companyName: null,
      customerRep: null,
      loadTrackingAssociateShortName: null,
      loadTrackingCreateDate: null,
      loadTrackingNote: null,
      loadTrackingPhase: null,
      proNumber: null,
      refNum1: null,
      refNum1Description: null,
      shipmentId: null,
      statusId: null,
    };
  }

  coerceTrackedShipment(listTrackedData) {
    const overrides = {
      bolDate: GatewayFacade.makeDateOnly(listTrackedData.bolDate),
      loadTrackingCreateDate: GatewayFacade.makeDate(listTrackedData.loadTrackingCreateDate)
    };

    return Core.Utils
      .merge({}, this.createTrackedShipment(), listTrackedData, overrides);
  }

  createShipment() {
    return {
      id: 0,
      bolNumber: null,
      ratingRefNumber: null,
      bolDate: null,
      freightCategoryId: null,
      billingCompanyId: null,
      statusId: null,
      associateId: null,
      customerId: null,
      shipperId: null,
      carrierMcNumber: null,
      consigneeId: null,
      thirdPartyId: null,
      note: null,
      refNum1Description: null,
      refNum1: null,
      refNum2Description: null,
      refNum2: null,
      refNum3Description: null,
      refNum3: null,
      refNum4Description: null,
      refNum4: null,
      isBlind: null,
      carrierPerformRating: null,
      estimatedPickupDate: null,
      pickupTime: null,
      pickupNumber: null,
      estimatedDeliveryDate: null,
      deliveryTime: null,
      deliveryNumber: null,
      loadTrackingId: null,
      equipmentType: null,
      hazardousStateId: null,
      truckNumber: null,
      trailerNumber: null,
      driverName: null,
      driverPhone: null,
      estimatedDeliveryDate2: null,
      proNumber: null,
      rateConNote: null,
      customerRep: null,
      blindBolAttachment: null,
      hasBeenQbInvoiced: null,
      isMultipleStopShipper: null,
      isMultipleStopConsignee: null,
      multiShipperIdStop1: null,
      multiConsigneeIdStop1: null,
      bolInternalNote: null,
      doPrintInvoiceBatch: null,
      isDeliveryOrderRequired: null,
      isMultipleStop: null,
      openLoadNote: null,
      isCovered: null,
      lockedAssociateId: null,
      lockedDate: null,
      shouldUseAutoTrack: null,
      macroPointOrderId: null,
      autoTrackSubmitAssociateId: null,
      autoTrackSubmitDate: null,
      autoTrackCancelAssociateId: null,
      autoTrackCancelDate: null,
      isHardCopyPodRequired: null,
      isVerbalPodRequired: null,
      quoteSource: null,
      signedForBy: null,
      ltlCarrierPickupNumber: null,
      pickupTerminalNumber: null,
      deliveryTerminalNumber: null,
      driverCallContactPreferred: null,
      driverTextContactPreferred: null,
      createdViaLtlRatingsEngine: false,
    };
  };

  createCustomerShipmentListing() {
    return {
      companyId: null,
      shipmentBolNumber: null,
      shipmentBolDate: null,
      shipmentLockedDate: null,
      carrierName: null,
      carrierMcNumber: null,
      shipmentStatusName: null,
      carrierCost: null,
      customerCost: null,
      totalMargin: null,
      invoiceEarnedPayout: null,
      invoiceTgfFee: null,
      invoiceDate: null,
      invoiceActualDeliveryDate: null,
      companyAssociateId: null,
      invoiceOneTimePayout: null,
      invoiceDaysPastDue: null,
      invoiceDueDate: null,
      invoiceHasCustomerPaid: null,
      invoiceWasDashboardInvoiceSent: null,
      invoicePaidDate: null,
      commissionPayoutDate: null
    };
  }

  coerceShipment(shipmentData) {
    if (shipmentData) {
      const overrides = {
        bolDate: GatewayFacade.makeDateOnly(shipmentData.bolDate),
        estimatedPickupDate: GatewayFacade.makeDateOnly(shipmentData.estimatedPickupDate),
        estimatedDeliveryDate: GatewayFacade.makeDateOnly(shipmentData.estimatedDeliveryDate),
        autoTrackSubmitDate: GatewayFacade.makeDateOnly(shipmentData.autoTrackSubmitDate),
        autoTrackCancelDate: GatewayFacade.makeDateOnly(shipmentData.autoTrackCancelDate),
        lockedDate: GatewayFacade.makeDate(shipmentData.lockedDate),
        invoice: shipmentData.invoice ?
          this.coerceInvoice(shipmentData.invoice) : null
      };

      return Core.Utils
        .merge({}, this.createShipment(), shipmentData, overrides);
    }
    return null;
  }

  coerceCustomerShipmentListing(shipmentData) {
    const overrides = {
      shipmentBolDate: GatewayFacade.makeDateOnly(shipmentData.shipmentBolDate),
      shipmentLockedDate: GatewayFacade.makeDate(shipmentData.shipmentLockedDate),
      invoiceDate: GatewayFacade.makeDateOnly(shipmentData.invoiceDate),
      invoiceActualDeliveryDate: GatewayFacade.makeDateOnly(shipmentData.invoiceActualDeliveryDate),
      invoiceDueDate: GatewayFacade.makeDateOnly(shipmentData.invoiceDueDate),
      invoicePaidDate: GatewayFacade.makeDateOnly(shipmentData.invoicePaidDate),
      commissionPayoutDate: GatewayFacade.makeDateOnly(shipmentData.commissionPayoutDate)
    };

    return Core.Utils
      .merge({}, this.createCustomerShipmentListing(), shipmentData, overrides);
  }

  async markShipmentsCustomerPaid(bolNumbers) {

    const { shipments } = await this.communicator
      .post('/shipment/mark-customer-paid', { bolNumbers });

    return shipments
      .map(shipment => {
        shipment.invoice = this.coerceInvoice(shipment.invoice);
        return this.coerceShipment(shipment);
      });
  }

  async getInvoicingExceptionWorkflowQueue(filter, offset, limit, sort) {

    const queryParams = {
      filter: JSON.stringify(filter),
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);

    const { shipments, count } = await this.communicator.get(`/shipment/invoicing-exception-workflow-queue?${qs}`);
    return {
      shipments: shipments
        .map(s => this.coerceInvoicingExceptionWorkflowQueue(s)),
      count
    };

  }

  coerceInvoicingExceptionWorkflowQueue(shipmentData) {
    const overrides = {
      shipmentBolDate: GatewayFacade.makeDateOnly(shipmentData.shipmentBolDate),
      invoiceConfirmedLockDate: GatewayFacade.makeDateOnly(shipmentData.invoiceConfirmedLockDate)
    };
    return Core.Utils.merge({}, this.createInvoicingExceptionWorkflowQueue(), shipmentData, overrides);
  }

  createInvoicingExceptionWorkflowQueue() {
    return {
      shipmentBolNumber: null,
      shipmentBolDate: null,
      invoiceConfirmedLockDate: null,
      invoiceAdjustedCustomerCost: null,
      associateId: null,
      associateSystemId: null,
      associateFirstName: null,
      associateLastName: null,
      companyId: null,
      companyName: null,
      companyInvoiceDeliveryMethodTypeId: null
    };
  }

  async getAutomatedInvoicingWorkflowQueue(filter, offset, limit, sort) {

    const queryParams = {
      filter: JSON.stringify(filter),
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);

    const { shipments, count } = await this.communicator.get(`shipment/automated-invoicing-workflow-queue?${qs}`);
    return {
      shipments: shipments
        .map(s => this.coerceAutomatedInvoicingWorkflowQueue(s)),
      count
    };

  }

  coerceAutomatedInvoicingWorkflowQueue(shipmentData) {
    const overrides = {
      shipmentBolDate: GatewayFacade.makeDateOnly(shipmentData.shipmentBolDate),
      shipmentLockedDate: GatewayFacade.makeDate(shipmentData.shipmentLockedDate)
    };
    return Core.Utils.merge({}, this.createAutomatedInvoicingWorkflowQueue(), shipmentData, overrides);
  }

  createAutomatedInvoicingWorkflowQueue() {
    return {
      shipmentBolNumber: null,
      associateId: null,
      associateFirstName: null,
      associateLastName: null,
      associateSystemId: null,
      companyId: null,
      companyName: null,
      companyInvoiceDeliveryMethodTypeId: null,
      shipmentLockedDate: null,
      lockingAssociateId: null,
      lockingAssociateFirstName: null,
      lockingAssociateLastName: null,
      lockingAssociateSystemId: null,
      adjustedCustomerCost: null,
    };
  }

  async getManualInvoicedShipments(odata) {
    const qs = buildOdataQuery(odata);
    const result = await this.communicator.get(`/reports/manual-invoice-report-listing${qs}`);
    return result;
  };

  //#endregion

  //#region : Shipments Contents :

  async getAllShipmentContents(bolNumber) {
    Core.Validation.Mandate.argIsNumber(bolNumber, 'bolNumber');
    const shipmentContents = await this.communicator.get(`/shipment/${bolNumber}/content`);
    return shipmentContents;
  }

  async saveShipmentContent(shipmentContentModel) {
    const emptyContentData = this.createShipmentContent();

    const contentdata = GatewayFacade.extract(shipmentContentModel, emptyContentData);

    const updatedContentData = await this.communicator
      .post(`/shipment/${shipmentContentModel.bolNumber}/content`, contentdata);

    return updatedContentData;
  };

  async deleteShipmentContent(bolNumber, shipmentContentId) {
    const result = await this.communicator
      .delete(`/shipment/${bolNumber}/content/${shipmentContentId}`);
    return result;
  }

  async getShipmentPackageTypes() {
    const packageTypes = await this.communicator.get('/shipment-package-type');
    return packageTypes;
  }

  async getShipmentFreightClasses() {
    const freightClasses = await this.communicator.get('/shipment-freight-class');
    return freightClasses;
  }

  createShipmentContent() {
    return {
      id: 0,
      bolNumber: null,
      packageCount: null,
      pieceCount: null,
      packageTypeId: null,
      description: null,
      dimensions: null,
      freightClassId: null,
      nmfc: null,
      packageWeight: null,
      note: null,
      haz: null
    };
  };

  async getShipmentNmfc() {
    const shipmentNMFC = await this.communicator.get('/shipment-nmfc');
    return shipmentNMFC;
  }

  //endregion

  //#region : Shipments Truckload Value :

  async getShipmentTruckLoadConfirmation(bolNumber) {
    Core.Validation.Mandate.argIsNumber(bolNumber, 'bolNumber');
    const shipmentTruckLoadConfirmation = await this.communicator.get(`/shipment/${bolNumber}/truckload-value-confirmation`);
    return shipmentTruckLoadConfirmation ?
      this.coerceShipmentTruckLoadConfirmation(shipmentTruckLoadConfirmation) :
      null;
  }

  async saveShipmentTruckLoadConfirmation(shipmentTruckLoadValueModel) {
    const emptyTruckLoadValueData = this.createShipmentTruckLoadConfirmation();

    const truckLoadValueData = GatewayFacade.extract(shipmentTruckLoadValueModel, emptyTruckLoadValueData);

    const updatedTruckLoadValueData = await this.communicator
      .post(`/shipment/${shipmentTruckLoadValueModel.bolNumber}/confirm-truckload-value`, truckLoadValueData);


    return this.coerceShipmentTruckLoadConfirmation(updatedTruckLoadValueData);
  };

  async deleteShipmentTruckLoadConfirmation(bolNumber) {
    Core.Validation.Mandate.argIsNumber(bolNumber, 'bolNumber');
    const updatedTruckLoadValueData = await this.communicator
      .delete(`/shipment/${bolNumber}/confirm-truckload-value`);

    return updatedTruckLoadValueData;
  };

  async getShipmentTruckLoadConfirmationTypes() {
    const truckloadValueConfirmationTypes = await this.communicator.get('/shipment-truckload-value-confirmation-type');
    return truckloadValueConfirmationTypes;
  }

  createShipmentTruckLoadConfirmation() {
    return {
      id: 0,
      bolNumber: null,
      typeId: null,
      confirmedDate: null,
      confirmingAssociateId: null,
      confirmedValue: null
    };
  };

  coerceShipmentTruckLoadConfirmation(shipmentTruckLoadValueData) {
    const overrides = {
      confirmedDate: GatewayFacade.makeDate(shipmentTruckLoadValueData.confirmedDate)
    };
    return Core.Utils
      .merge({}, this.createShipmentTruckLoadConfirmation(), shipmentTruckLoadValueData, overrides);
  };

  //endregion

  //#region : Shipments Tracking Notes :

  async getAllShipmentTrackingNotes(bolNumber) {
    Core.Validation.Mandate.argIsNumber(bolNumber, 'bolNumber');
    const trackingNotes = await this.communicator.get(`/shipment/${bolNumber}/load-tracking-note`);
    return trackingNotes
      .map(notes => this.coerceTrackingNote(notes));
  };

  async getLoadTrackingPhases() {
    const loadTrackingPhases = await this.communicator.get(`/shipment-load-tracking-phase`);
    return loadTrackingPhases;
  };

  async saveLoadTrackingNote(trackingNoteModel) {
    const trackingNoteData = this.extractTrackingNote(trackingNoteModel);

    Core.Validation.Mandate.argIsNumber(trackingNoteData.associateId, 'trackingNoteData.associateId');
    Core.Validation.Mandate.argIsNumber(trackingNoteData.bolNumber, 'trackingNoteData.bolNumber');
    Core.Validation.Mandate.argIsNumber(trackingNoteData.loadTrackingPhaseId, 'trackingNoteData.loadTrackingPhaseId');
    Core.Validation.Mandate.argIsNonEmptyString(trackingNoteData.note, 'trackingNoteData.note');
    Core.Validation.Mandate.argIsDate(trackingNoteData.trackingDate, 'trackingNoteData.trackingDate');
    Core.Validation.Mandate.argIsDate(trackingNoteData.createDate, 'trackingNoteData.createDate');

    const updatedNoteData = await this.communicator
      .post(`/shipment/${trackingNoteData.bolNumber}/load-tracking-note`, trackingNoteData);

    return this.coerceCompanyNote(updatedNoteData);
  };


  coerceTrackingNote(trackingNoteData) {
    const overrides = {
      trackingDate: GatewayFacade.makeDate(trackingNoteData.createDate),
      createDate: GatewayFacade.makeDate(trackingNoteData.createDate)
    };
    return Core.Utils
      .merge({}, this.createShipmentTrackingNote(), trackingNoteData, overrides);
  };

  extractTrackingNote(trackingNoteModel) {
    return {
      id: trackingNoteModel.id,
      bolNumber: trackingNoteModel.bolNumber,
      trackingDate: GatewayFacade.makeDate(new Date()),
      associateId: trackingNoteModel.associateId,
      note: trackingNoteModel.note,
      loadTrackingPhaseId: trackingNoteModel.loadTrackingPhaseId,
      createDate: GatewayFacade.makeDate(new Date())
    };
  }

  createShipmentTrackingNote() {
    return {
      id: null,
      bolNumber: null,
      trackingDate: null,
      signer: null,
      associateId: null,
      note: null,
      loadTrackingPhaseId: null,
      createdDate: null
    };
  };

  //endregion

  //#region : Shipments invoice/financial :

  async getInvoice(bolNumber) {
    Core.Validation.Mandate.argIsNumber(bolNumber, 'bolNumber');
    const invoice = await this.communicator.get(`/shipment/${bolNumber}/invoice`);
    return this.coerceInvoice(invoice);
  };

  async saveInvoice(invoiceModel, shouldPerformDisablementCheck) {

    const emptyInvoiceModel = this.createInvoice();

    const invoiceData = GatewayFacade.extract(invoiceModel, emptyInvoiceModel);

    // Fix all "Date only" fields with appropriate timestamp of midnight UTC.
    // These fields will receive makeDateOnly when being coerced which will then
    // apply the appropriate date when viewed.
    invoiceData.invoiceDate = invoiceData.invoiceDate ?
      getUtcDate(invoiceData.invoiceDate) : invoiceData.invoiceDate;
    invoiceData.actualDeliveryDate = invoiceData.actualDeliveryDate ?
      getUtcDate(invoiceData.actualDeliveryDate) : invoiceData.actualDeliveryDate;
    invoiceData.auditDate = invoiceData.auditDate ?
      getUtcDate(invoiceData.auditDate) : invoiceData.auditDate;
    invoiceData.invoiceDueDate = invoiceData.invoiceDueDate ?
      getUtcDate(invoiceData.invoiceDueDate) : invoiceData.invoiceDueDate;

    const updatedInvoiceData = await this.communicator
      .post(`/shipment/${invoiceData.bolNumber}/invoice`, {invoiceData: invoiceData, shouldPerformDisablementCheck: shouldPerformDisablementCheck});

    return this.coerceInvoice(updatedInvoiceData);
  };

  coerceInvoice(invoice) {
    if (invoice) {
      const overrides = {
        invoiceDate: GatewayFacade.makeDateOnly(invoice.invoiceDate),
        actualDeliveryDate: GatewayFacade.makeDateOnly(invoice.actualDeliveryDate),
        auditDate: GatewayFacade.makeDateOnly(invoice.auditDate),
        invoiceDueDate: GatewayFacade.makeDateOnly(invoice.invoiceDueDate),
        auditFinalizedDate: GatewayFacade.makeDate(invoice.auditFinalizedDate),
        auditQuickBooksUpdateDate: GatewayFacade.makeDateOnly(invoice.auditQuickBooksUpdateDate),
        customerPaidDate: GatewayFacade.makeDateOnly(invoice.customerPaidDate),
        finalizingAssociate: invoice.finalizingAssociate ?
          this.coerceAssociate(invoice.finalizingAssociate) :
          null,
        // Coerce costs to ensure nulls don't escape into the components.
        customerCost: invoice.customerCost || 0,
        carrierCost: invoice.carrierCost || 0,
        adjustedCustomerCost: invoice.adjustedCustomerCost || 0,
        adjustedCarrierCost: invoice.adjustedCarrierCost || 0
      };

      return Core.Utils
        .merge({}, this.createInvoice(), invoice, overrides);
    }
    return null;
  };

  createInvoice() {
    return {
      id: 0,
      bolNumber: null,
      invoiceDate: null,
      customerCost: 0,
      customerInvoice: null,
      carrierCost: 0,
      customerWasPaid: false,
      carrierWasPaid: false,
      detentionAmount: null,
      layoverAmount: null,
      carrierAdvanceAmount: null,
      carrierAdvanceReferenceNumber: null,
      reweighAdjustmentAmount: null,
      reclassAdjustmentAmount: null,
      signedRateConfirmationAttachment: null,
      proofOfDeliveryAttachment: null,
      customerInvoiceAttachment: null,
      customerChargeWaiverAmount: null,
      genericAttachmentName: null,
      genericAttachment: null,
      genericAttachmentName2: null,
      genericAttachment2: null,
      customerDetentionAmount: null,
      customerLayoverAmount: null,
      customerReweighAmount: null,
      customerReclassAmount: null,
      otherCost: null,
      carrierInvoice: null,
      associateWasPaid: false,
      salesCoordinatorWasPaid: false,
      financialNote: null,
      invoiceNote: null,
      adjustedCarrierCost: 0,
      adjustedCustomerCost: 0,
      carrierLumpersAmount: null,
      customerLumpersAmount: null,
      carrierOtherAmountLabel: null,
      carrierOtherAmount: null,
      customerOtherAmountLabel: null,
      customerOtherAmount: null,
      carrierDeductedCost1Label: null,
      carrierDeductedCost1: null,
      customerDeductedCost1Label: null,
      customerDeductedCost1: null,
      carrierDeductedCost2Label: null,
      carrierDeductedCost2: null,
      customerDeductedCost2Label: null,
      customerDeductedCost2: null,
      dashboardInvoiceWasGenerated: false,
      dashboardInvoiceWasSent: false,
      dashboardCustomerWasPaid: false,
      hasDashboardQuickBooksAlert: false,
      actualDeliveryDate: null,
      ownerNote: null,
      postedFor: null,
      confirmedAssociateWasPaid: false,
      oneTimePayoutAmount: null,
      oneTimePayoutRep: null,
      oneTimeRepPayoutWasPaid: false,
      confirmedOneTimeRepPayoutWasPaid: false,
      invoicedAmount: null,
      auditDate: null,
      invoiceDueDate: null,
      carrierProfileName: null,
      carrierProfileAddress1: null,
      carrierProfileAddress2: null,
      carrierProfileCity: null,
      carrierProfileStateProvince: null,
      carrierProfilePostalCode: null,
      remitToName: null,
      remitToAddress1: null,
      remitToAddress2: null,
      remitToCity: null,
      remitToStateProvince: null,
      remitToPostalCode: null,
      auditFinalizedDate: null,
      auditQuickBooksUpdateDate: null,
      finalizingAssociateId: null,
      customerPaidDate: null,
      fee: null,
      earnedPayout: null,
      finalizingAssociate: null,
      doNotPayCarrier:null
    };
  };

  async updateShipmentInvoice(bolNumber, invoiceData) {
    invoiceData.invoiceDateTime = invoiceData.invoiceDateTime ?
        getUtcDate(invoiceData.invoiceDateTime) : null;

    const queryParams = {
      bolNumber: bolNumber,
      invoiceData: {...invoiceData}
    }

    const qs = Qs.stringify(queryParams);

    return await this.communicator.post(`/shipment/financials/update-shipment-invoice?${qs}`);
  }

  //endregion

  //#region : Shipment Invoice Audit Notes :

  async getShipmentInvoiceAuditNotes(bolNumber) {
    Core.Validation.Mandate.argIsNumber(bolNumber, 'bolNumber');
    const invoiceAuditNotes = await this.communicator.get(`/shipment/${bolNumber}/invoice-audit-note`);
    return invoiceAuditNotes
      .map(notes => this.coerceShipmentInvoiceAuditNote(notes));
  }

  async saveShipmentInvoiceAuditNote(invoiceAuditNote) {
    Core.Validation.Mandate.argIsNumber(invoiceAuditNote.bolNumber, 'invoiceAuditNote.bolNumber');
    Core.Validation.Mandate.argIsNumber(invoiceAuditNote.associateId, 'invoiceAuditNote.associateId');
    Core.Validation.Mandate.argIsString(invoiceAuditNote.content, 'invoiceAuditNote.content');
    Core.Validation.Mandate.argIsDate(invoiceAuditNote.createDate, 'invoiceAuditNote.createDate');
    const invoiceAuditNoteData = this.extractShipmentInvoiceAuditNote(invoiceAuditNote);
    const updatedInvoiceAuditNotes = await this.communicator
      .post(`/shipment/${invoiceAuditNoteData.bolNumber}/invoice-audit-note`, invoiceAuditNoteData);
    return updatedInvoiceAuditNotes
      .map(n => this.coerceShipmentInvoiceAuditNote(n));
  }

  coerceShipmentInvoiceAuditNote(note) {
    const overrides = {
      createDate: GatewayFacade.makeDate(note.createDate),
      associate: note.associate ?
        this.coerceAssociate(note.associate) :
        null
    };
    return Core.Utils
      .merge({}, this.createShipmentInvoiceAuditNote(), note, overrides);
  }

  createShipmentInvoiceAuditNote() {
    return {
      id: null,
      bolNumber: null,
      associateId: null,
      content: null,
      createDate: null
    };
  }

  extractShipmentInvoiceAuditNote(invoiceAuditNoteModel) {
    return GatewayFacade.extract(invoiceAuditNoteModel, this.createShipmentInvoiceAuditNote());
  }

  //#endregion

  //#region : Shipments Multiple Stop Locations :

  async getShipmentMultipleStop(bolNumber) {
    Core.Validation.Mandate.argIsNumber(bolNumber, 'bolNumber');
    const shipmentMultipleStop = await this.communicator.get(`/shipment/${bolNumber}/multi-stop-location`);
    return shipmentMultipleStop
      .map(s => this.coerceShipmentMultipleStop(s));
  }

  async saveShipmentMultipleStop(multiStopManifest, bolNumber) {

    const {
      multiStops
    } = multiStopManifest;

    multiStops.forEach(stopLocation => {
      // Be sure to only send UTC dates to the server.
      stopLocation.arrivalDate = stopLocation.arrivalDate ?
        getUtcDate(stopLocation.arrivalDate) : stopLocation.arrivalDate;
    });

    const updatedMultipleStopData = await this.communicator
      .post(`/shipment/${bolNumber}/multi-stop-location`, multiStopManifest);

    return updatedMultipleStopData
      .map(ms => this.coerceShipmentMultipleStop(ms));
  };

  async resetShipmentMultipleStop(bolNumber) {

    const updatedMultipleStopData = await this.communicator
      .post(`/shipment/${bolNumber}/reset-multi-stop-location`);

    return updatedMultipleStopData
      .map(ms => this.coerceShipmentMultipleStop(ms));
  };

  coerceShipmentMultipleStop(shipmentMultipleStop) {

    const overrides = {
      arrivalDate: GatewayFacade.makeDateOnly(shipmentMultipleStop.arrivalDate)
    };
    return Core.Utils.merge({}, this.createShipmentMultipleStop(), shipmentMultipleStop, overrides);
  }

  createShipmentMultipleStop() {
    return {
      id: 0,
      bolNumber: null,
      isPickup: false,
      isDelivery: false,
      isUnloadReload: false,
      companyId: null,
      isLocationAll: false,
      arrivalDate: null,
      arrivalTime: null,
      reference: null,
      note: null
    }
  };

  //endregion

  //#region : TeraCrunch Automation :

  async getQaReview(options = {}) {
    const {
      offset = 0,
      limit = 0,
      sort = []
    } = options;

    const queryParams = {
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);
    const { count, AutoProcessedForReview } = await this.communicator.get(`/automation/manual-review?${qs}`);

    return {
      qaReview: AutoProcessedForReview,
      count
    };

  }

  async setQaRejected(teraCrunchInputId) {
    await this.communicator.post(`/automation/${teraCrunchInputId}/rejected`);
  };

  async setQaAccepted(teraCrunchInputId, remitToInputData) {
    await this.communicator.post(`/automation/${teraCrunchInputId}/accepted`, {remitToInputData: remitToInputData});
  };

  async getHardFailReasons(bolNumber) {
    const { count, rows } = await this.communicator.get(`/automation/${bolNumber}/validation-list`);
    return {
      hardFails: rows,
      count
    };
  }

  async getQaFailed(options = {}) {
    const {
      offset = 0,
      limit = 0,
      sort = []
    } = options;

    const queryParams = {
      offset,
      limit,
      sort
    };

    const qs = Qs.stringify(queryParams);
    const { count, AutoProcessedFailed } = await this.communicator.get(`/automation/failed?${qs}`);

    return {
      qaFailed: AutoProcessedFailed,
      count
    };

  }

  async setQaFailed(teraCrunchInputId, dispositionStatus, note) {
    await this.communicator.post(`/automation/${teraCrunchInputId}/failed`, {dispositionStatus: dispositionStatus, note : note});
  };

  async getAutoProcessed(options = {}) {
    const {
      offset = 0,
      limit = 0,
      startDate = null,
      endDate = null,
      byAgentId = null,
      sort = []
    } = options;

    const queryParams = {
      offset,
      limit,
      startDate,
      endDate,
      byAgentId,
      sort
    };

    const qs = Qs.stringify(queryParams);
    const { count, AutoProcessed } = await this.communicator.get(`/automation/auto-processed?${qs}`);

    return {
      autoProcessed: AutoProcessed,
      count
    };

  }

  async listAutomationDocuments(bolNumber) {
    return this.communicator.get(`/automation/${bolNumber}/documents`);
  };

  async listAllDispositionStatuses() {
    return this.communicator.get(`/automation/disposition-statuses`);
  };

  async getTeraCrunchInputRecord(teraCrunchInputId) {
    return this.communicator.get(`/automation/${teraCrunchInputId}/input-record`);
  };

  async listAllClosed(options) {

    const {
      offset = 0,
      limit = 0,
      startDate = null,
      endDate = null,
      byAgentId = null,
      sort = []
    } = options;

    const queryParams = {
      offset,
      limit,
      startDate,
      endDate,
      byAgentId,
      sort
    };

    const qs = Qs.stringify(queryParams);
    return this.communicator.get(`/automation/closed?${qs}`);
  };

  async getDoNotPayCarrier(options = {}) {
    const {
      offset = 0,
      limit = 0,
      startDate = null,
      endDate = null,
      byAgentId = null,
      sort = []
    } = options;

    const queryParams = {
      offset,
      limit,
      startDate,
      endDate,
      byAgentId,
      sort
    };

    const qs = Qs.stringify(queryParams);
    const { count, DoNotPayCarrier } = await this.communicator.get(`/automation/do-not-pay-carrier?${qs}`);

    return {
      doNotPayCarrier: DoNotPayCarrier,
      count
    };

  }

  //#endregion

  getFeatureFlags = async () => {
    const flags = this.communicator.get('/security/featureFlags');
    return flags;
  }

  getInvoiceMemos = async(odata = {}, explicitFilter = null) => {
    odata.filter = explicitFilter ? null : odata.filter;
    let qs = buildOdataQuery(odata);
    if(explicitFilter) {
      qs = `${qs}&$filter=${explicitFilter}`;
    }
    const result = await this.communicator.get(`/receivables/invoicememo/list${qs}`);
    return result;
  }

  getInvoiceMemoLineItems = async(odata = {}, explicitFilter = null) => {
    odata.filter = explicitFilter ? null : odata.filter;
    let qs = buildOdataQuery(odata);
    if(explicitFilter) {
      qs = `${qs}&$filter=${explicitFilter}`;
    }
    const result = await this.communicator.get(`/receivables/invoicememolineitem/list${qs}`);
    return result;
  }

  getPayments = async(odata = {}, explicitFilter = null) => {
    odata.filter = explicitFilter ? null : odata.filter;
    let qs = buildOdataQuery(odata);
    if(explicitFilter) {
      qs = `${qs}&$filter=${explicitFilter}`;
    }
    const result = await this.communicator.get(`/receivables/payment/list${qs}`);
    return result;
  }

  getPaymentApplications = async(odata = {}, explicitFilter = null) => {
    odata.filter = explicitFilter ? null : odata.filter;
    let qs = buildOdataQuery(odata);
    if(explicitFilter) {
      qs = `${qs}&$filter=${explicitFilter}`;
    }
    const result = await this.communicator.get(`/receivables/paymentApplication/list${qs}`);
    return result;
  }

  getShipmentReceivables = async(bolNumber) => {
    const result = await this.communicator.get(`receivables/shipment/${bolNumber}`);
    return result;
  }

  //#region : OpenLoadBoard, loadBoard, Dat, Truckstop integration :

  getAllOpenLoads = async (odata = {}) => {
    const qs = buildOdataQuery(odata);
    const result = await this.communicator.get(`/loadboard/listing/shipments${qs}`);
    return result;
  };

  coerceOpenLoad(load){
    const overrides = {
      pickupDate: GatewayFacade.makeDateOnly(load?.pickupDate),
      deliveryDate: GatewayFacade.makeDateOnly(load?.deliveryDate),
    };
    return Core.Utils.merge(load, overrides);
  }

  async getOpenLoad(bolNumber) {
    const result = await this.communicator.get(`/loadboard/listing/shipments/${bolNumber}`);
    return this.coerceOpenLoad(result);
  }

  async deleteAllPostingsOnShipment(bolNumber) {
    await this.communicator.delete(`/loadboard/loadposting/all/shipment/${bolNumber}`);
  }

  async saveUncoveredShipmentDetails(bolNumber, formValues) {
    const result = await this.communicator.post(`/loadboard/shipment/${bolNumber}/additionalShipmentDetails`, formValues);
    return result;
  }

  getAllDatPostings = async (odata = {}) => {
    const qs = buildOdataQuery(odata);
    const results = await this.communicator.get(`/loadboard/listing/dat${qs}`);
    return results;
  };

  getAllTruckstopPostings = async (odata = {}) => {
    const qs = buildOdataQuery(odata);
    const result = await this.communicator.get(`/loadboard/listing/truckstop${qs}`);
    return result;
  };

  async getTruckstopLookupData() {
    const result = await this.communicator.get(`/loadboard/lookupdata/truckstop`);
    return result;
  }

  async getTruckstopPosting(postingId) {
    const result = await this.communicator.get(`/loadboard/loadposting/truckstop/${postingId}`)
    return result
  }

  async saveTruckstopPosting(formData) {
    const result = await this.communicator.post('/loadboard/loadposting/truckstop', formData);
    return result;
  }

  async updateTruckstopPosting(formData) {
    const result = await this.communicator.put('/loadboard/loadposting/truckstop', formData);
    return result;
  }

  async deleteTruckstopPosting(postingId) {
    const result = await this.communicator.delete(`/loadboard/loadposting/truckstop/${postingId}`);
    return result
  }

  async getDatPostingFormData(postingId, uncoveredShipmentId) {
    const qs = uncoveredShipmentId ? `?uncoveredshipmentid=${uncoveredShipmentId}` : '';
    const result = await this.communicator.get(`/loadboard/loadposting/formdata/dat/${postingId}${qs}`);
    return result;
  }

  async getTruckstopPostingFormData(postingId, uncoveredShipmentId) {
    const qs = uncoveredShipmentId ? `?uncoveredshipmentid=${uncoveredShipmentId}` : '';
    const result = await this.communicator.get(`/loadboard/loadposting/formdata/truckstop/${postingId}${qs}`);
    return result;
  }

  async getDatPosting(postingId) {
    const result = await this.communicator.get(`/loadboard/loadposting/dat/${postingId}`);
    return result;
  };

  async getDatLookupData() {
    const result = await this.communicator.get('/loadboard/lookupdata/dat');
    return result;
  }

  async saveDatPosting(formData) {
    const result = await this.communicator.post('/loadboard/loadposting/dat', formData);
    return result;
  }

  async updateDatPosting(formData) {
    const result = await this.communicator.put('/loadboard/loadposting/dat', formData)
    return result;
  }

  async deleteDatPosting(postingId) {
    const result = await this.communicator.delete(`/loadboard/loadposting/dat/${postingId}`);
    return result
  }

  async refreshDatPosting(postingId) {
    const result = await this.communicator.post(`/loadboard/loadposting/dat/${postingId}/refresh`);
    return result
  }

  async refreshTruckstopPosting(postingId) {
    const result = await this.communicator.post(`/loadboard/loadposting/truckstop/${postingId}/refresh`);
    return result
  }

  //#endregion

  //#region : Pin Shipments

  async getShipmentPins(odata) {
    const qs = buildOdataQuery(odata);
    const result = await this.communicator.get(`/shipment-pin/list-all/${qs}`);
    return result
  }

  async isShipmentPinned(bolNumber, agentId) {
    const result = await this.communicator.get(`/shipment-pin/${bolNumber}/${agentId}`);
    return result
  }


  async setShipmentPin(bolNumber, agentId) {
    const result = await this.communicator.post(`/shipment-pin/${bolNumber}/${agentId}`);
    return result
  }

  async unsetShipmentPin(bolNumber, agentId) {
    const result = await this.communicator.delete(`/shipment-pin/${bolNumber}/${agentId}`);
    return result
  }

  //#endregion

  // region: blocked dates

  async saveBlockedDatesShipmentLocking(data) {
    const qs = Qs.stringify(data)
    const result = await this.communicator.post(`/settings/blocked-dates/shipment_locking_availability?${qs}`);
    return result;
  }

  async saveBlockedDatesArBilling(data) {
    const qs = Qs.stringify(data)
    const result = await this.communicator.post(`/settings/blocked-dates/ar_billing?${qs}`);
    return result;
  }

  async getBlockedDatesShipmentLocking(odata) {
    const qs = buildOdataQuery(odata);
    return await this.communicator.get(`/settings/blocked-dates/shipment_locking_availability${qs}`);
  }

  async getBlockedDatesArBilling(odata) {
    const qs = buildOdataQuery(odata);
    return await this.communicator.get(`/settings/blocked-dates/ar_billing${qs}`);
  }

  async updateBlockedDate(data, type) {
    const qs = Qs.stringify(data);
    return await this.communicator.post(`/settings/blocked-date/${type === 'AR_BILLING' ? 'ar_billing' : 'shipment_locking_availability'}?${qs}`);
  }

  async deleteBlockedDate(id, type) {
    const qs = Qs.stringify({id: id});
    return await this.communicator.delete(`/settings/blocked-date/${type === 'AR_BILLING' ? 'ar_billing' : 'shipment_locking_availability'}?${qs}`);
  }


  //#endregion

  // region: new financials page
  async getShipmentFinancials(bolNumber) {
    return await this.communicator.get(`/shipment/${bolNumber}/financials`);
  }

  async saveShipmentFinancials(bolNumber, data) {
    return await this.communicator.post(`/shipment/${bolNumber}/financials`, data);
  }

  //#endregion

  // #region : Multi-trans
  async getShipmentMultiTransData(bolNumber) {
    return await this.communicator.get(`/shipment/${bolNumber}/financials/multi-trans`);
  }

  async saveShipmentFinancialAdjustment(bolNumber, data) {
    const result = await this.communicator.post(`/shipment/${bolNumber}/adjustment`, data);
    return result;
  }

  async deleteShipmentFinancialAdjustment(bolNumber, shipmentAdjustmentId) {
    const result = await this.communicator.delete(`/shipment/${bolNumber}/financial/${shipmentAdjustmentId}`);
    return result;
  }

  //#endregion

  //#region : accounting
  // async getShipmentFinancialsPaymentHistory(odata) {
  //   const qs = buildOdataQuery(odata);
  //   return await this.communicator.get(`/shipment/financials/payment-history/${qs}`)
  // }
  //
  // async getShipmentFinancialsInvoiceAging(odata) {
  //   const qs = buildOdataQuery(odata);
  //   return await this.communicator.get(`/shipment/financials/invoice-aging/${qs}`)
  // }
  //
  // async getShipmentFinancialsCurrentBreakdown(bolNumber) {
  //   return await this.communicator.get(`/shipment/${bolNumber}/financials/current-breakdown`)
  // }
  //#endregion


  // region: From To History

  async getFromToHistory(odata, customerId) {
    const qs = buildOdataQuery(odata);
    return await this.communicator.get(`/company/${customerId}/from-to-history-listing${qs}`);
  }

  //#endregion

  // region: Operations View User

  async getOperationsViewUsers(odata, customerId) {
    const qs = buildOdataQuery(odata);
    return await this.communicator.get(`/company/${customerId}/from-to-history-listing${qs}`);
  }

  //#endregion

    //#endregion

    // region: Product

    async saveProduct(customerId, productDescription, internalProductNotes, nmfcCode, productClass, productId = null) {
      const result = await this.communicator.post(
            `/company/${customerId}/product-catalog/`,
            {
                productId: productId,
                productDescription: productDescription,
                internalProductNotes: internalProductNotes,
                nmfcCode: nmfcCode,
                productClass: productClass
            }
        );
        return result;
    }

    async deleteProduct(customerId, productId) {
      const result = await this.communicator.delete(
            `/company/${customerId}/product-catalog/${productId}`
        );
        return result;
    }

    async getProductCatalog(odata, customerId) {
        let  qs = buildOdataQuery(odata);
        return await this.communicator.get(`/company/${customerId}/product-catalog${qs}`);
    }

    //#endregion

  async voidShipment(bolNumber, voidReason, customerId) {
    const result = await this.communicator.post(`/shipment/${bolNumber}/void`, {voidReason:voidReason, customerId: customerId});
    return result
  }

  async restoreVoidShipment(bolNumber, customerId) {
    const result = await this.communicator.post(`/shipment/${bolNumber}/restore`, {customerId: customerId});
    return result
  }

  async getRiskReviewReport (odata, route) {
    const qs = buildOdataQuery(odata);
    return await this.communicator.get(`${route}${qs}`);
  }

  async retryLastInvoiceDistribution() {
      return await this.communicator.get(`/shipment/financials/retry-last-invoice-distribution`);
  }

    async getAutocompleteRegions(searchTerm) {
        const queryParams = {
            searchTerm: searchTerm
        }
        const qs = Qs.stringify(queryParams);
        const results = await this.communicator.get(`/public/autocomplete/regions?${qs}`);

        return results;
    }

    async getAutocompleteAddressesByZipCode(searchTerm, sessionToken) {
        const queryParams = {
            searchTerm: searchTerm,
            sessionToken: sessionToken
        }
        const qs = Qs.stringify(queryParams);
        const results = await this.communicator.get(`/public/autocomplete/zip-code?${qs}`);

        return results;
    }

    async getAddressByGooglePlaceId(placeId, sessionToken) {
        const queryParams = {
            placeId: placeId,
            sessionToken: sessionToken
        }
        const qs = Qs.stringify(queryParams);
        const result = await this.communicator.get(`/public/autocomplete/address-details?${qs}`);

        return result;
    }
}

//#region : Company Category Type Constants :

const AdminCategoryTypes = [
  'Third Party',
  'Billing Company',
  'Factoring Company'
];

const AffiliateCategoryTypes = [
  'Shipper/Consignee'
];

const CreditCategoryTypes = [
  'Customer'
];

const ShippableCategoryTypes = [
  'Customer',
  'Shipper/Consignee'
];

const DefaultCategoryTypeName = 'Shipper/Consignee';

//#endregion

//#region : Company Note Constants :

const CompanyNoteTypes = {
  Company: 'company',
  Aging: 'aging'
};

//#endregion

//#region : Payment Terms Constants :

const createPaymentTerm = (id, name, isDefault) => ({ id, name, isDefault });

const PaymentTerms = [
  createPaymentTerm(1, 'Prepay', false),
  createPaymentTerm(30, 'Net 30', true),
  createPaymentTerm(45, 'Net 45', false),
  createPaymentTerm(60, 'Net 60', false)
];

//#endregion



export default GatewayFacade;
