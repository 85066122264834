class DateUtils {
    constructor() {
    }

    // NOTE: these are all static methods. So no need to instantiate a new DateUtils.
    // Just access the methods by using DateUtils.TheMethodYouNeed()
    // NOTE: JS dates are inherintly UTC. If you pass a -string representation- of a dateTime,
    // either exclude the time completly, or make sure its an ISOString (UTC). Non UTC dateTime strings are very hard to work with.
    // NOTE for Jarett: update these all to use JSDoc


    /*** Check if JS Date is valid. returns true or false.
     * @param {Date} date - A JS Date object.
     * */
    static isValidDateObj(date) {
        if(!date) return false;
        if(date instanceof Date) {
            if (date.getTime() === date.getTime()) {
                return true;
            }
        }
        // else
        return false
    };

    /*** Check if Date string is valid. returns true or false.
     * @param {String} dateString - A string representation of a date.
     * */
    static isValidDateString(dateString) {
        if(!dateString) return false;
        const strToDateObj = new Date(dateString)
        if(strToDateObj instanceof Date) {
            if (strToDateObj.getTime() === strToDateObj.getTime()) {
                return true;
            }
        }
        return false
    };

    /*** Check if Date & Time is in the past.
     * @param {Date} dateTime - A JS Date object.
     * */
    static isPastDateTime(dateTime) {
        if (this.isValidDateObj(dateTime)) {
            return (dateTime.getTime() < new Date().getTime());
        }
        // else
        return this.#logErrorMessage();
    };

    /*** Check if a Date is in the past.
     * Does not take time into consideration.
     * @param {Date} date - A JS Date object.
     * */
    static isPastDate (date) {
        if (this.isValidDateObj(date)) {
            const now = new Date(Date.now());

            if(date.getFullYear() > now.getFullYear()) {return false;}

            if(date.getFullYear() === now.getFullYear()
                && date.getMonth() > now.getMonth()) {return false;}

            if(date.getFullYear() === now.getFullYear()
                && date.getMonth() === now.getMonth()) {
                return date.getDate() < now.getDate();
            }

            return true;
        }
        return this.#logErrorMessage();
    };

    /*** Check if a Date is same as current Date (today).
     * Does not take time into consideration.
     * @param {Date} date - A JS Date object.
     * */
    static isCurrentDate(date) {
        if(this.isValidDateObj(date)) {
            const dateClone = new Date(date);
            // Does not take time into consideration.
            const midnightDate = new Date(dateClone.setHours(0,0,0,0));
            const currentMidnightDate = new Date(new Date().setHours(0,0,0,0));

            return (midnightDate.getTime() === currentMidnightDate.getTime());
        }
        // else
        return this.#logErrorMessage();
    }

    /*** Check if 2 dates are equal. Returns true or false
     * @param {Date} date1 - A JS Date object.
     * @param {Date} date2 - A JS Date object.
     * */
    static areEqualDates (date1, date2) {
        if (this.isValidDateObj(date1) && this.isValidDateObj(date2)) {
            // Does not take time into consideration.
            const date1Clone = new Date(date1);
            const date2Clone = new Date(date2)
            const date1Midnight = new Date(date1Clone.setHours(0, 0, 0, 0));
            const date2Midnight = new Date(date2Clone.setHours(0, 0, 0, 0));
            return (date1Midnight.getTime() === date2Midnight.getTime());
        }
        // else
        return this.#logErrorMessage();
    }

    /*** Takes an array of dates (date + time), and returns the max (latest) dateTime.
     * @param {array} arrayOfDates - An array of JS dates (date + time), optionally takes date + time strings.
     * */
    static maxDateTime(arrayOfDates) {
        // remove null values, if any.
        let filteredArrayOfDates = arrayOfDates.filter(date => date !== null);
        const isValid = filteredArrayOfDates.every(date => (this.isValidDateObj(date) || this.isValidDateString(date)));
        if (isValid) {
            // in case strings are passed in, convert them to dates.
            filteredArrayOfDates = filteredArrayOfDates.map(date => new Date(date));
            return new Date(Math.max(...filteredArrayOfDates));
        }
        // else
        return this.#logErrorMessage();
    };

    /*** Takes a UTC Date Time object or UTC date Time String, and returns a date only string.
     * "2023-02-07T5:00:00Z" => "2023/02/07"
     * @param {Date} date - A UTC date object or a UTC date string.
     * */
    static convertUTCDateTimeToUTCDateOnlyString(date) {
        if(this.isValidDateObj(date) || this.isValidDateString(date)) {
            // in case date string is passed in
            const dateObj = new Date(date);
            // utc months are indexed at 0, kinda weird.
            return `${dateObj.getUTCFullYear()}-${dateObj.getUTCMonth() + 1}-${dateObj.getUTCDate()}`;
        }
        // else
        return this.#logErrorMessage();
    }
    /*** Accepts UTC Date Time objects or UTC date Time Strings, and returns true or false if date A < date B.
     * @param {Date} dateA - A UTC date object or a UTC date string.
     * @param {Date} dateB - A UTC date object or a UTC date string.
     * */
    static checkIfDateALessThanDateB(dateA, dateB) {
        // does not take time into consideration.
        // assumes passed in dateTimes are UTC.
        if(this.isValidDateString(dateA) || this.isValidDateObj(dateA) && this.isValidDateString(dateB) || this.isValidDateObj(dateB)) {
            // incase strings passed in
            const dateAClone = new Date(dateA);
            const dateBClone = new Date(dateB)
            dateAClone.setUTCHours(12,0,0,0);
            dateBClone.setUTCHours(12,0,0,0);
            return dateA.getTime() < dateB.getTime();
        }
        // else
        return this.#logErrorMessage();
    }

    /*** WARNING (for client side use ONLY!). Returns a UTC dateTime object set for noon (12pm UTC)
     * */
    static getCurrentDateNoonUTC () {
        let date = new Date();
        const noonUTCDate = new Date(date.toLocaleDateString() + "Z");
        noonUTCDate.setUTCHours(12,0,0,0);
        return noonUTCDate;
    }

    static getFirstDayOfCurrentMonth() {
        let date = new Date();
        date = new Date(date.getUTCFullYear(), date.getUTCMonth(), 1);
        return date;
    }

    static getLastDayOfCurrentMonth() {
        let date = new Date();
        date = new Date(date.getUTCFullYear(), date.getUTCMonth() + 1, 0);
        return date;
    }

    /*** If passed date object or date string, returns new Date(date). else return null.
     * @param {Any} date - Any.
     * */
    static dateOrNull(date) {
        if(this.isValidDateObj(date) || this.isValidDateString(date)) {
            // if valid date
            return new Date(date);
        }
        // else
        return null
    }

    static #logErrorMessage(message = "Error: Invalid Date") {
        return console.error(message);
    }

    static getDisplayDateFromDateString(bolDateString) {
        if(!bolDateString) return;
        let date = new Date(bolDateString);
        date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());
        return new Intl.DateTimeFormat('en-US').format(date);
    }

    static getDisplayDateTimeFromDateTime(dateTime) {
        if(!dateTime) return;
        let date = new Date(dateTime);
        return new Intl.DateTimeFormat('UTC', {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        }).format(date);
    }

    static getLatestDate(dates) {
        if(!dates) return;

        // Convert each date string to a Date object and get the latest date
        let latestDate = this.dateOrNull(new Date(Math.max(...dates.map(date => new Date(date).getTime()))));
        return latestDate;
    }

    static getLatestDateTime(dateTimes) {
        if(!dateTimes) return;

        // Convert each date string to a Date object and get the latest date
        let latestDateTime = this.dateOrNull(new Date(Math.max(...dateTimes.map(date => new Date(date).getTime()))));
        return latestDateTime ? this.getDisplayDateTimeFromDateTime(latestDateTime) : null;
    }

    static getDisplayTimeFromMilitaryTimeString(militaryTimeStr) {
        const [hours, minutes] = militaryTimeStr.split(':').map(Number);
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0);
        date.setMilliseconds(0);

        const formatter = new Intl.DateTimeFormat('UTC', {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        });

        return formatter.format(date);
    }

}

export default DateUtils;