
import ActionTypes from "../../../ActionTypes";
import ActionBuilder from "../../../ActionBuilder2";

const grantCustomerPortalAccess = async (shell, companyId, contactId) => {

    // eslint-disable-next-line no-unused-vars
    const result = await shell.gateway.grantCustomerPortalAccess(companyId, contactId);
    return {
        type: ActionTypes.AddressBook.Modification.GrantCustomerPortalAccess,
        result: true
    };

};

export default ActionBuilder
    .for(grantCustomerPortalAccess)
    .build();