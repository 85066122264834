import AppMuiCard from "../../../../../core/components/cards/AppCard";
import React, {useState} from "react";
import {Grid} from "@material-ui/core";
import DateUtils from "../../../../../core/utils/dateUtils/dateUtils";
import {SendToDetailsPageModal} from "../SendToDetailsPageModal/SendToDetailsPageModal";


export const RefNumberInfo = (props) => {

    const {
        ltlTracking,
        ltlShipmentDetails,
        shipment,
    } = props.data;

    const [sendToDetailsPageModalOpen, setSendToDetailsPageModalOpen] = useState(false);
    const [sendToDetailsPageModalProps, setsendToDetailsPageModalProps] = useState({});

    const formattedValues = {
        createdOnDate: DateUtils.getDisplayDateFromDateString(ltlShipmentDetails?.createdDate),
        gtzBolNumber: ltlShipmentDetails?.thirdPartyBolNumber,
        customerReferenceNumber: ltlTracking?.ShipmentStatus?.ReferenceNo,
        carrierPickupNumber: ltlTracking?.ShipmentStatus?.CarrierPickupNumber,
        originPickupNumber: ltlTracking?.ShipmentStatus?.PoNo,
        carrierProNumber: ltlTracking?.ShipmentStatus?.PRONo
    }

    const handleSendToDetailsPageClick = (type) => {
        setsendToDetailsPageModalProps({
            type: type,
            shipment: shipment,
            ltlCarrierPickupNumber: formattedValues.carrierPickupNumber,
            proNumber: formattedValues.carrierProNumber
        });
        setSendToDetailsPageModalOpen(true);
    }

    let shipmentCanBeChanged = true;
    if(shipment) {
        shipmentCanBeChanged = (
            [1, 6, 17, 19].includes(shipment.statusId)
            &&
            shipment.lockedDateTime === null
            &&
            !shipment.hasOwnProperty('voidId')
        )
    }

    return (
        <AppMuiCard title={"Reference Number Information"}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    Created on: <strong>{formattedValues?.createdOnDate}</strong>
                </Grid>
                <Grid item xs={12}>
                    GTZ #: <strong>{formattedValues?.gtzBolNumber}</strong>
                </Grid>
                <Grid item xs={12}>
                    Customer Ref # / TGF Ship #: <strong>{formattedValues?.customerReferenceNumber}</strong>
                </Grid>
                <Grid item xs={12} className={"ltl-autotrack_ref-number-card_send-to-details-div"}>
                    <spam>Carrier Pickup #: <strong>{formattedValues?.carrierPickupNumber}</strong></spam>
                    {(shipmentCanBeChanged && formattedValues.carrierPickupNumber) &&
                        <span className={"ltl-autotrack_ref-number-card_send-to-details-btn"} onClick={() => handleSendToDetailsPageClick("ltlCarrierPickupNumber")}>Send to Details Page</span>
                    }
                </Grid>
                <Grid item xs={12}>
                    Origin P/U #: <strong>{formattedValues?.originPickupNumber}</strong>
                </Grid>
                <Grid item xs={12} className={"ltl-autotrack_ref-number-card_send-to-details-div"}>
                    <span>Carrier Pro #: <strong>{formattedValues?.carrierProNumber}</strong></span>
                    {(shipmentCanBeChanged && formattedValues.carrierProNumber) &&
                        <span className={"ltl-autotrack_ref-number-card_send-to-details-btn"}
                              onClick={() => handleSendToDetailsPageClick("proNumber")}>Send to Details Page</span>
                    }
                </Grid>
                {sendToDetailsPageModalOpen === true &&
                    <SendToDetailsPageModal
                        onClose={() => setSendToDetailsPageModalOpen(false)}
                        {...sendToDetailsPageModalProps}
                    />
                }
            </Grid>
        </AppMuiCard>
    )
}
