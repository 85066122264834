import React from 'react';
import FullWidthLayout from "../../core/layouts/FullWidthLayout";
import {usePageTitle} from "../../crm/components/customHooks/misc/usePageTitle";
import LtlQuoteNav from "./includes/Nav/LtlQouteNav";

const LtlQuote = (props) => {
    usePageTitle("TGF: LTL Quote");

    return (
        <FullWidthLayout SideNav={LtlQuoteNav} title="LTL Quote">
        </FullWidthLayout>
    )
};

export default LtlQuote
