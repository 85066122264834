//#region Imports

import {
    Home,
    People
} from '@material-ui/icons';
import { useEffect, useState } from 'react';

import { useSelector } from "react-redux";
import ComponentBuilder from '../../../../core/ComponentBuilder';
import AppSideBar from '../../../../core/components/AppSideBar';

//#endregion


//#region Links

const topAdminNavItem = { title: 'Agency Admin', icon: Home, key: 'admin', href: '/admin/agency' };

const baseLinks = [
    { title: 'Manage Users', icon: People, key: 'placeholder-1', href: '/admin/agency/manage-users' },

];

//#endregion

const AgencyAdminLeftNav = (props) => {

    const state = useSelector(state => state);
    const canEditBlockedDates = state.persona.agent.canEditBlockedDates;
    const [open, setOpen] = useState(true);

    const handleDrawerOpen = () => setOpen(true);

    const handleDrawerClose = () => setOpen(false);

    const findAdditionalLinks = (featureFlags) => {
        const links = [...baseLinks];
        links.sort((a, b) => a.title.localeCompare(b.title));
        links.unshift(topAdminNavItem);
        return links;
    };

    const [menuItems, setMenuItems] = useState([]);

    useEffect(() => {
        async function getFeatureState() {
            const featureFlags = await props.getFeatureState();
            const links = findAdditionalLinks(featureFlags);
            setMenuItems(links);
        };
        getFeatureState();

    }, []);

    //#endregion

    // const getFeatureFlags = async () => await shell.gateway.communicator.get('/security/featureFlags');

    return (
        <AppSideBar menuList={menuItems} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose} open={open} />
    );
};

export default ComponentBuilder
    .wrap(AgencyAdminLeftNav)
    .stateToProps((state, ownProps) => ({
    }))
    .dispatchToProps((shell, dispatch, getState) => {
        return {
            async getFeatureState() {
                return await shell.gateway.communicator.get('/security/featureFlags');
            }
        };
    })
    .build();
