import React, {useState} from 'react';
import {DateRange, PersonAddDisabled} from '@material-ui/icons';

import AppSideBar from '../../../../core/components/AppSideBar';

const ReportUrl = '/ltl-quote';

const BaseLinks = [
    {title: 'Customer Quote/Book', icon: DateRange, key: 'customer-ltl-quote', href: `${ReportUrl}/customer`},
    {title: 'Quote Only', icon: PersonAddDisabled, key: 'quote-only', href: `${ReportUrl}/quote-only`},
];

const LtlQuoteNav = (props) => {
    const [links, setLinks] = useState(BaseLinks);

    const [open, setOpen] = useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <AppSideBar menuList={links || []} handleDrawerOpen={handleDrawerOpen} handleDrawerClose={handleDrawerClose}
                    open={open}/>
    );
};

export default LtlQuoteNav
