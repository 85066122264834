import Core from '@atomos/core';

const FullNames = [
  // ADDRESS BOOK
  'AddressBook.Entries.IdentifiedCompany',
  'AddressBook.Entries.ClearedCompany',
  'AddressBook.Listing.Loaded',
  'AddressBook.Listing.Disposed',
  'AddressBook.Listing.LoadCompanyDocuments',
  'AddressBook.Modification.DetailsLoaded',
  'AddressBook.Modification.CompanyLoaded',
  'AddressBook.Modification.CompanyAffiliateLoaded',
  'AddressBook.Modification.CompanyContactsLoaded',
  'AddressBook.Modification.CompanyContactSaved',
  'AddressBook.Modification.CompanyContactDeleted',
  'AddressBook.Modification.CompanyCreditStatusLoaded',
  'AddressBook.Modification.CompanyCreditLimitChangesLoaded',
  'AddressBook.Modification.CompanyDisablementHistoryLoaded',
  'AddressBook.Modification.CompanyDisabled',
  'AddressBook.Modification.CompanyFirstShipmentLoaded',
  'AddressBook.Modification.CompanyNotesLoaded',
  'AddressBook.Modification.CompanyAgingNotesLoaded',
  'AddressBook.Modification.CompanyAgingNoteSaved',
  'AddressBook.Modification.CompanyNoteSaved',
  'AddressBook.Modification.CompanySaved',
  'AddressBook.Modification.CompanyDeleteDocument',
  'AddressBook.Modification.CompanyPublishDocument',
  'AddressBook.Modification.CompanyInvoiceSettingsLoaded',
  'AddressBook.Modification.CompanyInvoiceSettingsSaved',
  'AddressBook.Modification.CompanyFinancialLoaded',
  'AddressBook.Modification.CompanyFinancialHealthSummaryLoaded',
  'AddressBook.Modification.CompanyFinancialSaved',
  'AddressBook.Modification.Disposed',
  'AddressBook.Modification.CompanyAutofillFieldsLoaded',
  'AddressBook.Modification.CompanyAutofillFieldsSaved',
    'AddressBook.Modification.GrantCustomerPortalAccess',
    'AddressBook.Modification.RevokeCustomerPortalAccess',
  'AddressBook.Payment.Loaded',
  'AddressBook.Searching.SearchedCustomers',
  'AddressBook.Searching.CustomersSearchCleared',
  'AddressBook.Searching.SearchedCompanies',
  'AddressBook.Searching.CompaniesSearchCleared',
  'AddressBook.TallgrassBillingCompany.Loaded',
  'AddressBook.TallgrassBillingCompany.Disposed',

  // ADMIN
  'Admin.ManageUsers.ListingLoaded',
  'Admin.ManageUsers.Disposed',
  'Admin.ManageUsers.AssociateLoaded',
  'Admin.ManageUsers.AssociateJacksLoaded',
  'Admin.ManageUsers.AssociateNotesLoaded',
  'Admin.ManageUsers.RolesLoaded',
  'Admin.ManageUsers.AssociateSaved',
  'Admin.ManageUsers.Associate.SaveNote',
  'Admin.ManageUsers.AssociateNoteSaved',
  'Admin.ManageUsers.AssociateNoteTypesLoaded',
  //'Admin.ManageUsers.VerifyLoadBoardCredentials',
  'Admin.CommissionSummary.PaymentsLoaded',
  'Admin.CommissionSummary.AssociatesLoaded',
  'Admin.CommissionSummary.Disposed',

  // CARRIER DIRECTORY
  'Carrier.Pending.Loaded',
  'Carrier.Pending.Disposed',
  'Carrier.Pending.Deleted',
  'Carrier.Pending.Saved',
  'Carrier.Listing.Loaded',
  'Carrier.Listing.Disposed',
  'Carrier.LoadBoard.LoadCarrierDocuments',
  'Carrier.Modification.CarrierPublishDocument',
  'Carrier.Modification.CarrierDeleteDocument',
  'Carrier.Modification.CarrierLoaded',
  'Carrier.Modification.CarrierSaved',
  'Carrier.Modification.Disposed',
  'Carrier.Modification.CarrierHistory.Loaded',
  'Carrier.Modification.CarrierHistory.Disposed',
  'Carrier.Modification.CarrierNotesLoaded',
  'Carrier.Modification.CarrierNoteSaved',
  'Carrier.Modification.CarrierCompliance.Loaded',
  'Carrier.Modification.RmisCarrierContacts.Loaded',
  'Carrier.Modification.RmisCarrierLoaded',
  'CarrierDirectory.Entries.IdentifiedCarrier',
  'CarrierDirectory.Entries.ClearedCarrier',
  'CarrierDirectory.Searching.SearchedCarriers',
  'CarrierDirectory.Searching.CarriersSearchCleared',

  // DASHBOARD
  'Dashboard.Disposed',
  'Dashboard.ListingLoaded',
  'Dashboard.StatusCountLoaded',
  'Dashboard.MarkedInvoicesGenerated',
  'Dashboard.MarkedInvoicesSent',
  'Dashboard.ShipmentInvoiceSaved',

  // PERSONA
  'Persona.Initialized',
  'Persona.AllAssociatesLoaded',
  'Persona.BrokerAssociatesLoaded',
  'Persona.LoadedAnnouncementNote',
  'Persona.SavedAnnouncementNote',
  'Persona.LoadedDeliveredCount', // TODO: REMOVE
  'Persona.LoadedTgfMonthlyMetrics',
  'Persona.LoadedAssociateMonthlyMetrics',
  'Persona.Authenticated',
  'Persona.Invalidated',
  'Persona.Identified',
  'Persona.ytdMarginGoals',
  'Persona.NotIdentified',
  'Persona.Mrv.VisitationsCleared',
  'Persona.Mrv.VisitationsLoaded',
  'Persona.Mrv.RecentlyVisited',
  'Persona.RolesLoaded',
  'Persona.Session.Expired',

  // COMMISSION
  'CommissionCenter.CommissionAdjustmentDeleted',
  'CommissionCenter.CommissionAdjustmentSaved',
  'CommissionCenter.CommissionRateLoaded',
  'CommissionCenter.CommissionRateSaved',
  'CommissionCenter.PayPeriodCommissionAdjustmentLoaded',
  'CommissionCenter.PayPeriodShipmentLoaded',
  'CommissionCenter.PayStatementLoaded',
  'CommissionCenter.FinalizedStatement',
  'CommissionCenter.StatementLoaded',
  'CommissionCenter.ShipmentInvoiceSaved',
  'CommissionCenter.Disposed',

  // REPORTS
  'Reporting.LaneLookup.Loaded',
  'Reporting.LaneLookup.Disposed',
  'Reporting.InvoiceAging.Loaded',
  'Reporting.InvoiceAging.Disposed',
  'Reporting.CustomerReport.Loaded',
  'Reporting.CustomerReport.GrossMarginBreakdownLoaded',
  'Reporting.CustomerReport.Disposed',
  'Reporting.SearchLoads',
  'Reporting.SearchLoads.Loaded',
  'Reporting.SearchLoads.Disposed',
  'Reporting.CustomerPaymentShipmentsLoaded',
  'Reporting.MarkedShipmentsCustomerPaid',
  'Reporting.CustomerPaymentsDisposed',
  'Reporting.MonthlyGrossMargin.ShipmentsLoaded',
  'Reporting.MonthlyGrossMargin.Disposed',
  'Reporting.MonthlyGrossMargin.AllCommissionRateLoaded',
  'Reporting.MonthlyGrossMargin.MonthlyCommissionRateLoaded',
  'Reporting.MonthlyGrossMargin.MonthlyCommissionBreakdownLoaded',
  'Reporting.MonthlyGrossMargin.MonthlyCommissionAdjustmentLoaded',
  'Reporting.MonthlyGrossMargin.GrossMarginLoaded',
  'Reporting.MissingPrimaryContacts.Loaded',
  'Reporting.MissingPrimaryContacts.Disposed',
  'Reporting.MissingCreditApp.Disposed',
  'Reporting.MissingCreditApp.Loaded',
  'Reporting.StandardReports.Loaded',

  // SHIPMENT
  'Shipment.LoadTrackingNotes.Loaded',
  'Shipment.SaveTrackingNote.Saved',
  'Shipment.LoadTrackingNotes.Disposed',
  'Shipment.CreateDocs.ShipmentLoaded',
  'Shipment.CreateDocs.Disposed',
  'Shipment.Customer.SearchedCustomerShipments',
  'Shipment.Customer.ClearedCustomerShipmentsSearch',
  'Shipment.Customer.RetrievedCustomerFirstShipment',
  'Shipment.Customer.ClearedCustomerFirstShipment',
  'Shipment.Duplication.ShipmentLoaded',
  'Shipment.Duplication.ShipmentDuplicated',
  'Shipment.Duplication.Disposed',
  'Shipment.LoadBoard.Loaded',
  'Shipment.LoadBoard.Disposed',
  'Shipment.LoadBoard.LoadShipmentDocuments',
  'Shipment.Modification.ShipmentPublishDocument',
  'Shipment.Modification.shipmentCarrierContactLoaded',
  'Shipment.Modification.ShipmentCarrierContactSaved',
  'Shipment.Modification.ShipmentCompanyLoaded',
  'Shipment.Modification.ShipmentContentsLoaded',
  'Shipment.Modification.ShipmentContentsSaved',
  'Shipment.Modification.ShipmentContentsDeleted',
  'Shipment.Modification.ShipmentContentsDisposed',
  'Shipment.Modification.ShipmentDeleteDocument',
  'Shipment.Modification.ShipmentLockedAssociateLoaded',
  'Shipment.Modification.ShipmentTruckLoadConfirmationLoaded',
  'Shipment.Modification.ShipmentTruckLoadConfirmationSaved',
  'Shipment.Modification.ShipmentTruckLoadConfirmationDeleted',
  'Shipment.Modification.ShipmentMultipleStopsLoaded',
  'Shipment.Modification.ShipmentMultipleStopsSaved',
  'Shipment.Modification.ShipmentMultipleStopsReset',
  'Shipment.Modification.ShipmentInvoiceAuditNotesLoaded',
  'Shipment.Modification.ShipmentInvoiceAuditNoteSaved',
  'Shipment.Modification.ShipmentLoaded',
  'Shipment.Modification.ShipmentSaved',
  'Shipment.Modification.InvoiceLoaded',
  'Shipment.Modification.InvoiceSaved',
  'Shipment.Modification.Loaded',
  'Shipment.Modification.Disposed',
  'Shipment.Modification.NewShipmentLoaded',
  'Shipment.Uncovered.Loaded',
  'Shipment.Uncovered.Saved',
  'Shipment.Uncovered.Disposed',
  'Shipment.TrackedSetCountsLoaded',

  // SUPPORT
  'Support.CompanyDocumentTypes',
  'Support.CarrierDocumentTypes',
  'Support.ShipmentDocumentTypes',
  'Support.CompanyCategoryTypesLoaded',
  'Support.CompanyInvoiceDeliveryMethodTypesLoaded',
  'Support.FreightCategoryTypesLoaded',
  'Support.PaymentTermsLoaded',
  'Support.ShipmentTrackingPhasesLoaded',
  'Support.StateProvinces.Loaded',
  'Support.ShipmentPackageTypesLoaded',
  'Support.ShipmentFreightClassesLoaded',
  'Support.ShipmentTruckloadValueConfirmationTypeLoaded',
  'Support.ShipmentStatusTypesLoaded',
  'Support.ShipmentEquipmentTypesLoaded',
  'Support.ShipmentHazardousStatesLoaded',
  'Support.LoadHelpDocuments',
  'Support.Disposed',
  'Support.HelpDeleteDocument',
  'Support.HelpPublishDocument',

  // SYSTEM
  'Sys.ProcessStarting',
  'Sys.ProcessComplete',
  'Sys.UncaughtActionError',
  'Sys.ReceiveSnackbarMessage',
  'Sys.ClearSnackbarMessage',

  // AUTOMATION
  'Automation.RetrieveQaReview.Loaded',
  'Automation.RetrieveQaReview.Disposed',
  'Automation.QaRejected',
  'Automation.QaAcepted',
  'Automation.RetrieveQaFailed.Loaded',
  'Automation.QaFailed',
  'Automation.RetrieveAutoProcessed.Loaded',
  'Automation.ListAutomationDocuments',
  'Automation.RetrieveClosed.Loaded',
  'Automation.DoNotPayCarrier.Loaded',
];

const ActionTypes = {};

FullNames.forEach(name => Core.Utils.set(ActionTypes, name, name));

export default ActionTypes;