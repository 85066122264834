import AppMuiCard from "../../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import React from "react";
import LabelOrLink from "../../../../../../crm/components/cardHelperComponents/LabelOrLink";
import CrmTypedAddressBookListingComboBox from "../../../../../../crm/components/CrmTypedAddressBookListingComboBox";
import {Label} from "@progress/kendo-react-labels";
import TgfTextBox from "../../../../../../core/kendo/form/inputs/TgfTextBox";
import TgfPhoneNumberInput from "../../../../../../core/kendo/form/inputs/TgfPhoneNumberInput";
import TgfStateProvinceComboBox from "../../../../../../core/kendo/form/inputs/specialty/TgfStateProvinceComboBox";
import FieldError from "../../../../../../crm/components/inputs/FieldError";
import TgfTimeInput from "../../../../../../core/kendo/form/inputs/TgfTimeInput";
import InfoIconTooltip from "../../../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";


export const ConsigneeDetailsCard = (props) => {

    const {
        formValues,
        setFormValues,
        setIsDirty,
        fieldErrors,
        disableAll = false,
        filterShipperConsignee,
    } = props;

    const applyConsigneeFormValue = (name, value) => {
        setFormValues({
            ...formValues,
            consignee: {
                ...formValues.consignee,
                [name]: value,
            },
        });
        setIsDirty(true);
    };

    const handleChange = (e) => {
        applyConsigneeFormValue(e.target.name, e.target.value);
    };

    const handleReadyTimeChange = (e) => {
        setFormValues({
            ...formValues,
            consignee: {
                ...formValues.consignee,
                readyTime: e.target.value,
                closeTime: null
            },
        });
        setIsDirty(true);
    };

    const handleConsigneeChange = (consignee) => {
        setFormValues({
            ...formValues,
            consignee: {
                ...formValues.consignee,
                ...consignee
            }
        });
    }

    const isReadOnly = formValues.consignee?.selectedFromFirstPage;

    const getCloseTimeHoursMin = (readyTime) => {
        let minHours = new Date(2000, 2, 10, 0, 0);
        if(readyTime) {
            minHours = new Date(readyTime.getTime());
            minHours.setHours(minHours.getHours() + 2);
        }
        return minHours;
    }

    const cardTitle = (
        <div className={"card-title"}>
            <span>Consignee Details</span>
            <InfoIconTooltip title={
                <>
                    <p>Having and entering accurate Consignee Details is essential to avoiding delivery and shipment issues. </p>
                    <p>Remember to double check all involved information for accuracy.</p>
                </>
            }/>
        </div>
    )
    
    return (
        <AppMuiCard title={cardTitle}>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <LabelOrLink labelText={"Selected Consignee"} selected={formValues?.consignee} styles={{color: '#28a745'}}/>
                    <CrmTypedAddressBookListingComboBox
                        style={{width: '100%'}}
                        types={[CrmTypedAddressBookListingComboBox.ShipperConsignee]}
                        value={formValues.consignee.companyId ? formValues.consignee : null}
                        onChange={handleConsigneeChange}
                        filterMap={filterShipperConsignee}
                        placeholder={"Search..."}
                        popupIcon={' '}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TgfTextBox
                        label={"Company Name"}
                        id={"companyName"}
                        name={"companyName"}
                        value={formValues.consignee?.companyName}
                        onChange={handleChange}
                        disabled={isReadOnly || disableAll}
                    />
                    <FieldError>{fieldErrors?.consignee?.companyName}</FieldError>
                </Grid>
                <Grid item xs={12}>
                    <TgfTextBox
                        label={"Address 1"}
                        id={"companyAddress1"}
                        name={"companyAddress1"}
                        value={formValues.consignee?.companyAddress1}
                        onChange={handleChange}
                        disabled={isReadOnly || disableAll}
                    />
                    <FieldError>{fieldErrors?.consignee?.companyAddress1}</FieldError>
                </Grid>
                <Grid item xs={12}>
                    <TgfTextBox
                        label={"Address 2"}
                        id={"companyAddress2"}
                        name={"companyAddress2"}
                        value={formValues.consignee?.companyAddress2}
                        onChange={handleChange}
                        disabled={isReadOnly || disableAll}
                    />
                    <FieldError>{fieldErrors?.consignee?.companyAddress2}</FieldError>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TgfTextBox
                        label={"City"}
                        id={"companyCity"}
                        name={"companyCity"}
                        value={formValues.consignee?.companyCity}
                        onChange={handleChange}
                        disabled={isReadOnly || disableAll}
                    />
                    <FieldError>{fieldErrors?.consignee?.companyCity}</FieldError>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TgfStateProvinceComboBox
                        label={"State/Province"}
                        id={"companyStateProvince"}
                        name={"companyStateProvince"}
                        value={formValues.consignee?.companyStateProvince}
                        onChange={handleChange}
                        disabled={isReadOnly || disableAll}
                    />
                    <FieldError>{fieldErrors?.consignee?.companyStateProvince}</FieldError>
                </Grid>
                <Grid item xs={12} md={3}>
                    <TgfTextBox
                        label={"Zip/Postal Code"}
                        id={"companyPostalCode"}
                        name={"companyPostalCode"}
                        onChange={handleChange}
                        value={formValues.consignee?.companyPostalCode}
                        format={"c2"}
                        disabled={true}
                    />
                    <FieldError>{fieldErrors?.consignee?.companyPostalCode}</FieldError>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TgfTextBox
                        label={"Contact Name"}
                        id={"primaryContactFirstName"}
                        name={"primaryContactFirstName"}
                        value={formValues.consignee?.primaryContactFirstName}
                        onChange={handleChange}
                        disabled={isReadOnly || disableAll}
                        max={30}
                    />
                    <FieldError>{fieldErrors?.consignee?.primaryContactFirstName}</FieldError>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Label>
                        Phone
                    </Label>
                    <TgfPhoneNumberInput
                        id={"companyBusinessPhone"}
                        name={"companyBusinessPhone"}
                        value={formValues.consignee?.companyBusinessPhone}
                        onChange={handleChange}
                        disabled={isReadOnly || disableAll}
                    />
                    <FieldError>{fieldErrors?.consignee?.companyBusinessPhone}</FieldError>
                </Grid>
                <Grid item xs={12} md={4}></Grid>
                <Grid item xs={12} md={4}>
                    <Label>
                        Ready Time
                    </Label>
                    <InfoIconTooltip
                        title={`The Consignee's Ready Time must be between 6 am and 7 pm.`}
                    />
                    <TgfTimeInput
                        id={"readyTime"}
                        name={"readyTime"}
                        value={formValues.consignee?.readyTime}
                        onChange={handleReadyTimeChange}
                        disabled={disableAll}
                        min={new Date(new Date().setHours(6, 0, 0, 0))}
                        max={new Date(new Date().setHours(19, 0, 0, 0))}
                        steps={{
                            hour: 1,
                            minute: 30,
                        }}
                    />
                    <FieldError>{fieldErrors?.consignee?.readyTime}</FieldError>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Label>
                        Close Time
                    </Label>
                    <InfoIconTooltip
                        title={
                        <>
                            <p>The Consignee's Close Time must be between 8 am and 9 pm.</p>
                            <p>The Consignee's Close Time must be a minimum of two hours after the selected Ready Time.</p>
                            <p>Please remember that if you are booking a guaranteed shipment, the close time showing on the Bill of Lading must be applicable to the chosen guaranteed service.</p>
                        </>
                    }
                    />
                    <TgfTimeInput
                        id={"closeTime"}
                        name={"closeTime"}
                        value={formValues.consignee?.closeTime}
                        onChange={handleChange}
                        min={getCloseTimeHoursMin(formValues?.consignee?.readyTime)}
                        max={new Date(new Date().setHours(21, 0, 0, 0))}
                        disabled={disableAll || !formValues?.consignee?.readyTime}
                        steps={{
                            hour: 1,
                            minute: 30,
                        }}
                    />
                    <FieldError>{fieldErrors?.consignee?.closeTime}</FieldError>
                </Grid>
            </Grid>
        </AppMuiCard>
    );
};