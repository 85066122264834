import React, {useEffect} from "react";
import {Button, ButtonGroup} from "@progress/kendo-react-buttons";
import {Label} from "@progress/kendo-react-labels";
import "../tgf-form.scss";

export const TgfButtonGroupInput = (props) => {
    const handleChange = (dataItem) => {
        let updatedValue;
        if (props.isMultiSelect) {
            updatedValue = props.value.includes(dataItem.id)
                ? props.value.filter(val => val !== dataItem.id)
                : [...props.value, dataItem.id];
        } else {
            updatedValue = dataItem.id;
        }

        props.onChange({ target: { value: updatedValue, name: props.name } });
    }

    useEffect(() => {
        if(props.defaultValue) {
            handleChange(props.defaultValue);
        }
    }, []);

    return (
        <>
            {props.label && <Label editorId={props.id}>{props.label}</Label>}
            <ButtonGroup className={"tgf-button-group"}>
                {props.data.map((dataItem, idx) => {
                    return (
                        <Button
                            onClick={() => handleChange(dataItem)}
                            selected={Array.isArray(props.value) ? props.value.includes(dataItem.id) : props.value === dataItem.id}
                            togglable={true}
                            disabled={props.disabled}
                            key={idx}
                        >
                            {dataItem.display}
                        </Button>
                    )
                })}
            </ButtonGroup>
        </>
    )
}

