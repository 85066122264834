import AppMuiCard from "../../../../../core/components/cards/AppCard";
import React from "react";
import {Grid} from "@material-ui/core";


export const ConsigneeInfo = (props) => {

    const {
        ltlShipmentDetails
    } = props.data;

    const renderHours = () => {
        return (
            <span>Hrs: <strong>{ltlShipmentDetails?.shipmentDelHours}</strong></span>
        )
    }

    return (
        <AppMuiCard title={"Consignee"}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    Name: <strong><span style={{color: "red"}}>{ltlShipmentDetails?.destination?.businessName}</span></strong>
                </Grid>
                <Grid item xs={12}>
                    Address 1: <strong>{ltlShipmentDetails?.destination?.address1}</strong>
                </Grid>
                <Grid item xs={12}>
                    Address 2: <strong>{ltlShipmentDetails?.destination?.address2}</strong>
                </Grid>
                <Grid item xs={5}>
                    City: <strong>{ltlShipmentDetails?.destination?.city}</strong>
                </Grid>
                <Grid item xs={2}>
                    State: <strong>{ltlShipmentDetails?.destination?.state}</strong>
                </Grid>
                <Grid item xs={5}>
                    Zip/Postal: <strong>{ltlShipmentDetails?.destination?.postalCode}</strong>
                </Grid>
                <Grid item xs={12}>
                    Contact Name: <strong>{ltlShipmentDetails?.destination?.primaryContactName}</strong>
                </Grid>
                <Grid item xs={7}>
                    Contact Phone: <strong>{ltlShipmentDetails?.destination?.primaryContactPhone}</strong>
                </Grid>
                <Grid item xs={5}>
                    {renderHours()}
                </Grid>
            </Grid>
        </AppMuiCard>
    )
}
