import React from 'react';
import {usePageTitle} from "../../../crm/components/customHooks/misc/usePageTitle";
import {QuoteWizard} from "../includes/Components/QuoteWIzard/QuoteWizard";

const QuoteOnly = (props) => {
    usePageTitle("TGF: Quote Only");
    return (
        <QuoteWizard type={"quoteOnly"} />
    )
};

export default QuoteOnly
