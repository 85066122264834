import React from 'react';
import {
    AccessTime,
    ArtTrack,
    ContactMail,
    Info,
    ListAlt,
    Notes,
    Receipt,
    AccountBalance,
    CompareArrows
} from '@material-ui/icons';
import CategoryIcon from '@material-ui/icons/Category';
import ComponentBuilder from '../../core/ComponentBuilder';
import AppSideBar from '../../core/components/AppSideBar';

const BaseLinks = (companyId) => [
    {title: 'Details', icon: ArtTrack, key: 'details', href: `/address-book/${companyId}/details`}
];

const AdditionalLinks = (companyId) => [
    {title: 'Contacts', icon: ContactMail, key: 'contacts', href: `/address-book/${companyId}/contacts`},
    {title: 'Notes', icon: Notes, key: 'notes', href: `/address-book/${companyId}/notes`},
    {title: 'Documents', icon: ListAlt, key: 'documents', href: `/address-book/${companyId}/documents`}
];

const CustomerLinks = (companyId) => {

    const links = [
        {title: 'Product Catalog', icon: CategoryIcon, key: 'products', href: `/address-book/${companyId}/products`},
        /*{title: 'Legacy Billing', icon: Receipt, key: 'billing', href: `/address-book/${companyId}/billing`},*/
        {title: 'Billing', icon: Receipt, key: 'billing-new', href: `/address-book/${companyId}/billing-new`},
        {
            title: 'Financial Health',
            icon: AccountBalance,
            key: 'financial-health',
            href: `/address-book/${companyId}/financial-health`
        },
        {
            title: 'Shipment History',
            icon: Info,
            key: 'shipment-history',
            href: `/address-book/${companyId}/shipment-history`
        },
        {
            title: 'Aging Shipments',
            icon: AccessTime,
            key: 'aging-shipment-history',
            href: `/address-book/${companyId}/aging-shipment-history`
        },
        {
            title: 'Notes Autofill',
            icon: Notes,
            key: 'notes-autofill',
            href: `/address-book/${companyId}/notes-autofill`
        }
    ];

    if (localStorage.getItem('tgf-crm-company-outcome-stream-privilege')) {
        links.push({
            title: 'Outcome Stream',
            icon: Notes,
            key: 'outcome-stream',
            href: `/address-book/${companyId}/outcome-stream`
        });
    }

    return links;
};

const Wrapper = (props) => {
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (<AppSideBar menuList={props.links || []} handleDrawerOpen={handleDrawerOpen}
                        handleDrawerClose={handleDrawerClose} open={open}/>);
};

export default ComponentBuilder
    .wrap(Wrapper)
    .stateToProps((state, ownProps) => {
        const userHasCollectionRole = state?.persona?.associate?.collectionRole;

        const company = state.addressBook.modification.company;
        const customerCategoryType = state.support.customerCategoryType;
        const shipperConsigneeCategoryType = state.support.shipperConsigneeCategoryType;

        // No links if there is no company in memory.
        if (!company || !customerCategoryType) {
            return {links: []};
        }

        // New companies loaded into the state tree should have
        // a defaulted id of 0.
        const isCustomer = company.categoryTypeId === customerCategoryType.id;

        // Check if current company is a shipper/cosignee with an id of 2.
        const isShipperCosignee = company.categoryTypeId === shipperConsigneeCategoryType.id;

        let links = BaseLinks(company.id).concat(AdditionalLinks(company.id));

        if (isCustomer || isShipperCosignee) links.push({
            title: 'From/To History',
            icon: CompareArrows,
            key: 'from-to-history',
            href: `/address-book/${company.id}/from-to-history`
        });

        if (isCustomer) {
            links = links.concat(CustomerLinks(company.id));
        }

        if (isCustomer && userHasCollectionRole) links.push({
            title: 'Collections',
            icon: ContactMail,
            key: 'collections',
            href: `/address-book/${company.id}/collections`
        });

        return {links};
    })
    .build();
