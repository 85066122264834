import {NumericTextBox} from "@progress/kendo-react-inputs";
import {Label} from "@progress/kendo-react-labels";

const TgfCurrencyInput = (props) => {
    const formatOptions = {
        style: "currency",
        currency: "USD",
        currencyDisplay: "symbol",
    };

    const handleChange = (e) => {
        const valid = /\d/.test(e.target.value);
        props.onChange(valid ? e : { target: { element: { name: props.name }, value: "" } });
    };

    return (
        <>
            {props?.label && <Label editorId={props.id}>{props.label}</Label>}
            <NumericTextBox
                {...props}
                value={props.value ? parseFloat(props?.value) : null}
                min={0}
                format={formatOptions}
                onChange={handleChange}
                spinners={false}
            />

        </>

    );
};

export default TgfCurrencyInput;