import "../../../LtlQuote.scss"
import {Grid} from "@material-ui/core";
import {OriginCard} from "../OriginCard/OriginCard";
import {DestinationCard} from "../DestinationCard/DestinationCard";
import {ShipmentContents} from "../ShipmentContents/ShipmentContents";

export const QuoteForm = (props) => {
    const {
        type,
        formData,
        formValues,
        setFormValues,
        applyFormValue,
        setIsDirty,
        fieldErrors,
        shipmentContentsAreValid,
        setShipmentContentsAreValid,
        defaultShipmentContent,
        defaultShipperContent,
        defaultConsigneeContent,
        activeStepIdx,
        originZipCodeRef,
        setStackableHasBeenToggled,
        setHazmatHasBeenToggled
    } = props;

    return (
            <>
                <Grid item xs={12} md={12} lg={6}>
                    <OriginCard
                        type={type}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        originLocationTypes={formData?.originLocationTypes}
                        pickupSiteOptions={formData?.pickupSiteOptions}
                        fieldErrors={fieldErrors}
                        originAccessorialOptions={formData?.originAccessorialOptions}
                        applyFormValue={applyFormValue}
                        setIsDirty={setIsDirty}
                        defaultShipperContent={defaultShipperContent}
                        originZipCodeRef={originZipCodeRef}
                    />
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <DestinationCard
                        type={type}
                        formValues={formValues}
                        setFormValues={setFormValues}
                        destinationLocationTypes={formData?.destinationLocationTypes}
                        applyFormValue={applyFormValue}
                        fieldErrors={fieldErrors}
                        deliverySiteOptions={formData?.deliverySiteOptions}
                        destinationAccessorialOptions={formData?.destinationAccessorialOptions}
                        setIsDirty={setIsDirty}
                        defaultConsigneeContent={defaultConsigneeContent}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ShipmentContents
                        type={type}
                        formValues={formValues}
                        formData={formData}
                        setFormValues={setFormValues}
                        setIsDirty={setIsDirty}
                        shipmentContentsAreValid={shipmentContentsAreValid}
                        setShipmentContentsAreValid={setShipmentContentsAreValid}
                        defaultShipmentContent={defaultShipmentContent}
                        activeStepIdx={activeStepIdx}
                        setStackableHasBeenToggled={setStackableHasBeenToggled}
                        setHazmatHasBeenToggled={setHazmatHasBeenToggled}
                    />
                </Grid>
            </>
    )
};
