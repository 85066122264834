import {TimePicker} from "@progress/kendo-react-dateinputs";
import React from "react";

const TgfTimeInput = (props) => {

    const handleChange = (e) => {
        props.onChange(e);
    };

    const DisabledDateInput = (props) => {

        const getValue = () => {
            if(props.value) {
                let date = props.value;
                let hours = date.getHours();
                const minutes = date.getMinutes();

                const amPm = hours >= 12 ? 'PM' : 'AM';

                hours = hours % 12 || 12; // Converts 0 to 12 for 12 AM

                const formattedHours = String(hours).padStart(2, '0');
                const formattedMinutes = String(minutes).padStart(2, '0');

                return `${formattedHours}:${formattedMinutes} ${amPm}`;
            } else {
                return "";
            }
        }

        return (<React.Fragment>
            <span className="k-textbox k-input k-input-md k-input-solid k-rounded-md k-disabled">
                <input
                    type="text"
                    className="k-input-inner"
                    readOnly={true}
                    disabled={true}
                    value={getValue()}
                />
            </span>
        </React.Fragment>);
    };

    return (
        <TimePicker
            {...props}
            dateInput={DisabledDateInput}
            disabled={props.disabled || false}
            format={props.format || "hh:mm a"}
            defaultValue={props.value ?? null}
            onChange={handleChange}
            clearable={true}
        />
    );
};

export default TgfTimeInput;