import {Grid, GridCell, GridColumn} from "@progress/kendo-react-grid";
import _, {debounce} from "lodash";
import {forwardRef, useCallback, useEffect, useImperativeHandle, useState} from "react";
import {useDispatch} from "react-redux";
import dynamicSort from "../../utils/dynamicSort";
import "./TgfDataGrid.scss";
import CheckmarkCell from "./cells/CheckmarkCell";
import DateCell from "./cells/DateCell";
import DateFromUTCDateTimeCell from "./cells/DateFromUTCDateTimeCell";
import DateTimeFromUTCDateTimeCell from "./cells/DateTimeFromUTCDateTimeCell";
import LinkCell from "./cells/LinkCell";
import useMinWidth from "./includes/useMinWidth";

// https://www.telerik.com/kendo-react-ui/components/grid/api/GridColumnProps/
const makeColumn = (props) => {
    let {
        field,
        title,
        type,
        format,
        width,
        className,
        filter,
        filterable,
        sortable = true, // if not set, default to true
        reorderable,
        groupable,
        url,
        headerClassName,
    } = props;

    // apply the type constraint
    // while allowing the caller to override values where appropriate
    switch (type) {
        case "boolean":
            props.cell = CheckmarkCell;
            props.filter = "boolean";
            props.width = width ?? 80;
            break;
        case "custom":
            // default sort and filter to false for custom columns
            props.sortable = sortable ?? false;
            props.filterable = filterable ?? false;
            break;
        case "date":
            props.cell = DateCell;
            props.filter = "date";
            break;
        case "dateFromUTCDateTime":
            props.cell = DateFromUTCDateTimeCell;
            props.filter = "date";
            break;
        case "dateTimeFromUTCDateTime":
            props.cell = DateTimeFromUTCDateTimeCell;
            props.filter = "date";
            break;
        case "number":
            props.cell = GridCell;
            props.filter = "numeric";
            props.format = "{0:n}";
            break;
        case "currency":
            props.cell = GridCell;
            props.filter = "numeric";
            props.format = "{0:c2}";
            break;
        case "link":
            props.cell = (props) => <LinkCell {...props} url={url} />;
            props.filter = "text";
            break;
        default:
            // if we don't know what else to do, just treat it like a text column
            props.cell = GridCell;
            props.filter = "text";
            break;
    }
    return <GridColumn
        key={field}
        field={field}
        title={title}
        filter={filter}
        format={format}
        filterable={filterable}
        sortable={sortable}
        reorderable={reorderable}
        groupable={groupable}
        width={width}
        className={className}
        headerClassName={`${headerClassName ? headerClassName : ""} ${sortable === false ? "tgf-grid-default-cursor" : ""}`}
        {...props}
    />;
};

// -- GRID --
// https://www.telerik.com/kendo-react-ui/components/grid/api/GridProps/
const TgfDataGrid = forwardRef(function TgfDataGrid(props, ref) {
    const personalDispatch = useDispatch();
    const [total, setTotal] = useState(null);
    const [initialLoadSkipped, setInitialLoadSkipped] = useState(false);

    const {
        className,
        onApplyAdditionalDataStateAsOdata,
        onLoadDataPage,
        onDataPageLoaded,
        gridColumns,
        // set defaults if not provided.
        pageable = {
            pageSize: 10,
            buttonCount: 10,
            pageSizes: [5, 10, 20, 50, 100, 500],
        },
        sort,
        skip,
        take,
        sortable = true,
        filterable = false,
        resizable = false,
        scrollable = "scrollable",
    } = props;

    const initialDataState = {
        skip: skip ?? 0,
        take: take ?? pageable.pageSize ?? 10,
        sort: sort ?? [],
    };

    const [previousOdataQuery, setPreviousOdataQuery] = useState({});
    const [dataState, setDataState] = useState(initialDataState);
    const [data, setData] = useState(props.data);

    useImperativeHandle(ref, () => ({
        refresh() {
            loadGridData()
                .catch(e => console.log(e));
        }
    }));

    const loadGridData = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('loadGridData'));

            // Convert the current kendo grid data state to an odata object
            const internalOdata = dataStateToOdata(dataState);
            // if our implementor specified a function to attach additional dataState, use it.
            const fullOdata = (onApplyAdditionalDataStateAsOdata) ? onApplyAdditionalDataStateAsOdata(internalOdata) : internalOdata;
            if (!_.isEqual(fullOdata.filter, previousOdataQuery.filter)
                && fullOdata.skip > 0) {
                fullOdata.skip = 0;
            }

            const dataPage = await onLoadDataPage(fullOdata);

            if (props.clientSideSorting) {
                dataPage.items.sort(await dynamicSort(fullOdata.orderBy[0]));
            }

            if (props.clientSidePaging) {
                dataPage.items = dataPage.items.slice(dataState.skip, dataState.skip + dataState.take);
            } else {
                dataState.skip = dataPage.pageIndex * dataPage.pageSize;
            }

            setPreviousOdataQuery(fullOdata);
            setData(dataPage.items);
            setTotal(dataPage.totalItemCount);

            if (onDataPageLoaded) {
                onDataPageLoaded(dataPage);
            }
        } catch (e) {
            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('loadGridData'));
        }
    };

    const dataStateToOdata = (dataState, initialSort) => {
        const {
            skip = 0,
            take = 10,
            sort = initialSort ?? [],
            filter = {}
        } = dataState ?? {};
        const mappedSort = sort.length > 0 ? sort.map(s => `${s.field} ${s.dir}`) : [];
        const odata = {
            top: take,
            skip: skip,
            filter: filter,
            orderBy: mappedSort
        };

        return odata;
    };

    // initial load and on dataState change.
    useEffect(() => {
        if(props.skipInitialLoad && initialLoadSkipped === false) {
            setInitialLoadSkipped(true);
        } else {
            loadGridData()
                .catch(e => console.log(e));
        }

    }, [dataState]);

    const onDataStateChange = event => {
        setDataState(event.dataState);
    };
    const debouncedOnDataStateChange = useCallback(debounce(onDataStateChange, 200), []);

    // create grid columns
    const mappedGridColumns = useMinWidth(gridColumns, makeColumn);

    return (
        <>
            <Grid
                className={className}
                data={data}
                total={total}
                pageable={pageable}
                sortable={sortable}
                resizable={resizable}
                onDataStateChange={debouncedOnDataStateChange}
                scrollable={scrollable}
                {...dataState}
            >
                {mappedGridColumns}
            </Grid>
        </>
    );
});


export default TgfDataGrid;
