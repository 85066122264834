
export const HazmatDetailsDialog = (props) => {
    const {
        hazmatDetails,
        additionalData
    } = props;

    const splitUnPoNumber = (input) => {
        const [prefix, numbers] = input.split(/(?<=^UN|^NA)/);
        return {
            hazmatType: prefix || '',
            hazmatCode: numbers || ''
        };
    }

    const { hazmatType, hazmatCode } = splitUnPoNumber(hazmatDetails.unPoNumber);
    const hazmatClass = additionalData.hazmatClasses.find(hzClass => hzClass.id === hazmatDetails.hazmatClass).fullDisplay;
    const packingGroup = additionalData.hazmatPackingGroups.find(pg => pg.id === hazmatDetails.packingGroupNumber)?.display;

    return (
        <div className={"hazmat-details-container"}>
            <table>
                <thead>
                <tr>
                    <th>Hazmat Type</th>
                    <th>Hazmat Code</th>
                    <th>Hazmat Class</th>
                    <th>Packing Group</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>{hazmatType}</td>
                    <td>{hazmatCode}</td>
                    <td>{hazmatClass}</td>
                    <td>{packingGroup}</td>
                </tr>
                </tbody>
            </table>
        </div>

    )
}