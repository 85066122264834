import {MaskedTextBox} from "@progress/kendo-react-inputs";
import {Label} from "@progress/kendo-react-labels";

const TgfPhoneNumberInput = (props) => {
    const handleChange = (e) => {
        const valid = /\d/.test(e.target.value);
        if (e.target.value === '(___) ___-____') {
            props.onChange({ target: { name: props.name,  value: ""} });
            return;
        }
        if(valid) {
            props.onChange(e);
        }
    };

    return (
        <>
            {props?.label && <Label editorId={props.id}>{props.label}</Label>}
            <MaskedTextBox
                mask={"(999) 000-0000"}
                {...props}
                value={props?.value || ""}
                onChange={handleChange}
                label={null}
                clearable={true}
            />
        </>

    );
};

export default TgfPhoneNumberInput;