import React from 'react';
import ComponentBuilder from '../../core/ComponentBuilder';
import {
  Grid,
  Button,
  CssBaseline,
  Container,
  CardMedia,
  Card,
  CardContent
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import TgfLogo from '../../core/media/TgfLogoTitle.png';
import backgroundTrucking from '../../core/media/MovingTruckBackground.png';
import AppInput from '../../core/components/inputs/AppInput/AppMuiInput';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 0
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  backgroundLogo: {
    height: '100vh',
    width: '100vw',
    backgroundImage: `url(${backgroundTrucking})`
  },
  loginCard: {
    maxWidth: 500,
    position: 'fixed',
    top: '59%',
    marginTop: -240
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}));

const LoginPage = (props) => {
  const classes = useStyles();

  const {
    authenticate,
    identify,
    history,
    sendSnackbarMessage
  } = props;

  const [username, setUsername] = React.useState('');
  const [password, setPassword] = React.useState('');

  React.useEffect(() => {
    if (!window.shell.config.isLocal()) {
      window.shell.cacheBuster.activate();
    }
    return () => window.shell.cacheBuster.deactivate();
  }, []);

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const showAuthError = (content) =>
    sendSnackbarMessage({ content, color: 'error' });

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const authResult = await authenticate(username, password);
      if (authResult.status === 'failed') {
          showAuthError('Login failed, please check your credentials and try again.');
      } else if (authResult.status === 'account-locked') {
        showAuthError('Login failed, account is now locked.  Please contact management.');
      } else if (authResult.status === 'maintenance-mode') {
        showAuthError('Login failed. The Site is currently in a maintenance window.');
      } else if (authResult.status === 'success') {
        await identify();
        history.replace('/');
      }
    } catch (error) {
      console.error(error);
      showAuthError(error.message);
    }
  };

  return (
    <>
      <CardMedia component="div" className={classes.backgroundLogo}>
        <Container component="main" maxWidth="sm" className={classes.root}>
          <CssBaseline />
          <div className={classes.loginCard}>
            <Card>
              <CardContent>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <img src={TgfLogo} alt="Tallgrass Freight Company" width="400" height="60" />
                    </Grid>
                    <Grid item xs={12}>
                      <AppInput
                        variant="outlined"
                        required
                        fullWidth
                        id="username"
                        name="username"
                        value={username}
                        onChange={handleUsernameChange}
                        placeholder="you@tallgrassfreight.com"
                        label="Username"
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <AppInput
                        variant="outlined"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        value={password}
                        onChange={handlePasswordChange}
                        autoComplete="current-password"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={!(Boolean(username) && Boolean(password))}
                      >
                        Sign In
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </div>
        </Container>
      </CardMedia>
    </>
  );

};

export default ComponentBuilder
  .wrap(LoginPage)
  .dispatchToProps((shell, dispatch, ownProps) => {
    return {
      async authenticate(username, password) {
        const actionResult = await shell.actions.persona.authenticate(username, password);
        dispatch(actionResult);
        return actionResult;
      },
      async identify() {
        dispatch(await shell.actions.persona.identify());
      },
      async sendSnackbarMessage(message) {
        dispatch(await shell.actions.sys.sendSnackbarMessage(message));
      }
    };
  })
  .build();