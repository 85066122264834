export const CarrierRulesPopup = () => {

    return (
        <div className={"carrier-rules-popup"}>
            <div className={"popup-title"}>
                <p>Limit of Carrier Liability:</p>
            </div>
            <p>
                For additional information on carrier liability, exceptions and prohibited commodities please refer to
                carrier's rules tariff
            </p>
            <p className={"carrier-rules-bullet-header"}>
                GlobalTranz is not liable for any loss, damage, misdelivery or non-delivery caused by:
            </p>
            <ol type={"i"}>
                <li>
                    the act, default or omission of a carrier, the customer or any other party who claims interest in
                    the shipment; or
                </li>
                <li>
                    the nature of the shipment or any defect therein; or
                </li>
                <li>
                    a violation by the customer of any provision of this agreement, the BOL, the carrier's tariff,
                    including, improper or insufficient packing, securing, marking or addressing; or
                </li>
                <li>
                    failure to observe any of the rules relating to shipments not acceptable for transportation or
                    shipments acceptable only under certain conditions; or
                </li>
                <li>
                    the acts or omissions of any person other than employees of GlobalTranz; or
                </li>
                <li>
                    the selection of carrier for particular shipment. Customer acknowledges that in order to provide
                    competitive rates for the services, that the parties have agreed as a material term of this
                    Agreement that the risk of loss or damage incurred as a result of GlobalTranz' alleged liability
                    shall be limited to the fees that GlobalTranz has earned with respect to the subject shipment.
                </li>
            </ol>
            <p>
                Customer specifically acknowledges that GlobalTranz shall have no liability for negligent acts or
                omissions of its employees except to the extent such actions or omissions constitute gross negligence.
            </p>
        </div>
    );
};