import {useState} from "react";

// Takes in the total steps as input.
// Example usage >> const { stepForward, stepBackward, initializeFormStepper, activeStepIdx } = useFormStepper(3);

export const useFormStepper = (totalSteps) => {
    const [step, setStep] = useState(1);

    const initializeFormStepper = () => {
        setStep(1);
    };

    const handleStepForward = () => {
        if (step < totalSteps) {
            setStep((step) => step + 1);
        }
    };

    const handleStepBackward = () => {
        if (step > 1) {
            setStep((step) => step - 1);
        }
    };

    return {
        stepForward: handleStepForward,
        stepBackward: handleStepBackward,
        initializeFormStepper: initializeFormStepper,
        activeStepIdx: step,
    };
};