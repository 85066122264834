import {Grid} from "@material-ui/core";
import TgfButtonBasic from "../../../../../../../../core/kendo/buttons/TgfButtonBasic";
import AppCurrencyText from "../../../../../../../../core/components/text/AppCurrencyText";
import {Warning} from '@material-ui/icons';
import React from "react";
import TgfPopover from "../../../../../../../../core/kendo/tooltips/TgfPopover";
import {CarrierRulesPopup} from "./includes/CarrierRulesPopup";
import {TotalCostPopup} from "./includes/TotalCostPopup";
import InfoIconTooltip from "../../../../../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";
import {Label} from "@progress/kendo-react-labels";
import carrierFallbackImage from "../../../../../../../../core/media/carrier-logo-fallback.jpg";

export const QuoteResult = (props) => {

    const {data, formValues, applyFormValue, stepForward, type, isHazMat} = props;

    const handleSelectCarrierClick = () => {
        applyFormValue("selectedQuote", data);
        stepForward();
    };

    const renderDeliveryDate = () => {
        let display = `${data.estimatedDeliveryDate}`;
        if(data?.guaranteedRate) {
            display += ` By ${data.guaranteedRate.guaranteeTimeframe}`;
        }
        return display;
    }

    const gridAdjClassName = data?.guaranteedRate ? "remove-1-column" : null;

    const handleImageOnError = (e) => {
        e.currentTarget.src = carrierFallbackImage;
        e.currentTarget.onError = null;
    }

    return (
        <Grid container spacing={2} alignItems={"center"} className={"quote-result"}>
            <Grid item xs={12}>
                <div className={`grid-container ${gridAdjClassName}`}>
                    <div className="grid-item span-2-high">
                        <img
                            src={`${window.shell.config.imageUrls.carrierLogo.ltl}/${data?.carrierCode}.jpg`}
                            onError={handleImageOnError}
                            alt="Carrier Logo"
                            style={{maxWidth: 122}}
                        />
                    </div>
                    <div className="grid-item span-2-high">
                        <span className={"quote-result__carrier-name"}>{data.carrierName}</span>
                    </div>
                    <div className="grid-item span-2-high">
                        <TgfPopover
                            button={<Warning/>}
                            popoverContent={<CarrierRulesPopup/>}
                        />
                    </div>
                    {/* Labels */}
                    <div className="grid-item ">
                        <Label className={"quote-result__label"} style={{minWidth: 200}}>
                            Maximum Liability (New / Used) {<InfoIconTooltip title={`Maximum Lability information is dependent upon the details you provided. Remember that carrier liability can vary based on shipment specifics and a carrier's rules tariff.`}/>}
                        </Label>
                    </div>
                    {!data.guaranteedRate &&
                    <div className="grid-item">
                        <Label className={"quote-result__label"}>
                            Est. Transit Days {<InfoIconTooltip title={`Estimated Transit Days can sometimes change depending on the selected pickup date/day of the week. Remember that the provided estimated transit may not take observed holidays into consideration.`}/>}
                        </Label>
                    </div>
                    }
                    <div className="grid-item">
                        <label className={"quote-result__label"}>
                            {data?.guaranteedRate ?
                                <span>Guaranteed Delivery By <InfoIconTooltip title={`This date is dependent on the selected pickup date and the shipment actually being picked up on that day. Remember that the provided date may not take observed holidays into consideration. Always do your due diligence as needed.`}/></span>
                                :
                                <span>Est. Delivery Date <InfoIconTooltip title={`Estimated Delivery Date can sometimes change depending on the selected pickup date/day of the week. Remember that the provided date may not take observed holidays into consideration.`}/></span>
                            }
                        </label>
                    </div>
                    <div className="grid-item">
                        <label className={"quote-result__label"}>
                            Direct / Indirect
                        </label>
                    </div>
                    <div className="grid-item">
                        <label className={"quote-result__label total-cost"}>
                            Total Cost {<InfoIconTooltip title={`This is Tallgrass Freight's cost and has no markup/GM included. Remember to quote your customer accordingly. `}/>}
                        </label>
                    </div>
                    {/* End Labels */}
                    <div className="grid-item span-2-high">
                        {type === "customerQuote" && !isHazMat ?
                            <TgfButtonBasic
                                onClick={handleSelectCarrierClick}
                            >
                                CHOOSE CARRIER
                            </TgfButtonBasic>
                            :
                            <p className={"quote-result__cannot-continue-text"}>CANNOT CONTINUE. <br/> {type === "customerQuote" && isHazMat === true ?
                            "INVOLVES HAZMAT." : "QUOTE ONLY."
                            } </p>
                        }
                    </div>
                    <div className="grid-item">
                        <span className={"quote-result__liability"}>
                            <AppCurrencyText value={parseInt(data.loadLiabilityNew)}/>
                            &nbsp;/&nbsp;
                            <AppCurrencyText value={parseInt(data.loadLiabilityUsed)}/>
                        </span>
                    </div>
                    {!data.guaranteedRate &&
                    <div className="grid-item">
                        <span className={"quote-result__transit-days"}>
                            {data?.rate?.serviceDays} {data?.rate?.serviceDays > 1 ? "Days" : "Day"}
                        </span>
                    </div>
                    }
                    <div className="grid-item">
                        <span className={"quote-result__delivery-date"}>
                            {renderDeliveryDate()}
                        </span>
                    </div>
                    <div className="grid-item">
                        <span className={"quote-result__direct-indirect"}>{data.serviceType}</span>
                    </div>
                    <div className="grid-item">
                        <TgfPopover
                            button={
                                <AppCurrencyText
                                    className={"quote-result__total-cost"}
                                    value={parseFloat(data?.rate?.amount)}
                                />
                            }
                            popoverContent={
                                <TotalCostPopup rateCharges={data?.rate?.charges} totalCost={data?.rate?.amount}/>
                            }
                        />
                    </div>

                </div>
            </Grid>
        </Grid>

    );
};