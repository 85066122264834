import {Grid, Tooltip} from "@material-ui/core";
import {Switch} from '@progress/kendo-react-inputs';
import {Label} from "@progress/kendo-react-labels";
import "../tgf-form.scss";
import {makeStyles} from "@material-ui/core/styles";

const TgfToggleButton = (props) => {

    const classes = makeStyles(theme => ({
        tooltip: {
            backgroundColor: theme.palette.tgfGreen.main,
            textAlign: 'center',
            color: theme.palette.white,
            width: 220,
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: `1px solid ${theme.palette.tgfGrey.light}`,
        },
    }))();

    const {
        labelPosition = "right" // default to "right"
    } = props;
    return (
        <Grid container spacing={labelPosition === "top" ? 0 : 2} direction={labelPosition === "top" ? "column" : "row"} alignItems={"center"} justifyContent={"center"}>

            {labelPosition === 'top' && props.label &&
                <Grid item >
                    <Label editorId={props.id}>{props.label}</Label>
                </Grid>
            }
            <Grid item xs={labelPosition === "top" ? "auto" : 4}>
                <Tooltip title={(props?.checked === true && props?.tooltip) ? props.tooltip : ""} classes={{ tooltip: classes.tooltip }}>
                    <span>
                        <Switch
                            {...props}
                            className={"tgf-toggle-button"}
                            onChange={props.onChange}
                            checked={props.checked ?? false}
                            label={null}
                            size={"small"}
                            offLabel={null}
                            onLabel={null}
                        />
                    </span>
                </Tooltip>
            </Grid>
            {labelPosition === "right" && props.label &&
                <Grid item xs={8}>
                    {props.label && <Label editorId={props.id}>{props.label}</Label>}
                </Grid>
            }
        </Grid>
    );
};

export default TgfToggleButton;