import AppMuiCard from "../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import React from "react";
import TgfPhoneNumberInput from "../../../../../core/kendo/form/inputs/TgfPhoneNumberInput";
import FieldError from "../../../../../crm/components/inputs/FieldError";

export const HazmatEmergencyContactCard = ({formValues, applyFormValue, fieldErrors, disabled = false}) => {

    const handleChange = (e) => {
        applyFormValue(e.target.name, e.target.value)
    }

    return (
        <AppMuiCard title={'Hazmat Emergency Contact'}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={6}>
                    <TgfPhoneNumberInput
                        label={'Hazmat Contact Phone Number'}
                        id={"hazmatEmergencyPhone"}
                        name={"hazmatEmergencyPhone"}
                        value={formValues.hazmatEmergencyPhone}
                        onChange={handleChange}
                        disabled={disabled}
                    />
                    <FieldError>{fieldErrors?.hazmatEmergencyPhone}</FieldError>
                </Grid>
                <Grid item xs={12} md={12} lg={6}>
                    <span className={'warning-text'}>
                        Please ensure that this information is included on your Bill of Lading (BOL) in some capacity so that it is visible to the carrier.
                    </span>
                </Grid>
            </Grid>
        </AppMuiCard>
    )
}