import AppMuiCard from "../../../../../../core/components/cards/AppCard";
import {Grid} from "@material-ui/core";
import React from "react";
import LabelOrLink from "../../../../../../crm/components/cardHelperComponents/LabelOrLink";
import HotNote from "../../../../../shipments/DetailsPage/includes/HotNote";
import CrmTypedAddressBookListingComboBox from "../../../../../../crm/components/CrmTypedAddressBookListingComboBox";
import {Label} from "@progress/kendo-react-labels";
import TgfTextBox from "../../../../../../core/kendo/form/inputs/TgfTextBox";
import TgfPhoneNumberInput from "../../../../../../core/kendo/form/inputs/TgfPhoneNumberInput";
import DateOnlyPicker from "../../../../../../core/kendo/inputs/DateOnlyPicker";
import {
    WeekDaysOnlyCalendar,
} from "../../../../../../core/kendo/form/inputs/customDatePickerCalendars/WeekdaysOnlyCalendar";
import TgfTimeInput from "../../../../../../core/kendo/form/inputs/TgfTimeInput";
import FieldError from "../../../../../../crm/components/inputs/FieldError";
import TgfStateProvinceComboBox from "../../../../../../core/kendo/form/inputs/specialty/TgfStateProvinceComboBox";
import InfoIconTooltip from "../../../../../../core/components/Tooltips/InfoIconTooltip/InfoIconTooltip";

export const ShipperDetailsCard = (props) => {

    const {
        formValues,
        setFormValues,
        setIsDirty,
        fieldErrors,
        disableAll = false,
        filterShipperConsignee,
    } = props;

    const applyShipperFormValue = (name, value) => {
        setFormValues({
            ...formValues,
            shipper: {
                ...formValues.shipper,
                [name]: value,
            },
        });
        setIsDirty(true);
    };

    const handleChange = (e) => {
        applyShipperFormValue(e.target.name, e.target.value);
    };

    const handleReadyTimeChange = (e) => {
        setFormValues({
            ...formValues,
            shipper: {
                ...formValues.shipper,
                readyTime: e.target.value,
                closeTime: null
            },
        });
        setIsDirty(true);
    };

    const handleShipperChange = (shipper) => {
        setFormValues({
            ...formValues,
            shipper: {
                ...formValues.shipper,
                ...shipper
            }
        });
    }


    const isReadOnly = formValues.shipper?.selectedFromFirstPage;

    const getCloseTimeHoursMin = (readyTime) => {
        let minHours = new Date(2000, 2, 10, 0, 0);
        if(readyTime) {
            minHours = new Date(readyTime.getTime());
            minHours.setHours(minHours.getHours() + 2);
        }
        return minHours;
    }

    const cardTitle = (
        <div className={"card-title"}>
            <span>Shipper Details</span>
            <InfoIconTooltip title={
                <>
                    <p>Having and entering accurate Shipper Details is essential to avoiding pickup and shipment issues.</p>
                    <p>Remember to double check all involved information for accuracy.</p>
                </>
            }/>
        </div>
    )

    return (
        <AppMuiCard title={cardTitle}>
            <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                <Grid item xs={12}>
                    <LabelOrLink labelText={"Selected Shipper"} selected={formValues?.shipper} styles={{color: '#28a745'}}/>
                    {formValues.shipper?.hotNote &&
                        <HotNote company={formValues?.shipper}/>
                    }
                    <CrmTypedAddressBookListingComboBox
                        style={{width: '100%'}}
                        types={[CrmTypedAddressBookListingComboBox.ShipperConsignee]}
                        value={formValues.shipper.companyId ? formValues.shipper : null}
                        onChange={handleShipperChange}
                        filterMap={filterShipperConsignee}
                        placeholder={"Search..."}
                        popupIcon={' '}
                        disabled={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TgfTextBox
                        label={"Company Name"}
                        id={"companyName"}
                        name={"companyName"}
                        rows={1}
                        value={formValues.shipper?.companyName}
                        onChange={handleChange}
                        disabled={isReadOnly || disableAll}
                    />
                    <FieldError>{fieldErrors?.shipper?.companyName}</FieldError>
                </Grid>
                <Grid item xs={12}>
                    <TgfTextBox
                        label={"Address 1"}
                        id={"companyAddress1"}
                        name={"companyAddress1"}
                        value={formValues.shipper?.companyAddress1}
                        onChange={handleChange}
                        disabled={isReadOnly || disableAll}
                    />
                    <FieldError>{fieldErrors?.shipper?.companyAddress1}</FieldError>
                </Grid>
                <Grid item xs={12}>
                    <TgfTextBox
                        label={"Address 2"}
                        id={"companyAddress2"}
                        name={"companyAddress2"}
                        value={formValues.shipper?.companyAddress2}
                        onChange={handleChange}
                        disabled={isReadOnly || disableAll}
                    />
                    <FieldError>{fieldErrors?.shipper?.companyAddress2}</FieldError>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12} md={6}>
                        <TgfTextBox
                            label={"City"}
                            id={"companyCity"}
                            name={"companyCity"}
                            value={formValues.shipper?.companyCity}
                            onChange={handleChange}
                            disabled={isReadOnly || disableAll}
                        />
                        <FieldError>{fieldErrors?.shipper?.companyCity}</FieldError>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TgfStateProvinceComboBox
                            label={"State/Province"}
                            id={"companyStateProvince"}
                            name={"companyStateProvince"}
                            value={formValues.shipper?.companyStateProvince}
                            onChange={handleChange}
                            disabled={isReadOnly || disableAll}
                        />
                        <FieldError>{fieldErrors?.shipper?.companyStateProvince}</FieldError>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <TgfTextBox
                            label={"Zip/Postal Code"}
                            id={"companyPostalCode"}
                            name={"companyPostalCode"}
                            onChange={handleChange}
                            value={formValues?.shipper?.companyPostalCode}
                            format={"c2"}
                            disabled={true}
                        />
                        <FieldError>{fieldErrors?.shipper?.companyPostalCode}</FieldError>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12} md={6}>
                        <TgfTextBox
                            label={"Contact Name"}
                            id={"primaryContactFirstName"}
                            name={"primaryContactFirstName"}
                            value={formValues.shipper?.primaryContactFirstName}
                            onChange={handleChange}
                            disabled={isReadOnly || disableAll}
                        />
                        <FieldError>{fieldErrors?.shipper?.primaryContactFirstName}</FieldError>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Label>
                            Phone
                        </Label>
                        <TgfPhoneNumberInput
                            id={"companyBusinessPhone"}
                            name={"companyBusinessPhone"}
                            value={formValues.shipper?.companyBusinessPhone}
                            onChange={handleChange}
                            disabled={isReadOnly || disableAll}
                        />
                        <FieldError>{fieldErrors?.shipper?.companyBusinessPhone}</FieldError>
                    </Grid>
                </Grid>
                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={12} md={4}>
                        <Label>Pickup Date</Label>
                        <InfoIconTooltip title={"Pickup Dates are required at the time of quoting and cannot be changed here.  If you need to make a change, click the Edit/Go Back button above."}
                                         maxWidth={400}
                        />
                        <DateOnlyPicker
                            id={"pickupDate"}
                            name={"pickupDate"}
                            value={formValues.pickupDate}
                            onChange={handleChange}
                            min={new Date()}
                            calendar={WeekDaysOnlyCalendar}
                            disabled={isReadOnly || disableAll}
                        />
                        <FieldError>{fieldErrors?.pickupDate}</FieldError>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Label>
                            Ready Time
                        </Label>
                        <InfoIconTooltip
                            title={
                                <>
                                    <p>The Shipper's Ready Time must be between 6 am and 7 pm.</p>
                                    <p>If this is a same-day pickup, the selected pickup time must be a minimum of 1
                                        hour in the future from the time the shipment is booked. Otherwise, pickup
                                        issues can likely occur. Remember to select the time of the involved shipper's
                                        time zone.</p>
                                </>}
                        />
                        <TgfTimeInput
                            id={"readyTime"}
                            name={"readyTime"}
                            value={formValues.shipper?.readyTime}
                            onChange={handleReadyTimeChange}
                            disabled={disableAll}
                            min={new Date(new Date().setHours(6, 0, 0, 0))}
                            max={new Date(new Date().setHours(19, 0, 0, 0))}
                            steps={{
                                hour: 1,
                                minute: 30,
                            }}
                        />
                        <FieldError>{fieldErrors?.shipper?.readyTime}</FieldError>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Label>
                            Close Time
                        </Label>
                        <InfoIconTooltip
                            title={
                            <>
                                <p>The Shipper's Close Time must be between 8 am and 9 pm.</p>
                                <p>The Shipper's Close Time must be a minimum of two hours after the selected Ready Time.</p>
                            </>
                        }
                        />
                        <TgfTimeInput
                            id={"closeTime"}
                            name={"closeTime"}
                            value={formValues.shipper?.closeTime}
                            onChange={handleChange}
                            disabled={disableAll || !formValues?.shipper?.readyTime}
                            min={getCloseTimeHoursMin(formValues?.shipper?.readyTime)}
                            max={new Date(new Date().setHours(21, 0, 0, 0))}
                            steps={{
                                hour: 1,
                                minute: 30,
                            }}
                        />
                        <FieldError>{fieldErrors?.shipper?.closeTime}</FieldError>
                    </Grid>
                </Grid>
            </Grid>
        </AppMuiCard>
    );
};