import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import * as React from "react";

const TgfNumericTextBox = React.forwardRef((props, ref) => {
    return (
        <>
            {props?.label && <Label editorId={props.id}>{props.label}</Label>}
            <NumericTextBox
                {...props}
                className={"tgf-numeric-textbox"}
                ref={ref}
                value={props?.value ?? ""}
                spinners={false}
                label={null}
            />
        </>
    );
});

export default TgfNumericTextBox;
