import React from "react";
import "../QuotePopup.scss";
import AppCurrencyText from "../../../../../../../../../core/components/text/AppCurrencyText";

export const TotalCostPopup = (props) => {

    const {rateCharges, totalCost} = props;

    const renderCharges = (charges) => {
        return charges.map((charge) => {
            return (
                <tr className={"popup-table-primary-column"}>
                    <td>{charge.description}</td>
                    <td className={"total-cost-value"}>
                        <AppCurrencyText value={parseFloat(charge.amount)}/>
                    </td>
                </tr>
            )
        })
    };

    return (
        <>
            <div className={"popup-title"}>
                <p>Total Amount</p>
            </div>

            <table className={"total-cost-popup"}>
                {renderCharges(rateCharges)}
            </table>
            <hr />
            <table className={"total-cost-popup"}>
                <tr className={"popup-table-primary-column"}>
                    <td>Total</td>
                    <td className={"total-cost-value"}>
                        <AppCurrencyText
                            value={parseFloat(totalCost) || '--'}
                        />
                    </td>
                </tr>
            </table>
        </>
    );
};