import FullWidthLayout from '../../../core/layouts/FullWidthLayout';
import { usePageTitle } from '../../../crm/components/customHooks/misc/usePageTitle';
import AgencyAdminLeftNav from './controls/AgencyAdminLeftNav';

export const AgencyAdmin = (props) => {
    usePageTitle("TGF: Agency Admin Page");

    return (
        <FullWidthLayout SideNav={AgencyAdminLeftNav} title={'Agency Admin'}>

        </FullWidthLayout>
    );
};
