import * as yup from "yup";

export const getShipmenContentValidationSchema = (type) => {
    return yup.object().shape({
        packageType: yup.number().required(),
        unitCount: yup.number().required().min(1, "Must be > 0").max(999999, "Must be <= 999,999"),
        PiecesCount: yup.number().min(1, "Must be > 0").max(999999, "Must be <= 999,999").nullable(),
        class: yup.number().required(),
        weightLbs: yup.number().required().min(1, "Must be > 0").max(999999, "Must be <= 999,999"),
        dimensionsLength: yup.number().required('required').min(1, "Must be > 0").max(636, "Must be <= 636"),
        dimensionsWidth: yup.number().required('required').min(1, "Must be > 0").max(636, "Must be <= 636"),
        dimensionsHeight: yup.number().required('required').min(1, "Must be > 0").max(636, "Must be <= 636"),
        product: yup.object().shape({
            productDescription: yup.string().required(),
        }),
        nmfcCode: type === "customerQuote" ? yup.string().required() : yup.string().nullable(),
        // isHazMat: yup.boolean(),
        // hazmatType: yup.number().when('isHazMat', {
        //     is: true, // alternatively: (val) => val == true
        //     then: (schema) => schema.required(),
        //     otherwise: (schema) => schema.nullable(),
        // }),
        // hazmatCode: yup.string().when('isHazMat', {
        //     is: true, // alternatively: (val) => val == true
        //     then: (schema) => schema.required(),
        //     otherwise: (schema) => schema.nullable(),
        // }),
        // hazmatClass: yup.number().when('isHazMat', {
        //     is: true, // alternatively: (val) => val == true
        //     then: (schema) => schema.required(),
        //     otherwise: (schema) => schema.nullable(),
        // }),
        // packingGroupNumber: yup.number().when('isHazMat', {
        //     is: true, // alternatively: (val) => val == true
        //     then: (schema) => schema.required(),
        //     otherwise: (schema) => schema.nullable(),
        // })
    });
};
