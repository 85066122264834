import {Tooltip} from "@material-ui/core";
import {Info} from "@material-ui/icons";
import React from "react";

import "./InfoIconTooltip.scss"
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/styles";


const InfoIconTooltip = ({title, style, maxWidth = 300}) => {

    const classes = makeStyles((theme) => ({
        customWidth: {
            maxWidth: maxWidth,
        },
    }))();

    return (
        <Tooltip
          style={style ? style : {whiteSpace: 'pre-line'}}
          classes={{tooltip: classes.customWidth}}
          title={
            <div className={"info-icon-tooltip"} >
                <p>{title}</p>
            </div>
        }>
            <Info className={"info-icon-tooltip_icon"} />
        </Tooltip>
    )
}

InfoIconTooltip.propTypes = {
    // Text to display in tooltip.
    title: PropTypes.string.isRequired,
}

export default InfoIconTooltip
