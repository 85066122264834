import React, {useEffect, useState} from "react";
import {Button, Grid} from "@material-ui/core";
import {Label} from "@progress/kendo-react-labels";
import {useDispatch} from "react-redux";
import TgfTextArea from "../../../../core/kendo/form/inputs/TgfTextArea";
import TgfDropDownList from "../../../../core/kendo/form/inputs/TgfDropDownList";
import TgfTextBox from "../../../../core/kendo/form/inputs/TgfTextBox";
import TgfDialog from "../../../../core/kendo/dialog/TgfDialog";
import AppMuiButton from "../../../../core/components/AppButton/AppMuiButton";
import FieldError from "../../../../crm/components/inputs/FieldError";

const productClassOptions = [
    {
        id: null,
        display: "None"
    },
    {
        id: 50,
        display: "50"
    },
    {
        id: 55,
        display: "55"
    },
    {
        id: 60,
        display: "60"
    },
    {
        id: 65,
        display: "65"
    },
    {
        id: 70,
        display: "70"
    },
    {
        id: 77,
        display: "77.5"
    },
    {
        id: 85,
        display: "85"
    },
    {
        id: 92,
        display: "92.5"
    },
    {
        id: 100,
        display: "100"
    },
    {
        id: 110,
        display: "110"
    },
    {
        id: 125,
        display: "125"
    },
    {
        id: 150,
        display: "150"
    },
    {
        id: 175,
        display: "175"
    },
    {
        id: 200,
        display: "200"
    },
    {
        id: 250,
        display: "250"
    },
    {
        id: 300,
        display: "300"
    },
    {
        id: 400,
        display: "400"
    },
    {
        id: 500,
        display: "500"
    }
];

const AddEditProductModal = (props) => {
    const {
        showNewProduct,
        setAddEditProductModalOpen,
        companyId,
        reloadData,
        productId = null,
        originalProductDescription = '',
        originalInternalProductNotes = '',
        originalNmfc = '',
        originalProductClass = null
    } = props;

    const personalDispatch = useDispatch();
    const [productDescription, setProductDescription] = useState(originalProductDescription);
    const [internalProductNotes, setInternalProductNotes] = useState(originalInternalProductNotes);
    const [nmfcCode, setNmfcCode] = useState(originalNmfc);
    const [productClass, setProductClass] = useState(productClassOptions.find(pc => pc.id === originalProductClass));
    const [isDirty, setIsDirty] = useState(false);
    const [isValid, setIsValid] = useState(false);


    useEffect(() => {
        setIsValid(productDescription !== '');
    }, [productDescription])

    const handleDirtyStatus = (newValue, originalValue) => {
        if (newValue === originalValue) {
            setIsDirty(false);
        } else {
            setIsDirty(true);
        }
    }

    const handleProductDescriptionChange = (e) => {
        handleDirtyStatus(e.target.value, originalProductDescription);
        setProductDescription(e.target.value);
    }

    const handleInternalProductNotesChange = (e) => {
        handleDirtyStatus(e.target.value, originalInternalProductNotes);
        setInternalProductNotes(e.target.value);
    }

    const handleNmfcChange = (e) => {
        handleDirtyStatus(e.target.value, originalNmfc);
        setNmfcCode(e.target.value);
    }

    const handleProductClass = (e) => {
        handleDirtyStatus(e.target.value, originalProductClass);
        setProductClass(e.target.value);
    }

    const handleSubmit = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('saveProductData'));

            await window.shell.gateway.saveProduct(
                companyId,
                productDescription,
                internalProductNotes,
                nmfcCode,
                productClass.id,
                productId
            );

            // clear set values
            setProductDescription(null);
            setNmfcCode(null);
            setProductClass("");

            setAddEditProductModalOpen(false);
            reloadData();

            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: 'Product Saved'
            }));
        } catch (e) {
            if (productDescription) {
                setAddEditProductModalOpen(false);
                personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                    content: 'Failed To Save Product',
                    color: 'error'
                }));
            } else {
                personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                    content: 'Product Description Required',
                    color: 'error'
                }));
            }

            console.log(e);
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('saveProductData'));
        }
    }

    return (
        <TgfDialog
            title={showNewProduct ? "Add New Product" : "Edit Product"}
            onClose={() => setAddEditProductModalOpen(false)}
            width={1000}
        >
            <Grid container spacing={3} alignItems={"flex-end"}>
                <Grid item xs={12}>
                    <Label editorId={"productDescription"}>Description (max. 250 characters)</Label>
                    <TgfTextArea
                        id={"productDescription"}
                        name={"productDescription"}
                        value={productDescription}
                        onChange={handleProductDescriptionChange}
                        maxLength={250}
                        required
                    />
                    <FieldError shouldRender={!isValid}>{'Description is required'}</FieldError>
                </Grid>
                <Grid item xs={12}>
                    <Label editorId={"internalProductNotes"}>Internal Product Notes (max. 250 characters)</Label>
                    <TgfTextArea
                        id={"internalProductNotes"}
                        name={"internalProductNotes"}
                        value={internalProductNotes}
                        onChange={handleInternalProductNotesChange}
                        maxLength={250}
                        required
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Label>Class</Label>
                    <TgfDropDownList
                        id={"productClass"}
                        name={"Class"}
                        data={productClassOptions}
                        textField={"display"}
                        dataItemKey={"id"}
                        value={productClass.id}
                        onChange={handleProductClass}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Label editorId={"nmfc"}>NMFC</Label>
                    <TgfTextBox
                        id={"nmfc"}
                        name={"nmfc"}
                        value={nmfcCode}
                        onChange={handleNmfcChange}
                        maxLength={10}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Grid container item spacing={2} justifyContent={"flex-end"}>
                        <Grid item>
                            <AppMuiButton onClick={() => setAddEditProductModalOpen(false)}>
                                Cancel
                            </AppMuiButton>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                color="primary"
                                type={"button"}
                                disabled={!isDirty || !isValid}
                                onClick={handleSubmit}
                            >
                                Save
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </TgfDialog>
    )
}

export default AddEditProductModal;
