import {QuoteSummaryCard} from "../QuoteSummaryCard/QuoteSummaryCard";
import {QuoteResults} from "./includes/QuoteResults/QuoteResults";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {ShipmentContents} from "../ShipmentContents/ShipmentContents";
import TgfDialog from "../../../../../core/kendo/dialog/TgfDialog";
import {Grid} from "@material-ui/core";
import AppAlertStatic from "../../../../../core/components/AppAlertStatic";

const filterOptions = [
    {id: 1, display: "Standard Transit Time"},
    {id: 2, display: "Guaranteed Transit TIme"},
];

export const ResultsPage = (props) => {

    const personalDispatch = useDispatch();

    const {
        formValues,
        setFormValues,
        applyFormValue,
        formData,
        stepForward,
        type,
        defaultShipmentContent,
        activeStepIdx,
        setLocationTimeZones
    } = props;


    const [quoteResults, setQuoteResults] = useState([]);
    const [filteredQuoteResults, setFilteredQuoteResults] = useState([]);
    const [filter, setFilter] = useState(filterOptions[0].id);
    const [shipmentContentsOpen, setShipmentContentsOpen] = useState(false);
    const isHazMat = Object.values(formValues.shipmentContents).some(x => x.isHazMat === true);

    const handleFilterChange = async (e) => {
        setFilter(e.target.value);
    };

    const loadQuoteResults = async () => {
        try {
            personalDispatch(window.shell.actions.sys.processStart('getQuoteResults'));
            //map values. Some of this could be mitigated by matching naming of keys. Stretch goal?
            const shipmentItems = [];
            for (const [i, obj] of Object.entries(formValues.shipmentContents)) {
                shipmentItems.push({
                    packageTypeId: obj.packageType,
                    unitCount: obj.unitCount,
                    unitSaidToContainCount: obj.piecesCount,
                    description: obj.product.productDescription,
                    nmfcNumber: obj?.nmfcCode ? obj.nmfcCode : "",
                    freightClass: obj.class,
                    isHazmat: obj.isHazMat,
                    hazmatType: obj.hazmatType,
                    hazmatCode: obj.hazmatCode,
                    hazmatClass: obj.hazmatClass,
                    packingGroupNumber: obj?.packingGroupNumber ? obj.packingGroupNumber.toString() : null,
                    weightLbs: obj.weightLbs,
                    lengthIn: obj.dimensionsLength,
                    widthIn: obj.dimensionsWidth,
                    heightIn: obj.dimensionsHeight,
                    isStackable: obj.isStackable,
                });
            }

            const shipmentDetails = {
                pickupDateOnly: formValues.pickupDate,
                origin: {
                    companyId: formValues.customer?.companyId,
                    siteTypeId: formValues.originLocationType ? formValues.originLocationType : formValues.pickupSite,
                    postalCode: formValues.originZipPostal,
                    countryRegion: formValues.originCountry,
                    isLiftGatePickup: formValues.originAccessorials.includes(1),
                    isInsidePickup: formValues.originAccessorials.includes(2),
                    byAppointmentOnly: formValues.originAccessorials.includes(3),
                },
                destination: {
                    companyId: formValues.customer?.companyId,
                    siteTypeId: formValues.destinationLocationType ? formValues.destinationLocationType : formValues.deliverySite,
                    postalCode: formValues.destinationZipPostal,
                    countryRegion: formValues.destinationCountry,
                    isLiftGateDelivery: formValues.destinationAccessorials.includes(1),
                    notifyPriorToArrival: formValues.destinationAccessorials.includes(2),
                    isInsideDelivery: formValues.destinationAccessorials.includes(3),
                    byAppointmentOnly: formValues.destinationAccessorials.includes(4),
                    sortAndSegregate: formValues.destinationAccessorials.includes(5),
                },
                shipmentItems: shipmentItems,
                declaredValue: formValues.declaredValue,
                itemCondition: formValues.itemCondition ?? "NEW",
                protectFromFreeze: formValues.originAccessorials.includes(4),
            };
            const result = await window.shell.gateway.getLtlRates(formValues.customer?.companyId, shipmentDetails);
            setQuoteResults(result?.ltlCarrierRates);
            setFilteredQuoteResults(result?.ltlCarrierRates);
            setLocationTimeZones({
                originTimeZone: result?.originTimeZone
            });

        } catch (e) {
            console.log(e);
            let errorMessage = "Failed To Load Ltl Carrier Rates.";
            if(e?.response?.data?.error && e?.response?.data?.error.includes("The GlobalTranz API service is currently unreachable")) {
                errorMessage = "The GlobalTranz service is currently unavailable. Please try again.";
            } else if(e?.response?.data?.error && e?.response?.data?.error.includes("Request limit is exceeded")) {
                errorMessage = "Rate Request limit is exceeded. Please try again in 3 seconds.";
            }
            personalDispatch(await window.shell.actions.sys.sendSnackbarMessage({
                content: errorMessage,
                color: 'error',
            }));
        } finally {
            personalDispatch(window.shell.actions.sys.processComplete('getQuoteResults'));
        }
    };

    useEffect(() => {
        loadQuoteResults();
    }, []);

    useEffect(() => {
        if (filter === 2 && quoteResults.length !== 0) {
            setFilteredQuoteResults(quoteResults.filter(x => x.guaranteedRate !== null));
        } else {
            setFilteredQuoteResults(quoteResults.filter(x => !x.guaranteedRate));
        }
    }, [filter, quoteResults]);

    return (
        <>
            {shipmentContentsOpen &&
                <TgfDialog
                    onClose={() => setShipmentContentsOpen(false)}
                    width={"90%"}
                    title={"View Contents"}
                >
                    <div style={{maxHeight: "80vh"}}>
                        <ShipmentContents
                            type={type}
                            formValues={formValues}
                            formData={formData}
                            setFormValues={setFormValues}
                            defaultShipmentContent={defaultShipmentContent}
                            activeStepIdx={activeStepIdx}
                            disableAll={true}
                        />
                    </div>
                </TgfDialog>
            }
            <QuoteSummaryCard
                formValues={formValues}
                formData={formData}
                setShipmentContentsOpen={setShipmentContentsOpen}
            />
            <Grid item xs={12}>
                <Grid item xs={12} md={12} lg={12}>
                    <AppAlertStatic
                        title={
                            <div className={"ltl-quote_results-page_alert-message"}>
                                <p>Terminal information is currently not made available until after a shipment is booked. If you're needing such information now or want to quote/book a terminal pickup or drop off, you must do so directly on Globaltranz's website.</p>
                                <p>Additional insurance is not included in the rates below and is not available here. If you're needing to quote/book with additional insurance, you must do so directly on Globaltranz's website. Don't forget that we have our own additional insurance options through Falvey (see the Help page). </p>
                            </div>
                        }
                        color={"warning"}
                    />
                </Grid>
            </Grid>
            <QuoteResults
                quoteResults={filteredQuoteResults}
                formValues={formValues}
                applyFormValue={applyFormValue}
                filter={filter}
                handleFilterChange={handleFilterChange}
                filterOptions={filterOptions}
                stepForward={stepForward}
                type={type}
                isHazMat={isHazMat}
            />
        </>


    );
};